import { type FC } from 'react';
import { UiGrid, UiSpinner, UiStack, uiStyles, UiText } from '@/lib/ui';
import PackagePack1 from './Package/Pack1';
import PackagePack2 from './Package/Pack2';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { type SubscriptionEditor } from '@/account/hook/useSubscriptionPlanEditor';
import { SubscriptionPackage } from '@/api/constant/subscription';
import AddonList from '@/account/subscription/PlanManagement/AddonList';
import ServicePackList from '@/account/subscription/PlanManagement/ServicePackList';
import CurrencyTag from '@/account/component/CurrencyTag';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { isPackageEnabled } from '@/account/subscription/utils';
import ServicePackEditor from '@/account/subscription/PlanManagement/ServicePackEditor';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';

export interface ManagementProps {
  subscriptionEditor: SubscriptionEditor
}

const Management: FC<ManagementProps> = ({
  subscriptionEditor
}) => {
  const {
    subscription,
    isLoading,
    selectPackage,
    toggleAddon,
    updateServicePackHours,
    toggleServicePack,
  } = subscriptionEditor;

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (!subscription) {
    return (
      <UiStack p={8}>
        <BaseMessageBarError>
          Failed to load the subscription editor.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  return (
    <>
      <UiStack alignItems={'stretch'} py={8} spacing={8} pb={16}>
        <UiStack justifyContent={'flex-start'} alignItems={'flex-start'} px={8}>
          <CurrencyTag currency={'AUD'} />
        </UiStack>
        <UiStack
          alignItems={'stretch'}
          px={8}
          flexGrow={1}
          spacing={4}
        >
          <UiText variant={'h6'}>Choose a package</UiText>
          <UiGrid templateColumns={'1fr 1fr'} gap={{ base: 4, lg: 8 }}>
            <PackagePack1
              subscription={subscription}
              onSelect={() => selectPackage(SubscriptionPackage.Pack1)}
            />
            <PackagePack2
              subscription={subscription}
              onSelect={() => selectPackage(SubscriptionPackage.Pack2)}
            />
          </UiGrid>
        </UiStack>
        <UiStack px={8}>
          <BaseDividerHorizontal height={2}/>
        </UiStack>
        <UiStack
          spacing={4}
          px={8}
        >
          <UiStack spacing={0}>
            <UiText variant={'h6'}>Service pack</UiText>
            {/*<UiText variant={'body2'}>The service pack is paid yearly</UiText>*/}
          </UiStack>
          <UiStack
            borderRadius={uiStyles.borderRadius}
            spacing={8}
          >
            <ServicePackEditor
              subscription={subscription}
              onHoursUpdate={updateServicePackHours}
              onToggle={toggleServicePack}
            />
          </UiStack>
        </UiStack>
        <UiStack px={8}>
          <BaseDividerHorizontal height={2}/>
        </UiStack>
        <UiStack
          px={8}
          spacing={4}
        >
          <UiStack spacing={0}>
            <UiText variant={'h6'}>Manage add-ons</UiText>
            <UiText variant={'body2'}>The following add-ons are paid every 3 months</UiText>
          </UiStack>
          <UiStack
            // borderWidth={'1px'}
            // borderColor={'gray.300'}
            // borderStyle={'solid'}
            spacing={8}
            // pt={4}
            // p={8}
            borderRadius={uiStyles.borderRadius}
          >
            <UiStack spacing={4}>
              {/*<UiStack spacing={0}>*/}
              {/*  <UiText variant={'body1'} fontWeight={'bold'}>Choose other add-ons</UiText>*/}
              {/*  <UiText variant={'body2'}>The following add-ons are paid every 3 months</UiText>*/}
              {/*</UiStack>*/}
              <UiStack
                // p={8}
                borderRadius={uiStyles.borderRadius}
                // borderWidth={'1px'}
                // borderColor={'gray.300'}
                // borderStyle={'solid'}
                spacing={8}
              >
                <AddonList
                  subscription={subscription}
                  toggleAddon={toggleAddon}
                />
              </UiStack>
            </UiStack>
          </UiStack>
        </UiStack>
      </UiStack>
    </>
  );
};

export default Management;
