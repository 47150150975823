import { type FC, useState, useMemo } from 'react';
import { Form, Formik, type FormikHelpers } from 'formik';
import {
  UiGrid,
  UiText,
  type UiHStackProps,
  UiHStack,
  UiSwitch,
  uiStyles,
  UiStack,
  UiButton
} from '@/lib/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import { saveAddresses, type AddressSaveRequestItem, addressQueryKey, type BothAddressResponse, type Address, addressQueryKeyBoth } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import { type ApiResponse } from '@/api/tenantClient';

import countries from '@/app/countries.json';

const countryOptions = countries.map((countryName) => ({ value: countryName, label: countryName }));

export interface AddressFormProps extends UiHStackProps {
  existingAddresses: BothAddressResponse
}

export interface AdressFormData {
  billingStreet: string
  billingCity: string
  billingCountry: string
  billingState: string
  billingPostcode: string
  hasShipping: boolean
  shippingStreet: string
  shippingCity: string
  shippingCountry: string
  shippingState: string
  shippingPostcode: string
}

const formSchema = Yup.object().shape({
  billingStreet: Yup.string()
    .required('Street address is required.'),
  billingCity: Yup.string()
    .required('City is required.'),
  billingCountry: Yup.string().required('Country is required.'),
  billingState: Yup.string()
    .required('State is required.'),
  billingPostcode: Yup.string()
    .required('Postcode is required.'),
  hasShipping: Yup.boolean(),
  shippingStreet: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Shipping street address is required.')
    }),
  shippingCity: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Shipping city is required.')
    }),
  shippingCountry: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Country is required.')
    }),
  shippingState: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Shipping state is required.')
    }),
  shippingPostcode: Yup.string()
    .when('hasShipping', {
      is: true,
      then: (schema) => schema.required('Shipping postcode is required.')
    })
});

const AddressForm: FC<AddressFormProps> = ({ existingAddresses }) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { registrationQuery } = useRegistrationAuth();
  const registration = registrationQuery?.data;
  const { toNextStep, toPreviousStep } = useRegisterSteps();
  const queryClient = useQueryClient();
  const { createTenantApiRequest } = useTenantApi();

  const { mutateAsync, isLoading } = useMutation<ApiResponse<Address[]>, Error, AddressSaveRequestItem[]>({
    mutationFn: async (addresses: AddressSaveRequestItem[]) => {
      return await saveAddresses(createTenantApiRequest)({ addresses });
    },
    onSuccess: (data: ApiResponse<Address[]>) => {
      if (data?.errors?.length && data?.errors?.length > 0) {
        setSaveErrors(data.errors);
        return;
      }
      toNextStep();
      void queryClient.invalidateQueries({ queryKey: [addressQueryKey] });
      void queryClient.invalidateQueries({ queryKey: [addressQueryKeyBoth] });
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the address.']);
    }
  });

  const submitForm = async (values: AdressFormData) => {
    const billingAddress: AddressSaveRequestItem = {
      id: existingAddresses?.billingAddress?.id,
      address: values.billingStreet,
      suburb: values.billingCity,
      country: values.billingCountry,
      state: values.billingState,
      postCode: values.billingPostcode,
      registrationId: registration!.id,
      type: 'billing'
    };

    const shippingAddress: AddressSaveRequestItem = values.hasShipping ? {
      id: existingAddresses?.shippingAddress?.id,
      address: values.shippingStreet,
      suburb: values.shippingCity,
      country: values.shippingCountry,
      state: values.shippingState,
      postCode: values.shippingPostcode,
      registrationId: registration!.id,
      type: 'shipping'
    } : { ...billingAddress, type: 'shipping', id: existingAddresses?.shippingAddress?.id };

    await mutateAsync([billingAddress, shippingAddress]);
  };

  const areAddressesIdentical = (billingAddress: Address, shippingAddress: Address) => {
    return (
      billingAddress?.address === shippingAddress?.address &&
      billingAddress?.suburb === shippingAddress?.suburb &&
      billingAddress?.country === shippingAddress?.country &&
      billingAddress?.state === shippingAddress?.state &&
      billingAddress?.postCode === shippingAddress?.postCode
    );
  };

  return (
    <Formik
      initialValues={{
        billingStreet: existingAddresses?.billingAddress?.address ?? '',
        billingCity: existingAddresses?.billingAddress?.suburb ?? '',
        billingCountry: existingAddresses?.billingAddress?.country ?? '',
        billingState: existingAddresses?.billingAddress?.state ?? '',
        billingPostcode: existingAddresses?.billingAddress?.postCode ?? '',
        hasShipping: !areAddressesIdentical(existingAddresses?.billingAddress, existingAddresses?.shippingAddress),
        shippingStreet: existingAddresses?.shippingAddress?.address ?? '',
        shippingCity: existingAddresses?.shippingAddress?.suburb ?? '',
        shippingCountry: existingAddresses?.shippingAddress?.country ?? '',
        shippingState: existingAddresses?.shippingAddress?.state ?? '',
        shippingPostcode: existingAddresses?.shippingAddress?.postCode ?? ''
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values: AdressFormData,
        { setSubmitting }: FormikHelpers<AdressFormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <BaseTransitionSlideLeft>
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error) => (
                  <BaseMessageBarError key={error}>
                    {error}
                  </BaseMessageBarError>
                ))}
              </UiStack>
            )}
            <UiStack flexGrow={1}>
              <BaseTransitionSlideLeft>
                <UiGrid
                  templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
                  gap={12}
                  alignItems={'stretch'}
                  flexGrow={1}
                >
                  <BaseFormFieldGroup>
                    <UiText variant={'title'}>Billing address</UiText>
                    <BaseFormInputField
                      name={'billingStreet'}
                      label={'Street address'}
                      layout={'stack'}
                    />
                    <BaseFormInputField
                      name={'billingCity'}
                      label={'Town/suburb/City'}
                      layout={'stack'}
                    />
                    <BaseFormInputField
                      name={'billingState'}
                      label={'State'}
                      layout={'stack'}
                    />
                    <BaseFormInputField
                      name={'billingPostcode'}
                      label={'Postcode'}
                      layout={'stack'}
                    />
                    <BaseFormSelectField
                      name={'billingCountry'}
                      label={'Country'}
                      options={countryOptions}
                      layout={'stack'}
                    />
                  </BaseFormFieldGroup>
                  <BaseFormFieldGroup>
                    <UiHStack>
                      <UiSwitch
                        colorScheme={'primary'}
                        onChange={() => { void setFieldValue('hasShipping', !values.hasShipping); }}
                        isChecked={values.hasShipping}
                        size={'lg'}
                      />
                      <UiText variant={'title'}>Shipping address</UiText>
                    </UiHStack>
                    {values.hasShipping ? (
                      <>
                        <BaseFormInputField
                          name={'shippingStreet'}
                          label={'Street address'}
                          layout={'stack'}
                        />
                        <BaseFormInputField
                          name={'shippingCity'}
                          label={'Town/suburb/City'}
                          layout={'stack'}
                        />
                        <BaseFormInputField
                          name={'shippingState'}
                          label={'State'}
                          layout={'stack'}
                        />
                        <BaseFormInputField
                          name={'shippingPostcode'}
                          label={'Postcode'}
                          layout={'stack'}
                        />
                        <BaseFormSelectField
                          name={'shippingCountry'}
                          label={'Country'}
                          options={countryOptions}
                          layout={'stack'}
                        />
                      </>
                    ) : (
                      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>Same as billing</BaseMessageBarInfo>
                    )}
                  </BaseFormFieldGroup>
                </UiGrid>
              </BaseTransitionSlideLeft>
            </UiStack>
          </BaseTransitionSlideLeft>
          <UiStack height={96} />
          <LayoutFooter>
            <UiHStack justifyContent={'flex-end'} flexGrow={1}>
              <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
                Previous
              </UiButton>
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type="submit" isLoading={isLoading}>
                Pay
              </UiButton>
            </UiHStack>
          </LayoutFooter>
        </Form>
      )}
    </Formik>
  );
};

export default AddressForm;
