import { type ApiResponseSingle, type ApiRequest, properCallApi } from '../tenantClient';
import { type RegistrationWithToken, type Registration } from './registration';

export const requestPasscodeQueryKey = 'registration.requestPasscodeQueryKey';

export interface VerifyUserRequest {
  email: string
  eventId: string
}

export interface RequestPasscodeRequest {
  email: string
  eventId: string
}

export interface VerifyPasscodeRequest {
  email: string
  passcode: string
  eventId: string
}

export interface VerifyUserResponse {
  existingUser: boolean
  registration: Registration | RegistrationWithToken
}

export interface LoginRequest {
  uid: string
  client: string
  token: string
  eventId: string
}

export function verifyUser(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: VerifyUserRequest
  ): Promise<ApiResponseSingle<VerifyUserResponse>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/users/verify_user';
    request.payload = params;
    return await properCallApi<ApiResponseSingle<VerifyUserResponse>>(request);
  };
};

export function requestPasscode(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: RequestPasscodeRequest
  ): Promise<ApiResponseSingle<void>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/users/request_passcode';
    request.payload = params;
    return await properCallApi<ApiResponseSingle<void>>(request);
  };
};

export function verifyPasscode(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: VerifyPasscodeRequest
  ): Promise<ApiResponseSingle<RegistrationWithToken>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/users/verify_passcode';
    request.payload = params;
    return await properCallApi<ApiResponseSingle<RegistrationWithToken>>(request);
  };
};

export function login(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: LoginRequest
  ): Promise<ApiResponseSingle<RegistrationWithToken>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/users/login';
    request.payload = params;
    return await properCallApi<ApiResponseSingle<RegistrationWithToken>>(request);
  };
}
