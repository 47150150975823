import { type FC, useMemo } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef
} from 'mantine-react-table';
import dayjs, { type Dayjs } from 'dayjs';
import { UiBadge, UiButton, UiFlex } from '@/lib/ui';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { type SerializedRegistrationTableRow } from './RegistrationTable';
import { type DatatableSelectOptions } from '@/api/tenantClient';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

dayjs.extend(advancedFormat);

interface DatatableProps {
  data: SerializedRegistrationTableRow[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: any
  setFilters: (filters: any) => void
  sorting: any
  setSorting: (sorting: any) => void
  pagination: any
  setPagination: (pagination: any) => void
  rowCount: number
  delegateTypeSelectOptions: DatatableSelectOptions
  booleanSelectOptions: DatatableSelectOptions
  statusSelectOptions: DatatableSelectOptions
  workshopTicketsSelectOptions: DatatableSelectOptions
  functionTicketsSelectOptions: DatatableSelectOptions
  tourTicketsSelectOptions: DatatableSelectOptions
}

const Datatable: FC<DatatableProps> = ({ data, isLoading, isError, isFetching, refetch, filters, setFilters, sorting, setSorting, pagination, setPagination, rowCount, delegateTypeSelectOptions, booleanSelectOptions, statusSelectOptions, workshopTicketsSelectOptions, functionTicketsSelectOptions, tourTicketsSelectOptions }) => {
  const navigate = useNavigate();
  const { tenantCode } = useRegisterRoute();

  const columns = useMemo<Array<MRT_ColumnDef<SerializedRegistrationTableRow>>>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID'
      },
      {
        accessorKey: 'uuid',
        header: 'Uuid'
      },
      {
        accessorKey: 'user.fullName',
        header: 'Name'
      },
      {
        accessorKey: 'user.email',
        header: 'Email'
      },
      {
        accessorKey: 'delegateType.name',
        header: 'Main Ticket',
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: delegateTypeSelectOptions
        }
      },
      {
        accessorKey: 'ticket.function',
        header: 'Function Tickets',
        filterVariant: 'multi-select',
        mantineFilterSelectProps: {
          data: functionTicketsSelectOptions
        },
        Cell: ({ cell }) => {
          const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
          return (
            <>
              {tickets.map((ticket, index) => (
                <UiBadge key={index} colorScheme="blue" mr={1}>
                  {`${ticket.name}`}
                </UiBadge>
              ))}
            </>
          );
        }
      },
      {
        accessorKey: 'ticket.workshop',
        header: 'Workshop Tickets',
        filterVariant: 'multi-select',
        mantineFilterSelectProps: {
          data: workshopTicketsSelectOptions
        },
        Cell: ({ cell }) => {
          const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
          return (
            <>
              {tickets.map((ticket, index) => (
                <UiBadge key={index} colorScheme="blue" mr={1}>
                  {`${ticket.name}`}
                </UiBadge>
              ))}
            </>
          );
        }
      },
      {
        accessorKey: 'ticket.tour',
        header: 'Tour Tickets',
        filterVariant: 'multi-select',
        mantineFilterSelectProps: {
          data: tourTicketsSelectOptions
        },
        Cell: ({ cell }) => {
          const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
          return (
            <>
              {tickets.map((ticket, index) => (
                <UiBadge key={index} colorScheme="blue" mr={1}>
                  {`${ticket.name}`}
                </UiBadge>
              ))}
            </>
          );
        }
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: statusSelectOptions
        }
      },
      {
        accessorKey: 'paid',
        header: 'Paid',
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: booleanSelectOptions
        }
      },
      {
        accessorKey: 'complete',
        header: 'Complete',
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: booleanSelectOptions
        }
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        Cell: ({ cell }) => cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('hh:mm A Do MMMM Z') : '',
        filterVariant: 'date-range'
      },
      {
        accessorKey: 'confirmDelegateTypeAt',
        header: 'Completed At',
        Cell: ({ cell }) => cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('hh:mm A Do MMMM Z') : '',
        filterVariant: 'date-range'
      }
    ],
    []
  );

  const handleApplyFilter = () => {
    refetch();
  };

  const toRegistrationInfoPage = (registrationId: number) => {
    navigate(generatePageUrl('RegistrationManageInfo', { tenantCode, registrationId: registrationId.toString() }));
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, showGlobalFilter: false },
    enableDensityToggle: false,
    enableClickToCopy: true,
    enableFullScreenToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    rowCount,
    state: {
      columnFilters: filters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      pagination
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 300 } },
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderTopToolbarCustomActions: () => (
      <UiFlex
        width={'100%'}
        direction={'row'}
        justifyContent={'flex-end'}
      >
        <UiButton
          px={6}
          size={'sm'}
          shadow={'base'}
          colorScheme={'primary'}
          onClick={handleApplyFilter}>
            Apply Filter
        </UiButton>
      </UiFlex>
    ),
    renderRowActions: ({ row }) => (
      <UiButton
        px={6}
        size={'sm'}
        shadow={'base'}
        colorScheme={'primary'}
        onClick={() => toRegistrationInfoPage(row.original.id)}>
          Details
      </UiButton>
    )
  });

  return <MantineReactTable table={table} />;
};

export default Datatable;
