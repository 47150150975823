import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  uiStyles,
  UiText
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { InfoFormType } from '@/api/registration';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';
import FormList from '@/registration/buildEvent/AdditionalInfoForm/FormList';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

const AdditionalInfoForm: FC = () => {
  const { eventId } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();

  const { mutate, isLoading } = useIsSetMutation();

  // Assume the step is set once the step is visited.
  useEffect(
    () => mutate({ eventId, key: 'additionalInformation', bool: true }),
    [mutate, eventId]
  );

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        // disableTopNav={true}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
        MainTransition={null}
      >
        <ManageEventLayoutBody
          steps={(
            <ManageEventSteps
              eventId={eventId}
              activeStep={'additionalInfo'}
            />
          )}
          message={'Group your input fields.'}
        >
          <UiStack
            // pt={4}
            spacing={4}
            flexGrow={1}
            p={8}
          >
            <UiHStack justifyContent={'flex-start'}>
              <StepInstruction label={'Instructions'}>
                <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                  <UiText>
                    You can create different additional information forms for different attendee categories.
                  </UiText>
                </UiStack>
              </StepInstruction>
            </UiHStack>
            <BaseDividerHorizontal height={4} />
            <FormList formType={InfoFormType.ADDITIONAL} />
          </UiStack>
          <UiHStack p={8} justifyContent={'space-between'}>
            <UiStack flexGrow={1} />
            <UiButton
              size={'lg'}
              colorScheme={'primary'}
              type={'submit'}
              fontSize={'lg'}
              onClick={toNextStep}
              isLoading={isLoading}>
              Next
            </UiButton>
          </UiHStack>
        </ManageEventLayoutBody>
      </BaseLayout>
    </>
  );
};

export default AdditionalInfoForm;
