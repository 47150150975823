import type React from 'react';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { type RegistrationTableRow, loadRegistrationsForTable, registrationTableQueryKey } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';
import { CenteredSpinner } from '@/base/QueryContainer/QueryContainer';
import Datatable from '../Registration/Datatable';

export interface SerializedRegistrationTableRow extends Omit<RegistrationTableRow, 'createdAt' | 'confirmDelegateTypeAt'> {
  createdAt: Date
  confirmDelegateTypeAt: Date
}

interface Params {
  filters: any
  sorting: any
  pagination: any
}

const useGetRegistrations = ({ filters, sorting, pagination, setPagination }: Params & { setPagination: React.Dispatch<React.SetStateAction<any>> }) => {
  const { createTenantAdminApiRequest } = useTenantApi();

  return useQuery<ApiResponse<RegistrationTableRow>, Error>({
    queryKey: [registrationTableQueryKey, pagination],
    queryFn: async (): Promise<ApiResponse<RegistrationTableRow>> => {
      const response = await loadRegistrationsForTable(createTenantAdminApiRequest)({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting
      });
      return response;
    },
    keepPreviousData: true,
    staleTime: 30_000
  });
};

const RegistrationTable = () => {
  const [filters, setFilters] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [pagination, setPagination] = useState<any>({ pageIndex: 0, pageSize: 10 });

  const { data, isLoading, isError, isFetching, refetch } = useGetRegistrations({ filters, sorting, pagination, setPagination });

  if (!data || isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <Datatable
      data={data.items.map((registration) => ({
        ...registration,
        paid: registration.paid ? 'Yes' : 'No',
        complete: registration.complete ? 'Yes' : 'No',
        createdAt: new Date(registration.createdAt),
        confirmDelegateTypeAt: new Date(registration.confirmDelegateTypeAt)
      }))}
      isLoading={isLoading}
      isError={isError}
      isFetching={isFetching}
      refetch={refetch}
      filters={filters}
      setFilters={setFilters}
      sorting={sorting}
      setSorting={setSorting}
      pagination={pagination}
      setPagination={setPagination}
      rowCount={data.pagination!.total}
      delegateTypeSelectOptions={data.selectOptions!.delegateTypeSelectOptions}
      booleanSelectOptions={data.selectOptions!.booleanSelectOptions}
      statusSelectOptions={data.selectOptions!.statusSelectOptions}
      tourTicketsSelectOptions={data.selectOptions!.tourTicketsSelectOptions}
      workshopTicketsSelectOptions={data.selectOptions!.workshopTicketsSelectOptions}
      functionTicketsSelectOptions={data.selectOptions!.functionTicketsSelectOptions}
    />
  );
};

export default RegistrationTable;
