import { type FC, useCallback, useState } from 'react';
import {
  UiHStack,
  type UiHStackProps,
  uiStyles,
  UiText,
  UiStack,
  UiSpinner,
  UiMenu,
  UiMenuButton,
  UiButton,
  UiMenuList,
  UiIconCaretDown
} from '@/lib/ui';
import { RegistrationMode } from '@/api/constant';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { type EventUpdateRequest, updateEvent, type Event } from '@/api/registration';
import { registration } from '@/api';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { MenuItemOption, MenuOptionGroup, Skeleton } from '@chakra-ui/react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useEventQuery } from '@/registration/hook/useEventQuery';
export interface SettingProps extends UiHStackProps {}

const Settings: FC<SettingProps> = () => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const eventQuery = useEventSettingsQuery(eventId);
  const { data: event } = useEventQuery(eventId);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutateAsync: updateEventMutation, isLoading } = useMutation<ApiResponse<Event>, Error, EventUpdateRequest>({
    mutationFn: async (data: EventUpdateRequest) => {
      return await updateEvent(createTenantAdminApiRequest)(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.eventQueryKey] });
    }
  });

  const onChange = useCallback((value: string | string[]) => {
    void updateEventMutation({
      event: {
        id: eventId,
        registrationMode: value as RegistrationMode
      }
    });
  }, [eventId, updateEventMutation]);

  return (
    <QueryContainer
      query={eventQuery}
      loadingComponent={<Skeleton height='80px' />}
      errorMessage="Failed to load data."
    >
      {(eventSettings) => (
        <UiStack
          // justifyContent={'flex-start'}
          alignItems={'stretch'}
          // {...uiStyles.hover}
          // alignItems={'flex-start'}
          // justifyContent={'flex-start'}
          spacing={4}
          // minWidth={'300px'}
          // py={4}
          // bgColor={'#fff'}
          // flexGrow={1}
          borderRadius={uiStyles.borderRadius}
        >
          <UiStack flexGrow={1}>
            <UiHStack p={8} py={6} spacing={4} borderRadius={uiStyles.borderRadius} flexGrow={1} bgColor={'#fff'} justifyContent={'space-between'}>
              <UiStack spacing={1}>
                <UiText variant={'body1'}>Registration mode</UiText>
                <UiText variant={'body2'} color={'text.secondary'}>This controls the status of the registration available to attendees.</UiText>
              </UiStack>
              <UiMenu>
                <UiMenuButton
                  as={UiButton}
                  rightIcon={<UiIconCaretDown color={'gray.800'} />}
                  color={'text.primary'}
                  bgColor={'blackAlpha.100'}
                  _expanded={{
                    bgColor: 'primary.100'
                  }}
                >
                  {isLoading ? <UiSpinner size="sm" color={'primary.500'} thickness='2px' /> : event?.registrationMode}
                </UiMenuButton>
                <UiMenuList>
                  <MenuOptionGroup
                    defaultValue={event?.registrationMode ?? undefined}
                    type='radio'
                    onChange={onChange}>
                    <MenuItemOption value={RegistrationMode.Building}>{RegistrationMode.Building}</MenuItemOption>
                    <MenuItemOption value={RegistrationMode.Preview}>{RegistrationMode.Preview}</MenuItemOption>
                    <MenuItemOption value={RegistrationMode.Live}>{RegistrationMode.Live}</MenuItemOption>
                    <MenuItemOption value={RegistrationMode.Closed}>{RegistrationMode.Closed}</MenuItemOption>
                  </MenuOptionGroup>
                </UiMenuList>
              </UiMenu>
            </UiHStack>
          </UiStack>
          {saveErrors.length > 0 && (
            <UiStack spacing={2} flexGrow={1}>
              {saveErrors.map((error) => (
                <UiText color={'red.500'} key={error}>
                  {error}
                </UiText>
              ))}
            </UiStack>
          )}
        </UiStack>
      )}
    </QueryContainer>
  );
};

export default Settings;
