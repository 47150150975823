import { type FC, type ReactNode } from 'react';
import {
  UiHStack, UiIconArrowSquareOut,
  UiStack,
  type UiStackProps, uiStyles, UiText,
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import BaseRouterLink from '@/base/Router/Link';

export interface ModuleCardProps {
  tenantCode: string
  children: ReactNode
  navLink?: string
  isEnabled?: boolean
}

const ModuleCard: FC<ModuleCardProps> = ({
  tenantCode,
  children,
  navLink = '',
  isEnabled = true,
}) => {
  return (
    // We need this UiStack to make sure the card will grow to its max width.
    <UiHStack
      bgColor={'#fff'}
      flexGrow={1}
      p={8}
      borderRadius={uiStyles.borderRadius}
      spacing={4}
      justifyContent={'space-between'}
      opacity={isEnabled ? 1 : 0.6}
    >
      <UiStack>
        {children}
      </UiStack>
      {isEnabled ? (
        <UiStack {...uiStyles.hover}>
          <BaseRouterLink to={navLink ?? ''} style={{ cursor: 'inherit' }} target={'_blank'}>
            <UiIconArrowSquareOut size={'3xl'} color={'primary.500'}/>
          </BaseRouterLink>
        </UiStack>
      ) : (
        <UiStack {...uiStyles.hover}>
          <BaseRouterLink to={generatePageUrl('AccountSubscriptionPlan', { tenantCode })}>
            <UiText color={'green.500'} fontWeight={600}>Enable</UiText>
          </BaseRouterLink>
        </UiStack>
      )}
    </UiHStack>
  );
};

export default ModuleCard;
