import { useCallback, type FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useQuery } from '@tanstack/react-query';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiIconPencilSimple, UiStack, UiText } from '@/lib/ui';
import { InfoFormType, type InfoFormResponse, loadRegistrationInfoFormResponse, type InfoForm, infoFormResponseQueryKey, infoFormQueryKey, loadInfoForm } from '@/api/registration';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import InformationList from './InformationList';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';

const AdditionalInfoReview: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { tenantCode, eventId } = useRegisterRoute();
  const { createTenantApiRequest } = useTenantApi();
  const { registrationQuery } = useRegistrationAuth();
  const registration = registrationQuery?.data;

  const additionalInfoQuery = useQuery<InfoFormResponse, Error>({
    queryKey: [infoFormResponseQueryKey, { registrationId: registration?.id, type: InfoFormType.ADDITIONAL }],
    queryFn: async () => {
      const response = await loadRegistrationInfoFormResponse(createTenantApiRequest)({
        registrationId: registration!.id,
        type: InfoFormType.ADDITIONAL
      });
      if (!response.item) {
        throw new Error('No data returned from loadVisitorInfoFormResponse');
      }
      return response.item;
    },
    enabled: !!registration
  });

  const infoFormQuery = useQuery<InfoForm, Error>(
    [infoFormQueryKey, { id: additionalInfoQuery?.data?.id }],
    async () => {
      if (!additionalInfoQuery.data) {
        throw new Error('InfoForm is undefined');
      }
      const result = await loadInfoForm(createTenantApiRequest)(additionalInfoQuery.data.infoFormId);
      const item = result.item;
      item.config = JSON.parse(item.config);
      return result.item as unknown as InfoForm;
    },
    {
      enabled: !!additionalInfoQuery.data
    }
  );

  const toModify = useCallback(
    () => {
      navigate(generatePageUrl(
        'RegistrationRegisterAdditionalInfo',
        { tenantCode, eventId },
        { ...Object.fromEntries(searchParams), review: true }
      ));
    },
    [eventId, navigate, searchParams, tenantCode]
  );

  return (
    <UiStack>
      <UiHStack justifyContent={'space-between'}>
        <UiText variant={'title'}>
          Additional information
        </UiText>
        <UiButton
          rightIcon={(<UiIconPencilSimple color={'primary.500'} size={'xl'} weight={'bold'} />)}
          variant={'ghost'}
          colorScheme={'primary'}
          fontSize={'md'}
          fontWeight={'bold'}
          onClick={toModify}
        >
          Modify
        </UiButton>
      </UiHStack>
      <QueryContainer query={infoFormQuery}>
        {(infoFormItem) => (
          <QueryContainer query={additionalInfoQuery}>
            {(additionalInfo) => additionalInfo && (
              <InformationList
                fieldsMetadata={infoFormItem.config.fieldsMetadata}
                response={additionalInfo.response} />
            )}
          </QueryContainer>
        )}
      </QueryContainer>
    </UiStack>
  );
};

export default AdditionalInfoReview;
