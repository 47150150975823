import { type PropsWithChildren, type FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ProviderRegistrationAuth } from '@/app/ProviderRegistrationAuth';

const RouteGuard: FC<PropsWithChildren> = ({ children }) => {
  return children;
};

const RegisterContainer: FC = () => (
  <ProviderRegistrationAuth>
    <RouteGuard>
      <Outlet />
    </RouteGuard>
  </ProviderRegistrationAuth>
);

export default RegisterContainer;
