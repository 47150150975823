import { useEffect, type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { UiGrid } from '@/lib/ui';
import Ticket from './Ticket';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type TicketType } from '@/api/constant';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type TicketListData, loadTicketListByAttendeeCategory, ticketListQueryKey, type Registration } from '@/api/registration';

export interface TicketListProps {
  ticketType: TicketType
  onEmpty?: () => void
  registration: Registration
}

const TicketList: FC<TicketListProps> = ({ ticketType, onEmpty, registration }) => {
  const { eventId } = useRegisterRoute();
  const { createTenantApiRequest } = useTenantApi();

  const ticketQuery = useQuery<TicketListData, Error>({
    queryKey: [ticketListQueryKey, { eventId, ticketType }],
    queryFn: async () => (
      await loadTicketListByAttendeeCategory(createTenantApiRequest)({
        eventId,
        attendeeCategoryId: registration.attendeeCategoryId,
        ticketType,
        registrationId: registration.id
      })).items,
    enabled: !!registration
  });

  useEffect(() => {
    if (!ticketQuery.isLoading && !ticketQuery.data?.length) {
      onEmpty?.();
    }
  }, [ticketQuery.isLoading, ticketQuery.data, onEmpty]);

  return (
    <QueryContainer query={ticketQuery}>
      {tickets => (
        <UiGrid
          templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
          gap={8}
        >
          {tickets.map((ticket) => (
            <Ticket
              key={ticket.id}
              ticket={ticket}
            />
          ))}
        </UiGrid>
      )}
    </QueryContainer>
  );
};

export default TicketList;
