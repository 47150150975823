import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { UiButton, UiHStack, UiStack } from '@/lib/ui';
import TicketList from './TicketList';
import { TicketType } from '@/api/constant';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import QueryContainer, { CenteredSpinner } from '@/base/QueryContainer/QueryContainer';

const MainTickets: FC = () => {
  const { registrationQuery } = useRegistrationAuth();
  const { toNextStep, toPreviousStep, isReviewing } = useRegisterSteps();

  return (
    <RegisterLayout>
      <QueryContainer query={registrationQuery} loadingComponent={<CenteredSpinner />}>
        {(registration) => (
          <>
            <BaseTransitionSlideLeft>
              <UiStack flexGrow={1}>
                <UiStack pb={4}>
                  {/* <UiText variant={'h6'}>Main event tickets</UiText> */}
                </UiStack>
                <TicketList ticketType={TicketType.Main} onEmpty={toNextStep} registration={registration} />
              </UiStack>
            </BaseTransitionSlideLeft>
            <UiStack height={96} />
            <LayoutFooter>
              <UiHStack justifyContent={'flex-end'} flexGrow={1}>
                <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
                  {isReviewing ? 'Back' : 'Previous'}
                </UiButton>
                <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={toNextStep}>
                  {isReviewing ? 'Done' : 'Next'}
                </UiButton>
              </UiHStack>
            </LayoutFooter>
          </>
        )}
      </QueryContainer>
    </RegisterLayout>
  );
};

export default MainTickets;
