import { useMemo, type FC } from 'react';
import { type Layout } from 'react-grid-layout';
import {
  UiStack,
  type UiStackProps,
  uiStyles
} from '@/lib/ui';
import Grid from '@/base/FormGenerator/Grid';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import GridItem from './GridItem';

export interface FormGeneratorProps extends UiStackProps {
  fieldsLayout?: Layout[]
  fieldsMetadata?: FieldMetadata[]
}

const FormGenerator: FC<FormGeneratorProps> = ({
  fieldsLayout = [],
  fieldsMetadata = [],
  ...props
}) => {
  const fieldsData = useMemo(() =>
    fieldsMetadata
      .map((metadata) => ({
        ...metadata,
        layout: fieldsLayout.find(layout => layout.i === metadata.id)
      }))
      .filter((metadata) => !!metadata.layout)
      .sort((a, b) => (a.layout!.y === b.layout!.y) ? a.layout!.x - b.layout!.x : a.layout!.y - b.layout!.y)
      .map(({ layout, ...metadata }) => metadata as FieldMetadata)
  , [fieldsLayout, fieldsMetadata]);
  return (
    <UiStack
      // p={2}
      // py={16}
      // width={1080}
      // spacing={8}
      // bgColor={'gray.100'}
      alignItems={'stretch'}
      borderRadius={uiStyles.borderRadius}
      {...props}
    >
      <Grid
        // layouts={{ lg: fieldsLayout, md: fieldsLayout, base: fieldsLayout }}
        layouts={{ lg: fieldsLayout, md: fieldsLayout, base: fieldsLayout }}
        // layouts={{ lg: fieldsLayout, md: fieldsLayout, sm: fieldsLayout, xs: fieldsLayout, xxs: fieldsLayout }}
        // layout={fieldsLayout}
        useCSSTransforms={false}
        rowHeight={96}
        // margin={[48, 32]}
        margin={{
          sm: [36, 24],
          md: [36, 24],
          lg: [36, 24],
        }}
        containerPadding={[0, 0]}
        // isResizable={true}
        // resizeHandle={(<UiBox>12e123</UiBox>)}
      >
        {fieldsData.map((fieldMetadata) => (
          <GridItem
            key={fieldMetadata.id}
            fieldMetadata={fieldMetadata} />
        ))}
      </Grid>
    </UiStack>
  );
};

export default FormGenerator;
