// Do NOT change values here since they are referenced directly in backend.

export enum SubscriptionState {
  Active = 1,
  Finished = 2,
  InReview = 3,
  Approved = 4,
  Cancelled = 5,
}

// Do NOT change values here since they are referenced directly in backend.
export enum SubscriptionPaymentPlan {
  Quarterly = 1,
  Yearly = 2,
}

export const subscriptionPlanLabels: Record<SubscriptionPaymentPlan, string> = {
  [SubscriptionPaymentPlan.Quarterly]: 'Quarterly',
  [SubscriptionPaymentPlan.Yearly]: 'Yearly'
};

export enum SubscriptionPackage {
  Pack1 = 'pack1',
  Pack2 = 'pack2'
}

export const subscriptionPackageLabels: Record<SubscriptionPackage, string> = {
  [SubscriptionPackage.Pack1]: 'Essentials',
  [SubscriptionPackage.Pack2]: 'Enterprise'
};

export enum SubscriptionProduct {
  Registration = 'registration',
  AbstractManagement = 'abstractManagement',
  EventWebsite = 'eventWebsite',
  MobileEventApp = 'mobileEventApp',
  DigitalPoster = 'digitalPoster',
  LiveStreaming = 'liveStreaming',
  SponsorExhibitor = 'sponsorExhibitor',
  Webinar = 'webinar',
  DataInsight = 'dataInsight',
  MultiLingualSupport = 'multiLingualSupport',
  LiveTranslation = 'liveTranslation',
  AILiveCaptioning = 'aiLiveCaptioning',
  AppWayfinder = 'appWayfinder',
  ContentHub = 'contentHub',
  // ServicePack = 'servicePack',
  // ServicePack1 = 'servicePack1',
  // ServicePack2 = 'servicePack2',
  // ServicePack3 = 'servicePack3',
}

export enum SubscriptionUnitCostType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}
