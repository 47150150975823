import {
  SubscriptionPackage,
  SubscriptionPaymentPlan,
  SubscriptionProduct,
  SubscriptionState,
  SubscriptionUnitCostType,
} from '@/api/constant/subscription';

import { type ApiRequest } from '@/api/tenantClient';

export const subscriptionQueryKey = 'account.subscription';

export interface SubscriptionProductBase {
  monthlyPrice: number
  yearlyPrice: number
}

export interface SubscriptionServicePack {
  hourPrice: number
}

// Modules
export interface SubscriptionRegistration extends SubscriptionProductBase {
  cprRate: number // Cost per registration
  cprType: SubscriptionUnitCostType
}
export interface SubscriptionAbstractManagement extends SubscriptionProductBase {
  cpsRate: number // Cost per submission
  cpsType: SubscriptionUnitCostType
}
export interface SubscriptionContentDeliveryPlatform extends SubscriptionProductBase {
}
export interface SubscriptionMobileEventApp extends SubscriptionProductBase {
}
export interface SubscriptionDigitalPoster extends SubscriptionProductBase {
  cppRate: number // // Cost per poster
  cppType: SubscriptionUnitCostType
}
export interface SubscriptionLiveStreaming extends SubscriptionProductBase {
}
export interface SubscriptionSponsorExhibitor extends SubscriptionProductBase {
}
export interface SubscriptionWebinar extends SubscriptionProductBase {
}
export interface SubscriptionDataInsight extends SubscriptionProductBase {
}
export interface SubscriptionMultiLingualSupport extends SubscriptionProductBase {
}
export interface SubscriptionLiveTranslation extends SubscriptionProductBase {
  usagePrice: number
}
export interface SubscriptionAICaptioning extends SubscriptionProductBase {
  usagePrice: number
}
export interface SubscriptionAppWayfinder extends SubscriptionProductBase {
}
export interface SubscriptionContentHub extends SubscriptionProductBase {
}

/**
 * DO NOT change the keys here since the backend will use the same keys to locate the products.
 */
export interface SubscriptionProducts {
  [SubscriptionProduct.Registration]: SubscriptionRegistration
  [SubscriptionProduct.AbstractManagement]: SubscriptionAbstractManagement
  [SubscriptionProduct.EventWebsite]: SubscriptionContentDeliveryPlatform
  [SubscriptionProduct.MobileEventApp]: SubscriptionMobileEventApp
  [SubscriptionProduct.DigitalPoster]: SubscriptionDigitalPoster
  [SubscriptionProduct.LiveStreaming]: SubscriptionLiveStreaming
  [SubscriptionProduct.SponsorExhibitor]: SubscriptionSponsorExhibitor
  [SubscriptionProduct.Webinar]: SubscriptionWebinar
  [SubscriptionProduct.DataInsight]: SubscriptionDataInsight
  [SubscriptionProduct.MultiLingualSupport]: SubscriptionMultiLingualSupport
  [SubscriptionProduct.LiveTranslation]: SubscriptionLiveTranslation
  [SubscriptionProduct.AILiveCaptioning]: SubscriptionAICaptioning
  [SubscriptionProduct.AppWayfinder]: SubscriptionAppWayfinder
  [SubscriptionProduct.ContentHub]: SubscriptionContentHub
}

export interface Addon {
  product: SubscriptionProduct
  paymentPlan: SubscriptionPaymentPlan
}

export interface Subscription {
  products: SubscriptionProducts
  [SubscriptionPackage.Pack1]: {
    yearlyPrice: number
    products: SubscriptionProduct[]
  }
  [SubscriptionPackage.Pack2]: {
    yearlyPrice: number
    products: SubscriptionProduct[]
  }
  servicePack: SubscriptionServicePack
  plan: {
    state: SubscriptionState
    from: number // timestamp
    to: number // timestamp
    currency: string
    package: SubscriptionPackage
    servicePack: {
      hours: number
      usedHours: number
    }
    addons: Addon[]
  }
}

export const defaultSubscription: Subscription = {
  products: {
    registration: {
      monthlyPrice: 715,
      yearlyPrice: 7140,
      cprRate: 3,
      cprType: SubscriptionUnitCostType.Fixed,
    },
    abstractManagement: {
      monthlyPrice: 715,
      yearlyPrice: 7140,
      cpsRate: 6,
      cpsType: SubscriptionUnitCostType.Fixed,
    },
    eventWebsite: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    mobileEventApp: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    digitalPoster: {
      monthlyPrice: 715,
      yearlyPrice: 7140,
      cppRate: 6,
      cppType: SubscriptionUnitCostType.Fixed,
    },
    liveStreaming: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    sponsorExhibitor: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    webinar: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    dataInsight: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    multiLingualSupport: {
      monthlyPrice: 295,
      yearlyPrice: 2950
    },
    liveTranslation: {
      monthlyPrice: 95,
      yearlyPrice: 950,
      usagePrice: 0
    },
    aiLiveCaptioning: {
      monthlyPrice: 95,
      yearlyPrice: 950,
      usagePrice: 0
    },
    appWayfinder: {
      monthlyPrice: 715,
      yearlyPrice: 7140
    },
    contentHub: {
      monthlyPrice: 595,
      yearlyPrice: 6300
    }
  },
  pack1: {
    yearlyPrice: 28355,
    products: [
      SubscriptionProduct.Registration,
      SubscriptionProduct.EventWebsite,
      SubscriptionProduct.Webinar,
      SubscriptionProduct.SponsorExhibitor,
      SubscriptionProduct.LiveStreaming,
      SubscriptionProduct.DataInsight
    ]
  },
  pack2: {
    yearlyPrice: 39500,
    products: [
      SubscriptionProduct.Registration,
      SubscriptionProduct.EventWebsite,
      SubscriptionProduct.Webinar,
      SubscriptionProduct.SponsorExhibitor,
      SubscriptionProduct.LiveStreaming,
      SubscriptionProduct.DataInsight,
      SubscriptionProduct.AbstractManagement,
      SubscriptionProduct.DigitalPoster,
      SubscriptionProduct.MobileEventApp
    ]
  },
  servicePack: {
    hourPrice: 170,
  },
  plan: {
    state: SubscriptionState.InReview,
    package: SubscriptionPackage.Pack2,
    from: 1705792147,
    to: 1716250147,
    currency: 'AUD',
    servicePack: {
      hours: 0,
      usedHours: 0,
    },
    addons: [],
  },
};

export interface SubscriptionLoadRequest {}

export const loadSubscription = (createTenantAdminRequest: () => ApiRequest) => {
  const apiRequest = createTenantAdminRequest();
  return async (
    params: SubscriptionLoadRequest
  ): Promise<Subscription> => {
    apiRequest.endpoint.path = '/api/v1/subscriptions';
    apiRequest.payload = params;
    return defaultSubscription;
    // return await properCallApi<Subscription>(request);
    // return subscriptionDefaultData;
  };
};
