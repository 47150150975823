import { type FC } from 'react';
import { UiHStack, UiImage, UiStack, uiStyles, UiText } from '@/lib/ui';
import { type RegistrationWithToken } from '@/api/registration';

interface DiscountSessionProps {
  registration: RegistrationWithToken
}

const DiscountSession: FC<DiscountSessionProps> = ({ registration }) => {
  return (
    <UiStack>
      <UiHStack justifyContent={'space-between'}>
        <UiText variant={'title'}>
          Discount Code
        </UiText>
      </UiHStack>
      <UiStack
        spacing={4}
        py={8}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        enableDivider={true}
      >
        <UiStack spacing={0} px={8}>
          <UiText variant={'body2'} color={'text.secondary'}>Discount Code</UiText>
          <UiText>{registration.discount.discountCode}</UiText>
        </UiStack>
        <UiStack spacing={0} px={8}>
          <UiText variant={'body2'} color={'text.secondary'}>Discount name</UiText>
          <UiText>{registration.discount.name}</UiText>
        </UiStack>
        {registration.discount.description && (
          <UiStack spacing={0} px={8}>
            <UiText variant={'body2'} color={'text.secondary'}>Description</UiText>
            <UiText>{registration.discount.description}</UiText>
          </UiStack>
        )}
        <UiStack spacing={0} px={8}>
          <UiText variant={'body2'} color={'text.secondary'}>ID Photocopy</UiText>
          {registration.idPhotocopy ? (
            <UiImage
              src={registration.idPhotocopy}
              borderRadius={uiStyles.borderRadius}
              maxWidth={32}
            />
          ) : (
            <UiText>Not provided</UiText>
          )}
        </UiStack>
        <UiStack spacing={0} px={8}>
          <UiText variant={'body2'} color={'text.secondary'}>ID Info</UiText>
          <UiText>{registration.idInfo ?? 'Not provided'}</UiText>
        </UiStack>
      </UiStack>
    </UiStack>
  );
};

export default DiscountSession;
