/* eslint-disable react/no-unescaped-entities */
import { useCallback, type FC } from 'react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import useFields from '@/base/FormBuilder/useFields';
import { initFieldsLayout, initFieldsMetadata, type InfoForm, type InfoFormSaveRequest, InfoFormType } from '@/api/registration';
import { UiHStack, UiIconArrowSquareOut, UiIconCaretLeft, UiLink, UiStack, UiText, uiStyles } from '@/lib/ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import { type Layout } from 'react-grid-layout';
import BaseFormBuilder from '@/base/FormBuilder';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useDisclosure } from '@chakra-ui/react';
import FullScreen from '@/base/Modal/FullScreen';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import FormPreview from '@/base/FormGenerator/FormPreview';
import { useSearchParams } from 'react-router-dom';

const PersonalInfoForm: FC = () => {
  const { tenantCode, eventId } = useRegisterRoute();
  const eventQuery = useEventSettingsQuery(eventId);
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const [searchParams] = useSearchParams();
  const id = Number(searchParams.get('id'));
  const infoFormQuery = useQuery<InfoForm, Error>(
    [registration.infoFormQueryKey, { id }],
    async () => {
      const result = await registration.loadInfoForm(createTenantAdminApiRequest)(id);
      return {
        ...result.item,
        config: JSON.parse(result.item.config) as InfoForm['config']
      };
    },
    {
      enabled: !isApiPreparing && !!id
    }
  );
  const {
    fieldsLayout,
    fieldsMetadata,
    addField,
    setFieldsLayout,
    deleteField,
    updateFieldMetadata
  } = useFields(
    infoFormQuery?.data?.config.fieldsLayout ?? initFieldsLayout,
    infoFormQuery?.data?.config?.fieldsMetadata ?? initFieldsMetadata
  );

  const onFieldAdd = (newFieldsMetadata: FieldMetadata) => {
    let layout: Layout = { x: 0, y: Infinity, w: 1, h: 1, maxH: 1, i: newFieldsMetadata.id };
    if (newFieldsMetadata.type === 'sectionBreak') {
      layout = { x: 0, y: Infinity, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: newFieldsMetadata.id };
    }
    if (newFieldsMetadata.type === 'descriptionBlock') {
      layout = { x: 0, y: Infinity, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: newFieldsMetadata.id };
    }
    addField(layout, newFieldsMetadata);
  };

  const { mutate } = useMutation<InfoForm, Error, InfoFormSaveRequest>({
    mutationFn: async (data: InfoFormSaveRequest) => {
      const response = await registration.saveInfoForm(createTenantAdminApiRequest)(data);
      return response.item;
    },
    onSuccess: (result: any) => {
      void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId, formType: InfoFormType.PERSONAL }] });
      void (!!id && queryClient.invalidateQueries({ queryKey: [registration.infoFormQueryKey, { id }] }));
    },
    onError: () => {
      // setSaveErrors([error.message ?? 'Failed to save the attendee category.']);
    }
  });

  const onSaveAndGoBack = useCallback(() => {
    mutate({
      infoForm: {
        ...(id ? { id } : {}),
        formType: InfoFormType.PERSONAL,
        eventId,
        config: JSON.stringify({
          totalFields: fieldsLayout.length,
          fieldsLayout,
          fieldsMetadata
        })
      }
    });
  }, [eventId, fieldsLayout, fieldsMetadata, id, mutate]);

  return (
    <UiStack
      // width={1200}
      bgColor={'gray.100'}
      alignItems={'stretch'}
      borderRadius={uiStyles.borderRadius}
      minHeight={'100vh'}
      flexGrow={1}
      pb={12}
    >
      <UiStack
        position={'fixed'}
        alignItems={'stretch'}
        width={'100%'}
        zIndex={999}
        bgColor={'gray.100'}
        p={8}
      >
        <UiStack
          px={8}
          py={6}
          bgColor={'#fff'}
          borderRadius={uiStyles.borderRadius}
        >
          <UiHStack alignItems={'stretch'} justifyContent={'space-between'}>
            <BaseRouterLink to={generatePageUrl('RegistrationBuildEventPersonalInfoForm', { tenantCode, eventId })}>
              <UiHStack {...uiStyles.hover} onClick={onSaveAndGoBack}>
                <UiIconCaretLeft color={'primary.500'} />
                <UiText variant={'title'} color={'primary.400'} fontWeight={400}>{eventQuery.isLoading ? 'Go back' : 'Save & go back'}</UiText>
              </UiHStack>
            </BaseRouterLink>
            <UiLink
              color={'primary.500'}
              fontSize={'xl'}
              fontWeight={'bold'}
              onClick={onOpen}
              target={'_blank'}
            >
              <UiHStack {...uiStyles.hover}>
                <UiText variant={'title'} color={'primary.400'} fontWeight={400}>Preview</UiText>
                <UiIconArrowSquareOut color={'primary.500'} />
              </UiHStack>
            </UiLink>
          </UiHStack>
        </UiStack>
      </UiStack>
      <UiStack
        spacing={4}
        flexGrow={1}
        alignItems={'center'}
        pt={36}
      >
        <UiHStack>
          <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'red.100'} width={1080}>
            Do not include the 'Email' field in the form. We will always collect the attendee's email at the beginning of the registration.
          </BaseMessageBarInfo>
        </UiHStack>
        <UiStack
          width={1080}
          alignItems={'stretch'}
        >
          <BaseFormBuilder
            onLayoutChange={setFieldsLayout}
            onFieldUpdate={updateFieldMetadata}
            fieldsLayout={fieldsLayout}
            fieldsMetadata={fieldsMetadata}
            onFieldAdd={onFieldAdd}
            onFieldDelete={deleteField}
          />
        </UiStack>
      </UiStack>
      <FullScreen isOpen={isOpen} onClose={onClose}>
        <FormPreview fieldsLayout={fieldsLayout} fieldsMetadata={fieldsMetadata} />
      </FullScreen>
    </UiStack>
  );
};

export default PersonalInfoForm;
