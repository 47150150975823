import { type ApiRequest } from '@/api/tenantClient';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { type UserSession, useRegistrationAuth } from '@/app/ProviderRegistrationAuth';

export function useTenantApi() {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const { adminAuth } = useAdminAuth();
  const { userSession } = useRegistrationAuth();

  const createTenantApiRequest = (): ApiRequest => {
    const request = {
      method: 'POST',
      endpoint: {
        host: tenant?.apiEndpoint,
        path: ''
      },
      headers: {
        uid: userSession?.email ?? '',
        client: userSession?.client ?? '',
        'access-token': userSession?.authToken ?? ''
      }
    };
    return request;
  };

  const createTenantApiRequestWithSession = (session: UserSession | undefined) => (): ApiRequest => {
    const request = {
      method: 'POST',
      endpoint: {
        host: tenant?.apiEndpoint,
        path: ''
      },
      headers: {
        uid: session?.email ?? '',
        client: session?.client ?? '',
        'access-token': session?.authToken ?? ''
      }
    };
    return request;
  };

  const createTenantAdminApiRequest = (): ApiRequest => {
    const request = createTenantApiRequest();
    request.headers = {
      ...request.headers,
      uid: adminAuth.user.email,
      client: adminAuth.client,
      'access-token': adminAuth.authToken
    };
    return request;
  };

  return {
    createTenantApiRequest,
    createTenantAdminApiRequest,
    createTenantApiRequestWithSession,
    isLoading: isTenantLoading
  };
}
