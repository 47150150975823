import { forwardRef } from 'react';
import { Input, type InputProps } from '@chakra-ui/react';
import { uiStyles } from '@/lib/ui/styles';

export interface UiInputProps extends InputProps {
  disabled?: boolean
}

export const UiInput = forwardRef<HTMLInputElement, UiInputProps>(function UiInput(props, ref) {
  return (
    <Input
      // maxWidth={'800px'}
      // height={'48px'}
      ref={ref}
      size={'md'}
      borderRadius={uiStyles.formElementBorderRadius}
      borderColor={'gray.300'}
      focusBorderColor={'primary.400'}
      bgColor={'#fff'}
      disabled={props.disabled}
      // borderRadius={'none'}
      // borderStyle={'none'}
      // borderBottomColor={'gray.300'}
      // borderBottomStyle={'solid'}
      // borderBottomWidth={'1px'}
      // // color={'text.primary'}
      // _focus={{
      //   borderStyle: 'none',
      //   borderBottomColor: 'primary.400',
      //   borderBottomWidth: '1px',
      //   borderBottomStyle: 'solid',
      // }}
      // px={0}
      {...props}
    />
  );
});
