import { type FC, useState } from 'react';
import {
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { registration } from '@/api';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { type ApiResponse } from '@/api/tenantClient';
import { type AttendeeCategorySaveRequest, type AttendeeCategoryData, RegistrationStrategyType } from '@/api/registration';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseFormSelectField from '@/base/Form/SelectField';

export interface TypeFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  attendeeCategory?: registration.AttendeeCategoryData
}

interface FormData {
  name: string
  description?: string
  registrationStrategy: RegistrationStrategyType
  isGroupAvailable?: boolean
  isVisible: boolean
}

const initFormData = {
  name: '',
  description: '',
  registrationStrategy: RegistrationStrategyType.FORMS_FIRST,
  isGroupAvailable: true,
  isVisible: true
};

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.'),
  description: Yup.string()
    .max(100, 'Description can not have more than 100 charactors.')
    .nullable(),
  registrationStrategy: Yup.string()
    .required('Registration strategy is required.'),
  isVisible: Yup.boolean()
});

const TypeForm: FC<TypeFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  attendeeCategory = undefined
}) => {
  const { eventId } = useRegisterRoute();
  const [formData, setFormData] = useState<FormData>(attendeeCategory ?? initFormData);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { mutateAsync, isLoading } = useMutation<ApiResponse<AttendeeCategoryData>, Error, AttendeeCategorySaveRequest>({
    mutationFn: async (data: registration.AttendeeCategorySaveRequest) =>
      await registration.saveAttendeeCategory(createTenantAdminApiRequest)(data),
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        setFormData(initFormData);
        onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({ queryKey: [registration.attendeeCategoryListQueryKey, { eventId }] });
        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the attendee category.']);
    }
  });

  const submitForm = async (values: FormData) => {
    void mutateAsync({
      attendeeCategory: {
        // If the id is passed, the backend should update the entity. Otherwise, the backend should create a new entity.
        id: attendeeCategory?.id ?? null,
        eventId: Number(eventId),
        name: values.name,
        description: values.description ?? '',
        registrationStrategy: values.registrationStrategy,
        isVisible: values.isVisible
      }
    });
  };

  return (
    <Formik
      initialValues={formData}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      {({ values }) => {
        const descriptionCharacterCount = values.description ? 100 - values.description.length : 100;

        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={attendeeCategory ? 'Edit attendee category' : 'Add attendee category'}
            size={'xl'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField
                name="name"
                label="Name"
              />
              <BaseFormSelectField
                name={'isGroupAvailable'}
                label={'Does this category support group registration?'}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' }
                ]}
                helperText={''}
              />
              <BaseFormTextareaField
                name="description"
                label="Description"
                isRequired={false}
                maxLength={100}
                helperText={`100 character limit (${descriptionCharacterCount} characters remaining)`}
              />
              <BaseFormSelectField
                name={'registrationStrategy'}
                label={'Registration Strategy'}
                layout="stack"
                options={[
                  { value: RegistrationStrategyType.FORMS_FIRST, label: 'Forms First' },
                  { value: RegistrationStrategyType.PAYMENT_FIRST, label: 'Payment First' }
                ]}
              />
              <BaseFormSelectField
                name={'isVisible'}
                label={'Visible to attendees'}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' }
                ]}
                helperText={'Attendees won’t see and choose this attendee category once this field is set to No.'}
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>);
      }}
    </Formik>
  );
};

export default TypeForm;
