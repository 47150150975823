import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  type UiBoxProps, UiButton,
  UiHStack,
  UiIconCaretRight,
  UiSimpleGrid,
  UiSpinner,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseEventAppCard from '@/base/App/EventAppCard';
import BaseEventWebsiteCard from '@/base/App/EventWebsiteCard';
import BaseAbstractCard from '@/base/App/AbstractCard';
import BaseDigitalPosterCard from '@/base/App/DigitalPosterCard';
import BaseLiveStreamingCard from '@/base/App/LiveStreamingCard';
import BaseSponsorExhibitorCard from '@/base/App/SponsorExhibitorCard';
import BaseWebinarCard from '@/base/App/WebinarCard';
import { account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import BaseRouterLink from '@/base/Router/Link';
import { isProductEnabled } from '@/account/subscription/utils';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { SubscriptionProduct } from '@/api/constant/subscription';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import ModuleCard from './ModuleCard';

// export interface Apps {
//   account: number;
//   registration: number;
//   abstract: number;
// }

// const apps: Apps = {
//   account: 1,
//   registration: 2,
//   abstract: 3,
// };

// export type AppName = keyof Apps;

export type AppName = 'account' | 'registration' | 'abstract' | 'eventWebsite' | 'speakerTools' | 'integrations' | 'contentHub' | 'eventApp' | 'digitalPoster' | 'sponsorExhibitor' | 'liveStreaming' | 'webinar';

export interface ModuleListProps extends UiBoxProps {
}

const ModuleList: FC<ModuleListProps> = ({
}) => {
  const { tenant } = useTenantRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { data, isLoading } = useQuery<account.Subscription, Error>(
    [account.subscriptionQueryKey, {}],
    async () => {
      return await account.loadSubscription(createTenantAdminApiRequest)({});
    },
    {
      enabled: !isApiPreparing
    }
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (!data) {
    return (
      <UiStack py={8}>
        <BaseMessageBarError>
          Failed to load your current subscription.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  if (!tenant?.code) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  const tenantCode = tenant.code;
  return (
    <UiStack spacing={8} justifyContent={'space-between'} alignItems={'stretch'}>
      <UiStack spacing={4}>
        {/*<UiStack spacing={4}>*/}
        {/*  <UiSimpleGrid columns={3} gap={8}>*/}
        {/*    <ModuleCard*/}
        {/*      tenantCode={tenantCode}*/}
        {/*      isEnabled={true}*/}
        {/*    >*/}
        {/*      <BaseAppAccountCard*/}
        {/*        navLink={generatePageUrl('AccountDashboardModules', { tenantCode })}*/}
        {/*      />*/}
        {/*    </ModuleCard>*/}
        {/*  </UiSimpleGrid>*/}
        {/*</UiStack>*/}
        {/*<BaseDividerHorizontal height={4} />*/}
        <UiText variant={'title'}>Modules</UiText>
        <UiSimpleGrid columns={3} gap={8}>
          <ModuleCard
            tenantCode={tenantCode}
            isEnabled={true}
            navLink={generatePageUrl('AccountDashboardModules', { tenantCode })}
          >
            <BaseAppAccountCard/>
          </ModuleCard>
          <ModuleCard
            tenantCode={tenantCode}
            isEnabled={isProductEnabled(data, SubscriptionProduct.Registration)}
            navLink={generatePageUrl('RegistrationManageEvent', { tenantCode })}
          >
            <BaseAppRegistrationCard/>
          </ModuleCard>
          <ModuleCard
            tenantCode={tenantCode}
            isEnabled={isProductEnabled(data, SubscriptionProduct.EventWebsite)}
            navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
          >
            <BaseEventWebsiteCard/>
          </ModuleCard>
          <ModuleCard
            tenantCode={tenantCode}
            isEnabled={isProductEnabled(data, SubscriptionProduct.DigitalPoster)}
            navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
          >
            <BaseDigitalPosterCard/>
          </ModuleCard>
          <ModuleCard
            tenantCode={tenantCode}
            isEnabled={isProductEnabled(data, SubscriptionProduct.LiveStreaming)}
            navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
          >
            <BaseLiveStreamingCard/>
          </ModuleCard>
          <ModuleCard
            tenantCode={tenantCode}
            isEnabled={isProductEnabled(data, SubscriptionProduct.SponsorExhibitor)}
            navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
          >
            <BaseSponsorExhibitorCard/>
          </ModuleCard>
          <ModuleCard
            tenantCode={tenantCode}
            isEnabled={isProductEnabled(data, SubscriptionProduct.Webinar)}
            navLink={generatePageUrl('EventWebsiteGeneralLanding', { tenantCode })}
          >
            <BaseWebinarCard/>
          </ModuleCard>
          <ModuleCard
            tenantCode={tenantCode}
            isEnabled={isProductEnabled(data, SubscriptionProduct.AbstractManagement)}
            navLink={generatePageUrl('AbstractGeneralLanding', { tenantCode })}
          >
            <BaseAbstractCard/>
          </ModuleCard>
          <ModuleCard
            tenantCode={tenantCode}
            isEnabled={isProductEnabled(data, SubscriptionProduct.MobileEventApp)}
            navLink={generatePageUrl('MobileAppGeneralLanding', { tenantCode })}
          >
            <BaseEventAppCard/>
          </ModuleCard>
        </UiSimpleGrid>
      </UiStack>
      <BaseDividerHorizontal height={0} />
      <UiHStack justifyContent={'flex-end'}>
        <BaseRouterLink to={generatePageUrl('AccountSubscriptionPlan', { tenantCode })}>
          <UiButton colorScheme={'primary'}>Manage modules</UiButton>
        </BaseRouterLink>
        {/*<BaseRouterLink to={generatePageUrl('AccountSubscriptionPlan', { tenantCode })}>*/}
        {/*  <UiHStack*/}
        {/*    flexGrow={1}*/}
        {/*    justifyContent={'space-between'}*/}
        {/*    p={4}*/}
        {/*    px={8}*/}
        {/*    bgColor={'#fff'}*/}
        {/*    borderRadius={uiStyles.borderRadius}*/}
        {/*    {...uiStyles.hover}*/}
        {/*  >*/}
        {/*    <UiText variant={'title'}>Manage modules</UiText>*/}
        {/*    <UiIconCaretRight color={'primary.500'} size={'3xl'} />*/}
        {/*  </UiHStack>*/}
        {/*</BaseRouterLink>*/}
      </UiHStack>
    </UiStack>
  );
};

export default ModuleList;
