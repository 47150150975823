import { getAppConfig } from '@/app/config';

export function isError(errors: Array<string | Error> | undefined | null) {
  return Array.isArray(errors) && errors.length > 0;
}

export function generateRegisterAbsoluteUrl(relativePath: string): string {
  const appConfig = getAppConfig();
  return appConfig.host + '/' + relativePath.replace(/^\/|\/$/g, '');
}
