/* eslint-disable @typescript-eslint/no-misused-promises */
import { type FC, useState, useCallback } from 'react';
import { Form, Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerCloseButton,
  UiDrawerBody,
  UiText,
  UiDrawerFooter,
  UiButton,
  UiHStack, UiLink, uiStyles, UiIconArrowSquareOut
} from '@/lib/ui';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';
import { type paymentOptionType, type CreatePaymentRequest, type PaymentOption } from '@/api/registration';
import { registration } from '@/api';

export interface OnlinePaymentFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  paymentOption?: PaymentOption
}

interface FormData {
  paymentType: paymentOptionType
  isEnabled?: boolean
  stripe_secret_key?: string
  stripe_public_key?: string
  eWayApiKey?: string
  eWayApiPassword?: string
}

const formSchema = Yup.object().shape({
  paymentType: Yup.string().required('Payment Gateway is required.'),
  isEnabled: Yup.boolean().required('This field is required'),
  stripe_secret_key: Yup.string().when('paymentType', {
    is: 'stripe',
    then: (schema) => schema.required('This key is required')
  }),
  stripe_public_key: Yup.string().when('paymentType', {
    is: 'stripe',
    then: (schema) => schema.required('This key is required')
  }),
  eWayApiKey: Yup.string().when('paymentType', {
    is: 'eWay',
    then: (schema) => schema.required('This key is required')
  }),
  eWayApiPassword: Yup.string().when('paymentType', {
    is: 'eWay',
    then: (schema) => schema.required('This key is required')
  })
});

const OnlinePaymentForm: FC<OnlinePaymentFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  paymentOption
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();

  const isEnabledOptions: Option[] = [
    { value: true, label: 'Enable' },
    { value: false, label: 'Disable' }
  ];

  const { mutateAsync: testConnection, isLoading: testConnectionLoading } = useMutation<ApiResponse<{}>, Error, CreatePaymentRequest>({
    mutationFn: async (data: CreatePaymentRequest) => {
      return await registration.testConnection(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save payment method']);
    }
  });

  const { mutateAsync, isLoading } = useMutation<ApiResponse<PaymentOption>, Error, CreatePaymentRequest>({
    mutationFn: async (data: CreatePaymentRequest) => {
      return await registration.addPaymentOptions(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({ queryKey: [registration.paymentOptionQueryKey] });
        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save payment method']);
    }
  });

  const onTestConnection = useCallback(async (values: FormData) => {
    const { paymentType, isEnabled, ...config } = values;
    await testConnection({
      paymentOptionType: paymentType,
      config
    });
  }, [testConnection]);

  const submitForm = useCallback(async (values: FormData) => {
    const { paymentType, isEnabled, ...config } = values;
    void mutateAsync({
      id: paymentOption?.id,
      paymentOptionType: paymentType,
      isEnabled,
      config
    });
  }, [mutateAsync, paymentOption?.id]);

  return (
    <Formik
      initialValues={{
        paymentType: paymentOption?.name ?? 'stripe'
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (values, { setSubmitting }: FormikHelpers<FormData>) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      {({ values, validateForm }) => (
        <UiDrawer placement={'right'} size={'xl'} isOpen={isVisible} onClose={onClose}>
          <Form style={{ display: 'flex' }}>
            <UiDrawerOverlay />
            <UiDrawerContent>
              <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
              <UiDrawerBody p={0}>
                <UiStack alignItems={'stretch'} p={8}>
                  <UiText variant={'h6'} pb={8}>Set up your payment gateway</UiText>
                  {saveErrors.length > 0 && (
                    <UiStack spacing={4} flexGrow={1} py={4}>
                      {saveErrors.map((error, index) => (
                        <BaseMessageBarError key={index}>{error}</BaseMessageBarError>
                      ))}
                    </UiStack>
                  )}
                  <BaseFormFieldGroup>
                    <BaseFormSelectField
                      name={'paymentType'}
                      label={'Payment Gateway'}
                      layout="stack"
                      options={[
                        { value: 'stripe', label: 'Stripe' }
                        // NOTE: disable eWay for now
                        // { value: 'eWay', label: 'eWay' }
                      ]}
                    />
                    {
                      values.paymentType === 'stripe' && (
                        <>
                          <UiLink href={'https://support.stripe.com/questions/locate-api-keys-in-the-dashboard'} target={'_blank'} {...uiStyles.hover}>
                            <UiHStack>
                              <UiText color={'primary.500'}>
                                Find keys in your Stripe account dashboard.
                              </UiText>
                              <UiIconArrowSquareOut color={'primary.500'} />
                            </UiHStack>
                          </UiLink>
                          <BaseFormSelectField
                            name={'isEnabled'}
                            label={'Enable this method?'}
                            options={isEnabledOptions}
                            layout="stack"
                          />
                          <BaseFormInputField
                            name="stripe_secret_key"
                            label="Live mode secret key (server-side key)"
                            layout="stack"
                          />
                          <BaseFormInputField
                            name="stripe_public_key"
                            label="Live mode publishable key (client-side key)"
                            layout="stack"
                          />
                          <BaseFormInputField
                            name="endpoint_secret"
                            label="Webhook secret"
                            layout="stack"
                          />
                        </>
                      )
                    }
                    {
                      values.paymentType === 'eWay' && (
                        <>
                          <BaseFormInputField
                            name="eWayApiKey"
                            label="Rapid API Key"
                            layout="stack"
                          />
                          <BaseFormInputField
                            name="eWayApiPassword"
                            label="Rapid Password"
                            layout="stack"
                          />
                        </>
                      )
                    }
                  </BaseFormFieldGroup>
                </UiStack>
              </UiDrawerBody>
              <UiDrawerFooter p={0}>
                <UiHStack p={8} justifyContent={'space-between'} flexGrow={1}>
                  <UiButton
                    px={8}
                    size={'lg'}
                    variant={'ghost'}
                    colorScheme={'primary'}
                    isLoading={testConnectionLoading}
                    onClick={async () => {
                      await validateForm();
                      void onTestConnection(values);
                    }}
                  >
                    Test Connection
                  </UiButton>
                  <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading}>
                    Save
                  </UiButton>
                </UiHStack>
              </UiDrawerFooter>
            </UiDrawerContent>
          </Form>
        </UiDrawer>

      )}
    </Formik>
  );
};

export default OnlinePaymentForm;
