import { type FC } from 'react';
import {
  UiHStack,
  UiImage,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText
} from '@/lib/ui';
import { type RegistrationInfo } from '@/api/registration';

export interface RegistrationDiscountProps {
  registration: RegistrationInfo
}

const RegistrationDiscount: FC<RegistrationDiscountProps> = ({ registration }) => {
  return (
    <UiStack spacing={4}>
      <UiText variant={'title'}>Proof Of Discount</UiText>
      <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1}>
        <UiSimpleGrid columns={3} gap={8}>
          <UiStack>
            <UiText color={'text.secondary'}>Name</UiText>
            <UiHStack spacing={4}>
              <UiText>{registration.discount?.name}</UiText>
            </UiHStack>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>Code</UiText>
            <UiText>{registration.discount?.discountCode}</UiText>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>Description</UiText>
            <UiText>{registration.discount?.description}</UiText>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>ID Photocopy</UiText>
            <UiImage src={registration.discount?.tmpIdPhotocopy ?? ''} boxSize={32} />
          </UiStack>
          {
            registration.discount?.idInfo && (
              <UiStack>
                <UiText color={'text.secondary'}>ID Info</UiText>
                <UiText>{registration.discount?.idInfo}</UiText>
              </UiStack>
            )
          }
        </UiSimpleGrid>
      </UiStack>
    </UiStack>
  );
};

export default RegistrationDiscount;
