import { useCallback, type FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiIconPencilSimple, UiStack, uiStyles, UiText } from '@/lib/ui';
import { type RegistrationWithToken } from '@/api/registration';

interface SummarySessionProps {
  registration: RegistrationWithToken
}

const SummarySession: FC<SummarySessionProps> = ({ registration }) => {
  return (
    <UiStack>
      <UiHStack justifyContent={'space-between'}>
        <UiText variant={'title'}>
          Summary
        </UiText>
      </UiHStack>
      <UiStack
        spacing={4}
        py={8}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        enableDivider={true}
      >
        <UiStack spacing={0} px={8}>
          <UiText variant={'body2'} color={'text.secondary'}>Email</UiText>
          <UiText>{registration.email}</UiText>
        </UiStack>
        <UiStack spacing={0} px={8}>
          <UiText variant={'body2'} color={'text.secondary'}>Status</UiText>
          <UiText>{registration.status}</UiText>
        </UiStack>
        <UiStack spacing={0} px={8}>
          <UiText variant={'body2'} color={'text.secondary'}>Attendee Category</UiText>
          <UiText>{registration.attendeeCategory?.name}</UiText>
        </UiStack>
        <UiStack spacing={0} px={8}>
          <UiText variant={'body2'} color={'text.secondary'}>Event</UiText>
          <UiText>{registration.event?.name}</UiText>
        </UiStack>
        <UiStack spacing={0} px={8}>
          <UiText variant={'body2'} color={'text.secondary'}>Created At</UiText>
          <UiText>{dayjs().format(registration.createdAt)}</UiText>
        </UiStack>
      </UiStack>
    </UiStack>
  );
};

export default SummarySession;
