/* eslint-disable react/no-unescaped-entities */
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerOverlay,
  type UiDrawerProps,
  UiHStack,
  UiIconCaretRight,
  UiIconUserCircle,
  UiInput, UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText
} from '@/lib/ui';
import {
  type AddonMonths,
  calculateFirstYearPrice,
  getAddonsEnabled, isAddonEnabled,
  isProductEnabled
} from '@/account/subscription/utils';
import { type account } from '@/api';
import { subscriptionPackageLabels, SubscriptionProduct } from '@/api/constant/subscription';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import AddonMonthsMenu from '@/account/subscription/PlanManagement/AddonMonthsMenu';
import AbstractCard from '@/base/App/AbstractCard';
import DigitalPosterCard from '@/base/App/DigitalPosterCard';
import EventAppCard from '@/base/App/EventAppCard';
import AppWayfinderCard from '@/base/App/AppWayfinderCard';
import ContentHubCard from '@/base/App/ContentHubCard';
import MultiLingualSupportCard from '@/base/App/MultiLingualSupportCard';
import LiveTranslationCard from '@/base/App/LiveTranslationCard';
import AILiveCaptioningCard from '@/base/App/AILiveCaptioningCard';
import AddonCalculator from '@/account/subscription/PlanManagement/Calculator/AddonCalculator';
import UnitCostCalculator from '@/account/subscription/PlanManagement/Calculator/UnitCostCalculator';

// import Summary from './Summary';

export interface ReviewDrawerProps {
  subscription: account.Subscription
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
}

const defaultAddonMonths = 3;

const ReviewDrawer: FC<ReviewDrawerProps> = ({
  subscription,
  isOpen,
  onClose
}) => {
  const [addonMonths, setAddonMonths] = useState<AddonMonths>({});
  const [numOfRegistrations, setNumOfRegistrations] = useState(0);
  const [numOfAbstractSubmissions, setNumOfAbstractSubmissions] = useState(0);
  const [numOfDigitalPosters, setNumOfDigitalPosters] = useState(0);
  const [liveTranslationUsageCost, setLiveTranslationUsageCost] = useState(0);
  const [aiLiveCaptioningUsageCost, setAiLiveCaptioningUsageCost] = useState(0);
  const [prices, setPrices] = useState(() => {
    return calculateFirstYearPrice(subscription, {
      numOfRegistrations: 0,
      numOfAbstractSubmissions: 0,
      numOfDigitalPosters: 0,
      liveTranslationUsageCost: 0,
      aiLiveCaptioningUsageCost: 0,
      defaultAddonMonths,
      addonMonths: {}
    });
  });

  const onAddonMonthsChange = useCallback(
    (addon: SubscriptionProduct, months: number) => {
      const newAddonMonths = { ...addonMonths };
      newAddonMonths[addon] = months;
      setAddonMonths(newAddonMonths);
    },
    [addonMonths]
  );

  // Update prices.
  useEffect(
    () => {
      setPrices(calculateFirstYearPrice(subscription, {
        numOfRegistrations,
        numOfAbstractSubmissions,
        numOfDigitalPosters,
        defaultAddonMonths,
        liveTranslationUsageCost,
        aiLiveCaptioningUsageCost,
        addonMonths
      }));
    },
    [
      addonMonths,
      numOfRegistrations,
      numOfAbstractSubmissions,
      numOfDigitalPosters,
      liveTranslationUsageCost,
      aiLiveCaptioningUsageCost,
      subscription
    ]
  );

  const hasUnitCosts = useMemo(
    () => {
      return isProductEnabled(subscription, SubscriptionProduct.Registration) ||
        isProductEnabled(subscription, SubscriptionProduct.AbstractManagement) ||
        isProductEnabled(subscription, SubscriptionProduct.DigitalPoster);
    },
    [subscription]
  );

  return (
    <UiDrawer
      placement={'right'}
      size={'2xl'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerBody p={0} bgColor={'gray.100'}>
          <UiStack py={12} spacing={4}>
            <UiStack spacing={0} px={12}>
              <UiText variant={'h6'}>
                Estimate your 12 months' cost ({subscription.plan.currency})
              </UiText>
              <UiText variant={'body2'} color={'text.secondary'}>
                Please use this calculator to estimate your 12 months' cost. Your bills might be different according to the actual scenarios.
              </UiText>
            </UiStack>
            <BaseDividerHorizontal height={4} />
            <UiStack bgColor={'gray.100'} px={12} pt={0} pb={6} spacing={8} enableDivider={true}>
              <UiStack>
                <UiText variant={'title'}>Package</UiText>
                <UiHStack alignItems={'flex-start'} justifyContent={'space-between'} p={8} bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
                  <UiText>{subscriptionPackageLabels[subscription.plan.package]}</UiText>
                  <UiText variant={'body1'}>
                    <PriceDisplay price={prices.package} />
                  </UiText>
                </UiHStack>
              </UiStack>
              {subscription.plan.servicePack.hours > 0 && (
                <UiStack>
                  <UiText variant={'title'}>Service pack</UiText>
                  <UiHStack alignItems={'flex-start'} justifyContent={'space-between'} p={8} bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
                    <UiText>{subscription.plan.servicePack.hours} hours</UiText>
                    <UiText variant={'body1'}>
                      <PriceDisplay price={prices.servicePack} />
                    </UiText>
                  </UiHStack>
                </UiStack>
              )}
              {getAddonsEnabled(subscription).length > 0 && (
                <UiStack>
                  <UiHStack alignItems={'stretch'} justifyContent={'flex-start'}>
                    <UiStack>
                      {getAddonsEnabled(subscription).length > 0 ? (
                        <UiStack spacing={0}>
                          <UiText variant={'title'}>Add-ons ({getAddonsEnabled(subscription).length} enabled)</UiText>
                          <UiText variant={'body2'}>How long would you estimate to keep them?</UiText>
                        </UiStack>
                      ) : (
                        <UiText variant={'title'}>Add-ons (0 enabled)</UiText>
                      )}
                    </UiStack>
                  </UiHStack>
                  <UiSimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                    {isAddonEnabled(subscription, SubscriptionProduct.AbstractManagement) && (
                      <AddonCalculator
                        AddonCard={(<AbstractCard showDescription={false} />)}
                        price={prices.addons[SubscriptionProduct.AbstractManagement] ?? 0}
                      >
                        <AddonMonthsMenu
                          months={addonMonths[SubscriptionProduct.AbstractManagement] ?? defaultAddonMonths}
                          onMonthsChange={(months: number) => onAddonMonthsChange(SubscriptionProduct.AbstractManagement, months)}
                        />
                      </AddonCalculator>
                    )}
                    {isAddonEnabled(subscription, SubscriptionProduct.DigitalPoster) && (
                      <AddonCalculator
                        AddonCard={(<DigitalPosterCard showDescription={false} />)}
                        price={prices.addons[SubscriptionProduct.DigitalPoster] ?? 0}
                      >
                        <AddonMonthsMenu
                          months={addonMonths[SubscriptionProduct.DigitalPoster] ?? defaultAddonMonths}
                          onMonthsChange={(months: number) => onAddonMonthsChange(SubscriptionProduct.DigitalPoster, months)}
                        />
                      </AddonCalculator>
                    )}
                    {isAddonEnabled(subscription, SubscriptionProduct.MobileEventApp) && (
                      <AddonCalculator
                        AddonCard={(<EventAppCard showDescription={false} />)}
                        price={prices.addons[SubscriptionProduct.MobileEventApp] ?? 0}
                      >
                        <AddonMonthsMenu
                          months={addonMonths[SubscriptionProduct.MobileEventApp] ?? defaultAddonMonths}
                          onMonthsChange={(months: number) => onAddonMonthsChange(SubscriptionProduct.MobileEventApp, months)}
                        />
                      </AddonCalculator>
                    )}
                    {/*{isAddonEnabled(subscription, SubscriptionProduct.ServicePack1) && (*/}
                    {/*  <AddonCalculator*/}
                    {/*    AddonCard={(<ServicePack1Card />)}*/}
                    {/*    price={prices.addons[SubscriptionProduct.ServicePack1] ?? 0}*/}
                    {/*  >*/}
                    {/*    <UiText>12 months</UiText>*/}
                    {/*  </AddonCalculator>*/}
                    {/*)}*/}
                    {/*{isAddonEnabled(subscription, SubscriptionProduct.ServicePack2) && (*/}
                    {/*  <AddonCalculator*/}
                    {/*    AddonCard={(<ServicePack2Card />)}*/}
                    {/*    price={prices.addons[SubscriptionProduct.ServicePack2] ?? 0}*/}
                    {/*  >*/}
                    {/*    <UiText>12 months</UiText>*/}
                    {/*  </AddonCalculator>*/}
                    {/*)}*/}
                    {/*{isAddonEnabled(subscription, SubscriptionProduct.ServicePack3) && (*/}
                    {/*  <AddonCalculator*/}
                    {/*    AddonCard={(<ServicePack3Card />)}*/}
                    {/*    price={prices.addons[SubscriptionProduct.ServicePack3] ?? 0}*/}
                    {/*  >*/}
                    {/*    <UiText>12 months</UiText>*/}
                    {/*  </AddonCalculator>*/}
                    {/*)}*/}
                    {isAddonEnabled(subscription, SubscriptionProduct.AppWayfinder) && (
                      <AddonCalculator
                        AddonCard={(<AppWayfinderCard />)}
                        price={prices.addons[SubscriptionProduct.AppWayfinder] ?? 0}
                      >
                        <AddonMonthsMenu
                          months={addonMonths[SubscriptionProduct.AppWayfinder] ?? defaultAddonMonths}
                          onMonthsChange={(months: number) => onAddonMonthsChange(SubscriptionProduct.AppWayfinder, months)}
                        />
                      </AddonCalculator>
                    )}
                    {isAddonEnabled(subscription, SubscriptionProduct.ContentHub) && (
                      <AddonCalculator
                        AddonCard={(<ContentHubCard />)}
                        price={prices.addons[SubscriptionProduct.ContentHub] ?? 0}
                      >
                        <AddonMonthsMenu
                          months={addonMonths[SubscriptionProduct.ContentHub] ?? defaultAddonMonths}
                          onMonthsChange={(months: number) => onAddonMonthsChange(SubscriptionProduct.ContentHub, months)}
                        />
                      </AddonCalculator>
                    )}
                    {isAddonEnabled(subscription, SubscriptionProduct.MultiLingualSupport) && (
                      <AddonCalculator
                        AddonCard={(<MultiLingualSupportCard />)}
                        price={prices.addons[SubscriptionProduct.MultiLingualSupport] ?? 0}
                      >
                        <AddonMonthsMenu
                          months={addonMonths[SubscriptionProduct.MultiLingualSupport] ?? defaultAddonMonths}
                          onMonthsChange={(months: number) => onAddonMonthsChange(SubscriptionProduct.MultiLingualSupport, months)}
                        />
                      </AddonCalculator>
                    )}
                    {isAddonEnabled(subscription, SubscriptionProduct.LiveTranslation) && (
                      <AddonCalculator
                        AddonCard={(<LiveTranslationCard />)}
                        price={prices.addons[SubscriptionProduct.LiveTranslation] ?? 0}
                      >
                        <AddonMonthsMenu
                          months={addonMonths[SubscriptionProduct.LiveTranslation] ?? defaultAddonMonths}
                          onMonthsChange={(months: number) => onAddonMonthsChange(SubscriptionProduct.LiveTranslation, months)}
                        />
                      </AddonCalculator>
                    )}
                    {isAddonEnabled(subscription, SubscriptionProduct.AILiveCaptioning) && (
                      <AddonCalculator
                        AddonCard={(<AILiveCaptioningCard />)}
                        price={prices.addons[SubscriptionProduct.AILiveCaptioning] ?? 0}
                      >
                        <AddonMonthsMenu
                          months={addonMonths[SubscriptionProduct.AILiveCaptioning] ?? defaultAddonMonths}
                          onMonthsChange={(months: number) => onAddonMonthsChange(SubscriptionProduct.AILiveCaptioning, months)}
                        />
                      </AddonCalculator>
                    )}
                  </UiSimpleGrid>
                </UiStack>
              )}
              {hasUnitCosts && (
                <UiStack>
                  <UiText variant={'title'}>Other costs</UiText>
                  <UiSimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                    {isProductEnabled(subscription, SubscriptionProduct.Registration) && (
                      <UnitCostCalculator
                        label={'Estimated total number of registrations'}
                        defaultValue={numOfRegistrations}
                        price={prices.registration}
                        onValueChange={setNumOfRegistrations}
                      />
                    )}
                    {isProductEnabled(subscription, SubscriptionProduct.AbstractManagement) && (
                      <UnitCostCalculator
                        label={'Estimated total number of abstract submissions'}
                        defaultValue={numOfAbstractSubmissions}
                        price={prices.abstractSubmission}
                        onValueChange={setNumOfAbstractSubmissions}
                      />
                    )}
                    {isProductEnabled(subscription, SubscriptionProduct.DigitalPoster) && (
                      <UnitCostCalculator
                        label={'Estimated total number of digital poster submissions'}
                        defaultValue={numOfDigitalPosters}
                        price={prices.digitalPoster}
                        onValueChange={setNumOfDigitalPosters}
                      />
                    )}
                    {isProductEnabled(subscription, SubscriptionProduct.LiveTranslation) && (
                      <UnitCostCalculator
                        label={`Estimated cost of live translation (${subscription.plan.currency})`}
                        defaultValue={liveTranslationUsageCost}
                        price={prices.liveTranslation}
                        onValueChange={setLiveTranslationUsageCost}
                      />
                    )}
                    {isProductEnabled(subscription, SubscriptionProduct.AILiveCaptioning) && (
                      <UnitCostCalculator
                        label={`Estimated cost of AI live captioning (${subscription.plan.currency})`}
                        defaultValue={aiLiveCaptioningUsageCost}
                        price={prices.aiLiveCaptioning}
                        onValueChange={setAiLiveCaptioningUsageCost}
                      />
                    )}
                  </UiSimpleGrid>
                </UiStack>
              )}
              <UiHStack alignItems={'center'} justifyContent={'flex-end'}>
                <UiText variant={'title'}>
                  Total ({subscription.plan.currency})
                </UiText>
                <UiText variant={'title'}>
                  <PriceDisplay price={prices.total} />
                </UiText>
              </UiHStack>
            </UiStack>
            <UiStack px={12} alignItems={'flex-end'}>
              <UiHStack
                p={6}
                py={4}
                // bgColor={'primary.500'}
                borderRadius={uiStyles.borderRadius}
                spacing={2}
                {...uiStyles.hover}
                minW={300}
                maxW={300}
                borderWidth={'2px'}
                borderStyle={'solid'}
                borderColor={'primary.500'}
                {...uiStyles.hoverFlex({ bgColor: 'primary.50' })}
              >
                <UiIconUserCircle size={'3xl'} color={'text.primary'} />
                <UiText flexGrow={1}>Contact sales</UiText>
                <UiIconCaretRight color={'primary.500'} size={'3xl'} />
              </UiHStack>
            </UiStack>
          </UiStack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default ReviewDrawer;
