import { useMemo, type FC } from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import {
  uiStyles,
  UiIconCalendarBlank,
  type UiStackProps,
  UiIconHouse,
  UiStack,
  UiText,
  UiHStack
} from '@/lib/ui';
import InfoField from './InfoField';
import { UiIconBrowser } from '@/lib/ui/Icon/UiIconBrowser';
import dayjs from 'dayjs';
import { type Event } from '@/api/registration';

export interface InfoListProps extends UiStackProps {
  event: Event
}

const InfoCard: FC<InfoListProps> = ({
  event,
  ...props
}) => {
  const croppedWebsiteUrl = useMemo(
    () => event.websiteUrl ? event.websiteUrl.replace(/(^\w+:|^)\/\//, '') : ''
    , [event]);

  const whenText = useMemo(() => {
    if (event.startDate === event.endDate) {
      return dayjs(event.startDate).format('DD MMM YYYY');
    } else {
      return `${dayjs(event.startDate).format('DD MMM YYYY')} ~ ${dayjs(event.endDate).format('DD MMM YYYY')}`;
    }
  }, [event]);

  const formattedStartDate = dayjs(event.startDate).format('YYYY-MM-DD');
  const formattedEndDate = dayjs(event.endDate).format('YYYY-MM-DD');
  const formattedStartTime = dayjs(event.startTime).format('HH:mm');
  const formattedEndTime = dayjs(event.endTime).format('HH:mm');

  return (
    <UiStack
      spacing={8}
      borderRadius={uiStyles.borderRadius}
      enableDivider={true}
      {...props}
    >
      <UiHStack spacing={0} p={8} pb={0}>
        <UiText variant={'h6'}>{event.name}</UiText>
      </UiHStack>
      <UiStack
        spacing={8}
        borderRadius={uiStyles.borderRadius}
      >
        <InfoField
          Icon={UiIconBrowser}
          label={'Website'}
          description={croppedWebsiteUrl}
          url={event.websiteUrl}
        />
        <InfoField
          Icon={UiIconHouse}
          label={'Location'}
          url={event.mapUrl}
          description={event.location}
        />
        <InfoField
          Icon={UiIconCalendarBlank}
          label={'When'}
          description={whenText}
        />
      </UiStack>
      <UiHStack px={8} justifyContent={'flex-end'}>
        <AddToCalendarButton
          hideBranding
          name={event.name}
          startDate={formattedStartDate}
          endDate={formattedEndDate}
          startTime={formattedStartTime}
          endTime={formattedEndTime}
          location={event.location}
          options={['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo']}
          timeZone={event.timeZone}
          trigger="click"
          listStyle="modal"
          iCalFileName="event-reminder"
          buttonStyle={'flat'}
        />
      </UiHStack>
    </UiStack>
  );
};

export default InfoCard;
