import { type FC, useCallback, useMemo, useState } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
// import { sellerHost } from '@/api';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
// import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { type PaymentOption, type CreatePaymentRequest } from '@/api/registration';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';

export interface InvoiceFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  invoicePayment?: PaymentOption
}

interface FormData {
  accountName: string
  bsb: string
  accountNumber: string
  isEnabled?: boolean
}

interface FormErrors {
  accountName?: string
  bsb?: string
  accountNumber?: string
  isEnabled?: string
}

const isEnabledOptions: Option[] = [
  { value: true, label: 'Enable' },
  { value: false, label: 'Disable' }
];

const InvoiceForm: FC<InvoiceFormProps> = ({
  // tenantCode,
  onClose,
  onSaveSuccess,
  isVisible,
  invoicePayment
}) => {
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const { mutateAsync, isLoading } = useMutation<ApiResponse<PaymentOption>, Error, CreatePaymentRequest>({
    mutationFn: async (data: CreatePaymentRequest) => {
      return await registration.addPaymentOptions(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({ queryKey: [registration.paymentOptionQueryKey] });
        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save payment method']);
    }
  });

  const submitForm = useCallback(async (values: FormData) => {
    const { isEnabled, ...config } = values;
    void mutateAsync({
      id: invoicePayment?.id,
      paymentOptionType: 'invoice',
      isEnabled,
      config
    });
  }, [mutateAsync, invoicePayment?.id]);

  const initFormData = useMemo<FormData>(() => ({
    accountName: '',
    bsb: '',
    accountNumber: '',
    isEnabled: invoicePayment?.enabled ?? true
  }), [invoicePayment?.enabled]);

  return (
    <Formik
      initialValues={initFormData}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Set your bank account'}
        size={'xl'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => (
              <BaseMessageBarError key={index}>
                {error}
              </BaseMessageBarError>
            ))}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormSelectField
            name={'isEnabled'}
            label={'Enable this method?'}
            options={isEnabledOptions}
          />
          <BaseFormInputField
            name={'accountName'}
            label={'Account name'}
          />
          <BaseFormInputField
            name={'bsb'}
            label={'BSB'}
          />
          <BaseFormInputField
            name={'accountNumber'}
            label={'Account number'}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default InvoiceForm;
