import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import TicketCard from './TicketCard';
import { UiHStack, UiGrid, UiText } from '@/lib/ui';
import NewTicket from './NewTicket';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type TicketType } from '@/api/constant';
import { Skeleton } from '@chakra-ui/react';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import OrderChange from './OrderChange';

export interface TicketListProps {
  ticketType: TicketType
}

const TicketList: FC<TicketListProps> = ({ ticketType }) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const ticketQuery = useQuery(
    [registration.ticketListQueryKey, { eventId, ticketType }],
    async () => {
      const result = await registration.loadTicketList(createTenantApiRequest)({ eventId, ticketType });
      return result.items;
    },
    {
      enabled: !isApiPreparing
    }
  );

  return (
    <QueryContainer
      query={ticketQuery}
      loadingComponent={<Skeleton height='80px' />}
      errorMessage='Failed to load tickets.'>
      {(data) => (
        <>
          <UiHStack justifyContent={'space-between'}>
            <NewTicket eventId={eventId} ticketType={ticketType} tenantCode={tenantCode} />
            <OrderChange tickets={data} ticketType={ticketType} />
          </UiHStack>
          {/* <UiGrid */}
          {/*  templateColumns={{ base: '1fr', md: '1fr 1fr', xl: '1fr 1fr 1fr' }} */}
          {/*  gap={8} */}
          {/* > */}
          {data.map((ticket) => <TicketCard key={ticket.id} ticket={ticket} ticketType={ticketType} />)}
          {/* </UiGrid> */}
        </>
      )}
    </QueryContainer>
  );
};

export default TicketList;
