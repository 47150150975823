import { useMemo, type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiStack,
  uiStyles,
  UiText,
  UiHStack,
  UiTag,
  UiButton
} from '@/lib/ui';
import AboutOnline from './AboutOnline';
import OnlinePaymentForm from '@/registration/buildEvent/PaymentMethods/OnlinePaymentForm';
import { type PaymentOption } from '@/api/registration';

export interface OnlineProps extends UiHStackProps {
  paymentOptions: PaymentOption[]
}

const Online: FC<OnlineProps> = ({ paymentOptions }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isAboutOpen, onToggle: onAboutToggle } = useDisclosure();
  const onlinePayment = useMemo(
    () => paymentOptions.find((option) => (option.name === 'stripe'))
    , [paymentOptions]);
  return (
    <>
      <UiHStack
        justifyContent={'space-between'}
        alignItems={'center'}
        // {...uiStyles.hover}
        // alignItems={'flex-start'}
        // justifyContent={'flex-start'}
        spacing={4}
        py={6}
        px={8}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
      >
        <UiHStack spacing={4}>
          <UiStack spacing={0} alignItems={'stretch'} justifyContent={'space-between'}>
            <UiText variant={'body1'} fontWeight={600}>Credit Card payments</UiText>
            <UiText variant={'body2'} color={'text.secondary'}>Set up your own online payment gateway</UiText>
          </UiStack>
        </UiHStack>
        <UiHStack spacing={8}>
          { onlinePayment
            ? <UiTag py={2} px={4} colorScheme={'green'}>Enabled</UiTag>
            : <UiTag py={2} px={4} colorScheme={'red'}>Not enabled</UiTag>}
          <UiHStack
            spacing={0}
            justifyContent={'space-between'}
          >
            <UiButton
              px={0}
              variant={'ghost'}
              {...uiStyles.hover}
              colorScheme={'primary'}
              onClick={onToggle}
            >
              Settings
            </UiButton>
          </UiHStack>
        </UiHStack>
      </UiHStack>
      <OnlinePaymentForm
        onClose={onToggle}
        isVisible={isOpen}
        paymentOption={onlinePayment}
        onSaveSuccess={() => { }} />
      <AboutOnline
        onClose={onAboutToggle}
        isOpen={isAboutOpen} />
    </>
  );
};

export default Online;
