/* eslint-disable react/no-unescaped-entities */
import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  type UiBoxProps,
  UiStack,
  UiText,
  UiSpinner,
  uiStyles,
  UiButton,
  UiHStack, UiIconUserCircle, UiIconCaretRight, UiSimpleGrid
} from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';
import Summary from './Summary';
import ServicePackSummary from './ServicePackSummary';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { account } from '@/api';
import {
  getAddonsEnabled,
  getPackageProducts,
  isAddonEnabled,
  isProductEnabled,
  isProductIncluded
} from '@/account/subscription/utils';
import Registration from '@/account/component/SubscriptionModule/Registration';
import AbstractManagement from '@/account/component/SubscriptionModule/AbstractManagement';
import ContentDeliveryPlatform from '@/account/component/SubscriptionModule/ContentDeliveryPlatform';
import MobileEventApp from '@/account/component/SubscriptionModule/MobileEventApp';
import DigitalPoster from '@/account/component/SubscriptionModule/DigitalPoster';
import LiveStreaming from '@/account/component/SubscriptionModule/LiveStreaming';
import SponsorExhibitor from '@/account/component/SubscriptionModule/SponsorExhibitor';
import Webinar from '@/account/component/SubscriptionModule/Webinar';
import DataInsight from '@/account/component/SubscriptionModule/DataInsight';
import { subscriptionPackageLabels, SubscriptionProduct } from '@/api/constant/subscription';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import AddonAbstractManagement from '@/account/component/SubscriptionAddon/AbstractManagement';
import AddonDigitalPoster from '@/account/component/SubscriptionAddon/DigitalPoster';
import AddonEventApp from '@/account/component/SubscriptionAddon/EventApp';
import AddonLiveTranslation from '@/account/component/SubscriptionAddon/LiveTranslation';
import AddonMultiLingualSupport from '@/account/component/SubscriptionAddon/MultiLingualSupport';
import AddonAppWayfinder from '@/account/component/SubscriptionAddon/AppWayfinder';
import AddonContentHub from '@/account/component/SubscriptionAddon/ContentHub';
import AddonAILiveCaptioning from '@/account/component/SubscriptionAddon/AILiveCaptioning';
import ServicePack1 from '@/account/component/SubscriptionAddon/ServicePack1';
import ServicePack2 from '@/account/component/SubscriptionAddon/ServicePack2';
import ServicePack3 from '@/account/component/SubscriptionAddon/ServicePack3';
import BaseMessageBarInfo from '@/base/MessageBar/Info';

export interface AppListProps extends UiBoxProps {
}

const PlanSummary: FC<AppListProps> = () => {
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { data: subscription, isLoading } = useQuery<account.Subscription, Error>(
    [account.subscriptionQueryKey, {}],
    async () => {
      return await account.loadSubscription(createTenantAdminApiRequest)({});
    },
    {
      enabled: !isApiPreparing
    }
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (!subscription) {
    return (
      <UiStack p={8}>
        <BaseMessageBarError>
          Failed to load your current subscription.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  return (
    <UiStack spacing={0}>
      <UiText variant={'title'} pb={4}>Your plan</UiText>
      <UiStack spacing={0} enableDivider={true}>
        <Summary subscription={subscription} />
      </UiStack>
      <BaseDividerHorizontal height={8} />
      {/* <UiStack> */}
      {/*  {getProductsEnabled(data).length === 0 && ( */}
      {/*    <> */}
      {/*      <UiHStack spacing={4} alignItems={'center'} flexGrow={1} borderRadius={uiStyles.borderRadius} px={8} py={4} bgColor={'#fff'}> */}
      {/*        <UiText flexGrow={1}>You haven't subscribed any modules.</UiText> */}
      {/*        <UiHStack justifyContent={'flex-end'}> */}
      {/*          <UiButton colorScheme={'primary'}>Manage subscription</UiButton> */}
      {/*        </UiHStack> */}
      {/*      </UiHStack> */}
      {/*    </> */}
      {/*  )} */}
      {/* </UiStack> */}
      <UiStack spacing={0}>
        <UiText
          variant={'title'}
          pb={4}
        >
          {subscriptionPackageLabels[subscription.plan.package]} ({getPackageProducts(subscription, subscription.plan.package).length} modules)
        </UiText>
        <UiSimpleGrid columns={{ base: 2, '2xl': 3 }} spacing={8}>
          {isProductIncluded(subscription, SubscriptionProduct.Registration) && (
            <Registration isSelected={true} bgColor={'#fff'} />
          )}
          {isProductIncluded(subscription, SubscriptionProduct.EventWebsite) && (
            <ContentDeliveryPlatform isSelected={true} bgColor={'#fff'} />
          )}
          {isProductIncluded(subscription, SubscriptionProduct.Webinar) && (
            <Webinar isSelected={true} bgColor={'#fff'} />
          )}
          {isProductIncluded(subscription, SubscriptionProduct.SponsorExhibitor) && (
            <SponsorExhibitor isSelected={true} bgColor={'#fff'} />
          )}
          {isProductIncluded(subscription, SubscriptionProduct.LiveStreaming) && (
            <LiveStreaming isSelected={true} bgColor={'#fff'} />
          )}
          {isProductIncluded(subscription, SubscriptionProduct.DataInsight) && (
            <DataInsight isSelected={true} bgColor={'#fff'} />
          )}
          {isProductIncluded(subscription, SubscriptionProduct.MobileEventApp) && (
            <MobileEventApp isSelected={true} bgColor={'#fff'} />
          )}
          {isProductIncluded(subscription, SubscriptionProduct.AbstractManagement) && (
            <AbstractManagement isSelected={true} bgColor={'#fff'} />
          )}
          {isProductIncluded(subscription, SubscriptionProduct.DigitalPoster) && (
            <DigitalPoster isSelected={true} bgColor={'#fff'} />
          )}
        </UiSimpleGrid>
        {/* <BaseDividerHorizontal height={8} /> */}
        {/* <UiText variant={'title'} pb={4}>Business support</UiText> */}
        {/* <UiStack> */}
        {/*  {isServiceEnabled(subscription, SubscriptionService.ServicePack1) && ( */}
        {/*    <ServicePack1 */}
        {/*      isSelected={false} */}
        {/*    /> */}
        {/*  )} */}
        {/*  {isServiceEnabled(subscription, SubscriptionService.ServicePack2) && ( */}
        {/*    <ServicePack2 */}
        {/*      isSelected={false} */}
        {/*    /> */}
        {/*  )} */}
        {/*  {isServiceEnabled(subscription, SubscriptionService.ServicePack3) && ( */}
        {/*    <ServicePack3 */}
        {/*      isSelected={false} */}
        {/*    /> */}
        {/*  )} */}
        {/*  {isServiceEnabled(subscription, SubscriptionService.NoService) && ( */}
        {/*    <NoServicePack */}
        {/*      isSelected={false} */}
        {/*    /> */}
        {/*  )} */}
        {/* </UiStack> */}
        <BaseDividerHorizontal height={8} />

        <UiText variant={'title'} pb={4}>Add-ons ({getAddonsEnabled(subscription).length})</UiText>
        <UiSimpleGrid columns={{ base: 2, '2xl': 3 }} spacing={8}>
          {/*{isAddonEnabled(subscription, SubscriptionProduct.ServicePack1) && (*/}
          {/*  <ServicePack1 />*/}
          {/*)}*/}
          {/*{isAddonEnabled(subscription, SubscriptionProduct.ServicePack2) && (*/}
          {/*  <ServicePack2 />*/}
          {/*)}*/}
          {/*{isAddonEnabled(subscription, SubscriptionProduct.ServicePack3) && (*/}
          {/*  <ServicePack3 />*/}
          {/*)}*/}
          {isAddonEnabled(subscription, SubscriptionProduct.AbstractManagement) && (
            <AddonAbstractManagement />
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.DigitalPoster) && (
            <AddonDigitalPoster />
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.MobileEventApp) && (
            <AddonEventApp />
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.AppWayfinder) && (
            <AddonAppWayfinder />
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.ContentHub) && (
            <AddonContentHub />
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.MultiLingualSupport) && (
            <AddonMultiLingualSupport />
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.LiveTranslation) && (
            <AddonLiveTranslation />
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.AILiveCaptioning) && (
            <AddonAILiveCaptioning />
          )}
          {getAddonsEnabled(subscription).length === 0 && (
            <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'#fff'}>No Add-on added.</BaseMessageBarInfo>
          )}
        </UiSimpleGrid>
      </UiStack>
    </UiStack>
  );
};

export default PlanSummary;
