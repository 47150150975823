import { type FC, type ReactNode, useMemo, type PropsWithChildren, useState, useEffect } from 'react';
import {
  UiHStack,
  UiStack,
  uiStyles,
  UiImage
} from '@/lib/ui';
// import EventInfoList from '@/registration/component/Event/InfoList';
// import EventInfoCard from '@/registration/component/Event/InfoCard';
import Header from './Header';
// import DesktopEventInfo from './DesktopEventInfo';
// import MobileEventInfo from './MobileEventInfo';
import EventInfo from './EventInfo';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { getAppConfig } from '@/app/config';
import { useWindowScrollY } from '@/lib/hook';
import Footer from './Footer';

export interface StoreLayoutProps extends PropsWithChildren {
  header?: ReactNode
  children: ReactNode
}

const topNavHeight = 100;

const StoreLayout: FC<StoreLayoutProps> = ({
  header = (<Header />),
  children
}) => {
  const [topNavOpacity, setTopNavOpacity] = useState(1);
  const [topNavScale, setTopNavScale] = useState(1);
  const windowScrollY = useWindowScrollY();
  const { eventId } = useRegisterRoute();
  const app = getAppConfig();
  const { data: event } = useEventQuery(eventId);
  const coverImage = useMemo(() => {
    // Backend should always return the aboslute URL since in production, the url will be the CloudFront url.
    return event?.coverImage ?? '';
  }, [event]);

  useEffect(
    () => {
      setTopNavOpacity(1 - 2 * windowScrollY / topNavHeight);
      setTopNavScale(Math.min(1 - (0.15 * windowScrollY) / topNavHeight, 1));
    },
    [
      windowScrollY
    ]
  );

  return (
    <UiStack
      // p={{base: 4, md: 8}}
      minHeight={'100vh'}
      alignItems={'stretch'}
      bgColor={'gray.100'}
      borderRadius={uiStyles.borderRadius}
      spacing={0}
      flexGrow={1}
      bgGradient={'radial(indigo.50, gray.200)'}
      // bgGradient={'linear(150deg, primary.50 10%, indigo.50 30%, gray.200 100%)'}
    >
      {header && (
        <UiStack
          position={'fixed'}
          minH={`${topNavHeight}px`}
          maxH={`${topNavHeight}px`}
          width={'100%'}
          // bgColor={'red'}
          alignItems={'stretch'}
          opacity={topNavOpacity}
          transform={`scale(${topNavScale})`}
        >
          {header}
        </UiStack>
      )}
      <UiStack
        alignItems={'stretch'}
        flexGrow={1}
        {...uiStyles.glass}
        bgColor={'whiteAlpha.600'}
        mt={header ? `${topNavHeight}px` : undefined}
        zIndex={99}
      >
        {coverImage && (
          <UiStack>
            <UiImage src={coverImage ?? ''} maxHeight={'400px'} />
          </UiStack>
        )}
        <EventInfo />
        <UiStack
          // p={{base: 8}}
          p={8}
          pt={{ base: 0, lg: 8 }}
          spacing={0}
          flexGrow={1}
          alignItems={'stretch'}
        >
          <UiHStack flexGrow={1} justifyContent={'space-around'} alignItems={'flex-start'}>
            <UiStack
              // minW={320}
              maxW={1440}
              flexGrow={1}
              // py={{base: 8, md: 8}}
            >
              {/* <EventInfoCard eventId={'1'} bgColor={'blackAlpha.50'}/> */}
              <UiStack py={8}>
                {children}
              </UiStack>
            </UiStack>
          </UiHStack>
        </UiStack>
      </UiStack>
      {/* Override brand footer */}
      <Footer />
    </UiStack>
  );
};

export default StoreLayout;
