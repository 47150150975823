import { type FC } from 'react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiSimpleGrid, UiStack, uiStyles, UiText } from '@/lib/ui';

export interface TicketProps {
  name: string
  amount: string
  isDiscounted: boolean
}

const Ticket: FC<TicketProps> = ({
  name,
  amount,
  isDiscounted,
}) => {
  const { tenantCode } = useRegisterRoute();

  return (
    <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1} enableDivider={true} spacing={4}>
      <UiStack>
        <UiText>{name}</UiText>
      </UiStack>
      <UiSimpleGrid columns={2} gap={8}>
        <UiStack>
          <UiText color={'text.secondary'}>Amount</UiText>
          <UiText>{amount}</UiText>
        </UiStack>
        <UiStack>
          <UiText color={'text.secondary'}>Discounted</UiText>
          <UiText>{isDiscounted ? 'Yes' : 'No'}</UiText>
        </UiStack>
      </UiSimpleGrid>
    </UiStack>
  );
};

export default Ticket;
