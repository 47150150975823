import { useCallback, useMemo, type FC } from 'react';
// import Filter from './Filter';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiStack } from '@/lib/ui';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { Form, Formik, type FormikHelpers } from 'formik';
import { type CloneEventRequest, type Event } from '@/api/registration';
import BaseFormSelectField from '@/base/Form/SelectField';
import * as Yup from 'yup';
import { useEventListQuery } from '@/registration/hook/useEventListQuery';
import { useMutation } from '@tanstack/react-query';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import BaseMessageBarError from '@/base/MessageBar/Error';
import QueryContainer from '@/base/QueryContainer/QueryContainer';

const formSchema = Yup.object().shape({
  eventId: Yup.string().required('Event is required.')
});

interface FormData {
  eventId?: string
}

interface CloneEventFormProps {
  onClose: () => void
}

const CloneEventForm: FC<CloneEventFormProps> = ({ onClose }) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const eventListQuery = useEventListQuery();
  const { createTenantAdminApiRequest } = useTenantApi();
  const navigate = useNavigate();

  const options = useMemo(
    () => eventListQuery.data?.items
      ?.filter((item) => Number(item.id) !== Number(eventId))
      ?.map(item => ({ value: item.id, label: item.name })) ?? []
    , [eventId, eventListQuery.data?.items]);

  const { mutateAsync: cloneEvent, isLoading, error } = useMutation<ApiResponse<Event>, Error, CloneEventRequest>({
    mutationFn: async (request) => {
      return await registration.cloneEvent(createTenantAdminApiRequest)(request);
    },
    onSuccess: (data) => {
      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors.join(', '));
      }
    },
    onError: () => {
      // setErrors([error.message ?? 'Failed to save the attendee category.']);
      // setErrors(['Login failed (connection error).']);
    }
  });

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    await cloneEvent({ eventFromId: values.eventId!.toString(), eventToId: eventId });
    navigate(generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId }));
    setSubmitting(false);
  }, [cloneEvent, eventId, navigate, tenantCode]);

  return (
    <Formik<FormData>
      initialValues={{}}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <QueryContainer query={eventListQuery}>
          {() => (
            <BaseTransitionSlideLeft>
              <UiStack flexGrow={1}>
                {error && (
                  <UiStack spacing={4} flexGrow={1} py={4}>
                    <BaseMessageBarError key={error.message}>
                      {error.message}
                    </BaseMessageBarError>
                  </UiStack>
                )}
                <BaseFormSelectField
                  name={'eventId'}
                  label={'Event'}
                  layout="stack"
                  options={options}
                />
                <UiHStack spacing={8}>
                  <UiButton colorScheme={'primary'} variant={'ghost'} onClick={onClose}>
                    Back
                  </UiButton>
                  <UiButton px={8} size={'lg'} colorScheme={'primary'} type='submit' isLoading={isLoading}>
                    Clone and start building
                  </UiButton>
                </UiHStack>
              </UiStack>
            </BaseTransitionSlideLeft>
          )}
        </QueryContainer>
      </Form>
    </Formik>
  );
};

export default CloneEventForm;
