import { type FC } from 'react';
import {
  UiHStack,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText
} from '@/lib/ui';
import { type RegistrationInfo } from '@/api/registration';
import dayjs from 'dayjs';

export interface RegistrationSummaryProps {
  registration: RegistrationInfo
}

const RegistrationSummary: FC<RegistrationSummaryProps> = ({ registration }) => {
  return (
    <>
      <UiStack spacing={4}>
        <UiText variant={'title'}>Summary</UiText>
        <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1}>
          <UiSimpleGrid columns={3} gap={8}>
            <UiStack>
              <UiText color={'text.secondary'}>Email</UiText>
              <UiHStack spacing={4}>
                <UiText>{registration.email}</UiText>
              </UiHStack>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Status</UiText>
              <UiText>{registration.status}</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Attendee Category</UiText>
              <UiText>{registration.attendeeCategory.name}</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Event</UiText>
              <UiText>{registration.event.name} (id: {registration.event.id})</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>CreatedAt</UiText>
              <UiText>{dayjs(registration.createdAt).format('HH:mm, DD/MM/YYYY')}</UiText>
            </UiStack>
          </UiSimpleGrid>
        </UiStack>
      </UiStack>
    </>
  );
};

export default RegistrationSummary;
