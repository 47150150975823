import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiHStackProps,
  uiStyles,
  UiText,
  UiHStack,
  UiIconListNumbers
} from '@/lib/ui';
import OrderChangeDrawer from './OrderChangeDrawer';

export interface OrderChangeProps extends UiHStackProps {
}

const OrderChange: FC<OrderChangeProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiHStack
        // bgColor={'gray.50'}
        borderRadius={'100%'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        // flexGrow={1}
        onClick={onToggle}
        {...uiStyles.hover}
        // width={'64px'}
        // flexGrow={}
        p={0}
      >
        <UiIconListNumbers
          size={'3xl'}
          color={'primary.600'}
          // width={64}
          weight={'light'}
        />
        <UiText color={'primary.500'} fontWeight={500}>Reorder</UiText>
      </UiHStack>
      <OrderChangeDrawer isOpen={isOpen} onClose={onToggle} />
    </>
  );
};

export default OrderChange;
