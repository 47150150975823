import { type AdminUserState } from '@/api/constant/adminUser';
import {
  type ApiRequest,
  callApi
} from '@/api/globalClient';

export const adminUserQueryKey = 'account.adminUser';

export interface AdminUserDataTenant {
  id: number
  state: number
  name: string
  code: string
  apiEndpoint: string
  createdAt: string
  updatedAt: string
}

export interface AdminUserData {
  id: number
  state: AdminUserState
  uid: string
  email: string
  tenants: AdminUserDataTenant[]
  createdAt: string | null
  updatedAt: string | null
}

export const adminUserDefaultData: AdminUserData = {
  id: 0,
  state: 2,
  uid: '',
  email: '',
  tenants: [],
  createdAt: '',
  updatedAt: ''
};

export interface AdminUserLoadByEmailRequest {
  email: string // Admin user email.
}

export interface AdminUserLoadByEmailResponse {
  item: AdminUserData
  errors: string[]
}

/**
 * Always return an admin user data.
 * If the email is empty, an empty admin user data will be returned.
 */
export async function loadAdminUserByEmail(
  params: AdminUserLoadByEmailRequest
): Promise<AdminUserLoadByEmailResponse> {
  if (params.email) {
    const request: ApiRequest = {
      method: 'POST',
      endpoint: {
        path: '/api/admin-user/load-by-email'
      },
      payload: {
        email: params.email
      }
    };
    const response = await callApi<AdminUserLoadByEmailResponse>(request);
    return {
      item: response.item,
      errors: response.errors
    };
  }
  return {
    item: adminUserDefaultData,
    errors: []
  };
}

export interface AdminUserSendInvitesRequest {
  authToken: string // This is the session token for the user who sends out the invites.
  emails: string[]
}

export interface AdminUserSendInviteResponse {
  errors: string[]
}

export async function sendAdminUserInvites(params: AdminUserSendInvitesRequest): Promise<AdminUserSendInviteResponse> {
  if (Array.isArray(params.emails) && params.emails.length > 0) {
    const request: ApiRequest = {
      method: 'POST',
      endpoint: {
        path: '/api/admin-user/send-invites'
      },
      authToken: params.authToken,
      payload: {
        emails: params.emails
      }
    };
    const response = await callApi<AdminUserLoadByEmailResponse>(request);
    return {
      errors: response.errors
    };
  }
  return {
    errors: [],
  }
}
