import { type FC, useEffect, useMemo, useState } from 'react';
import dayjs, { type Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import {
  UiTag
} from '@/lib/ui';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);

export interface TimeTagProps {
  toDate: Dayjs
}

const TimeTag: FC<TimeTagProps> = ({ toDate }) => {
  const isExpired = useMemo(() => toDate.isBefore(dayjs()), [toDate]);
  const diffHours = useMemo(() => toDate.diff(dayjs(), 'hours'), [toDate]);
  const timeToNow = useMemo(() => dayjs.duration(toDate.diff(dayjs())).humanize(true), [toDate]);

  if (isExpired) {
    return (<UiTag p={2} colorScheme="gray">No longer available</UiTag>);
  }
  if (diffHours > 24 * 7) {
    return (<UiTag p={2} colorScheme="green">Available for: {timeToNow}</UiTag>);
  }
  if (diffHours > 12) {
    return (<UiTag p={2} colorScheme="orange">Available for: {timeToNow}</UiTag>);
  }
  return (<CountdownTimeTag toDate={toDate} />);
};

const CountdownTimeTag: FC<TimeTagProps> = ({ toDate }) => {
  const [countdownTime, setCountdownTime] = useState(dayjs.duration(toDate.diff(dayjs())).format('HH:mm:ss'));

  useEffect(() => {
    const id = setInterval(() => {
      setCountdownTime(dayjs.duration(toDate.diff(dayjs())).format('HH:mm:ss'));
    }, 1000);

    return () => clearInterval(id);
  }, [toDate]);

  return (<UiTag p={2} colorScheme="red">Available for: {countdownTime}</UiTag>);
};

export default TimeTag;
