import { Center } from '@chakra-ui/react';
import { type UseQueryResult } from '@tanstack/react-query';
import BaseMessageBarError from '../MessageBar/Error';
import Spinner from '../Loading/Spinner';

interface QueryContainerProps<T> {
  query: UseQueryResult<T>
  loadingComponent?: React.ReactElement
  errorMessage?: string
  children: (data: T) => React.ReactElement
}

export function CenteredSpinner() {
  return (
    <Center h="100%" >
      <Spinner size="lg" />
    </Center>
  );
}

export function QueryContainer<T>(
  { query, loadingComponent, children, errorMessage }: QueryContainerProps<T>
) {
  const { data, isLoading, isError } = query;
  if (isLoading) {
    return loadingComponent ?? (
      <CenteredSpinner />
    );
  }
  if (isError || data === undefined || data === null) {
    return (
      <BaseMessageBarError>
        {errorMessage ?? 'Failed to load data.'}
      </BaseMessageBarError>
    );
  }
  return children(data);
}

export default QueryContainer;
