/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AIChatInterface from './AIChatInterface';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { type AIChatMessage, type AIChatSession } from '@/api/registration';
import useActionCable from '@/lib/hook/useActionCable';
import useChannel from '@/lib/hook/useChannel';
import EventDataVisualizer, { type EventSchema } from './EventDataVisualizer';
import { Box, Flex } from '@chakra-ui/react';

function AIChatSessionWindow() {
  const { actionCable } = useActionCable('ws://localhost:3000/cable');
  const { subscribe, unsubscribe } = useChannel<AIChatMessage>(actionCable);
  const [eventData, setEventData] = useState<EventSchema>({});

  const aiChatSessionId = '1';
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();

  useEffect(() => {
    subscribe(
      { channel: 'AIChatChannel', id: Number(aiChatSessionId) },
      {
        received: (message: AIChatMessage) => {
          updateMessagesMutation(message);
          if (message.data) {
            setEventData(JSON.parse(message.data));
          }
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, [subscribe, unsubscribe, aiChatSessionId]);

  const aiChatSessionQuery = useQuery<ApiResponseSingle<AIChatSession>, Error>({
    queryKey: [registration.aiChatSessionQueryKey, aiChatSessionId],
    queryFn: async () => {
      const response = await registration.loadAISession(createTenantApiRequest)(aiChatSessionId);
      setEventData(JSON.parse(response.item.data));
      return response;
    },
    enabled: !isApiPreparing
  });

  const { mutate: postMessageMutation, isLoading: isPostMessageLoading } = useMutation<ApiResponseSingle<AIChatMessage>, Error, string>({
    mutationFn: async (newMessage: string) => {
      return await registration.saveAIChatMessage(createTenantAdminApiRequest)({ aiChatSessionId, message: newMessage });
    },
    onMutate: async (newMessage: string) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([registration.aiChatSessionQueryKey, aiChatSessionId]);

      // Snapshot the previous value
      const previousSession = queryClient.getQueryData<ApiResponseSingle<AIChatSession>>([registration.aiChatSessionQueryKey, aiChatSessionId]);

      // Optimistically update to the new value
      queryClient.setQueryData([registration.aiChatSessionQueryKey, aiChatSessionId], (old: ApiResponseSingle<AIChatSession> | undefined) => {
        if (old) {
          const newMessages = [...old.item.messages, { sender: 'user' as const, message: newMessage, id: Date.now(), aiChatSessionId, createdAt: new Date().toISOString() }]; // Temporarily use timestamp as id
          newMessages.sort((a, b) => a.id - b.id);
          return { ...old, item: { ...old.item, messages: newMessages } };
        }
        return old;
      });

      // Return a context object with the snapshotted value
      return { previousSession };
    },
    // onError: (error, newMessage, context) => {
    //   // Roll back to the previous value
    //   queryClient.setQueryData([registration.aiChatSessionQueryKey, aiChatSessionId], context.previousSession);
    // },
    onSettled: () => {
      // After success or failure, refetch the messages query
      void queryClient.invalidateQueries([registration.aiChatSessionQueryKey, aiChatSessionId]);
    }
  });

  const { mutate: updateMessagesMutation } = useMutation<AIChatMessage, Error, AIChatMessage>({
    mutationFn: async (newMessage: AIChatMessage) => {
      return newMessage;
    },
    onMutate: async (newMessage: AIChatMessage) => {
      await queryClient.cancelQueries([registration.aiChatSessionQueryKey, aiChatSessionId]);

      const previousSession = queryClient.getQueryData<ApiResponseSingle<AIChatSession>>([registration.aiChatSessionQueryKey, aiChatSessionId]);

      queryClient.setQueryData([registration.aiChatSessionQueryKey, aiChatSessionId], (old: ApiResponseSingle<AIChatSession> | undefined) => {
        if (old) {
          const newMessages = [...old.item.messages, newMessage];
          newMessages.sort((a, b) => a.id - b.id);
          return { ...old, item: { ...old.item, messages: newMessages } };
        }
        return old;
      });

      return { previousSession };
    },
    onSettled: () => {
      void queryClient.invalidateQueries([registration.aiChatSessionQueryKey, aiChatSessionId]);
    }
  });

  const sendMessage = (newMessage: string) => {
    postMessageMutation(newMessage);
  };

  if (aiChatSessionQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (aiChatSessionQuery.isError) {
    return <div>Error loading messages</div>;
  }

  return (
    <Flex direction="column" h="100vh">
      <Box flex="1" maxH='50vh'>
        {eventData && <EventDataVisualizer eventData={eventData} substate={aiChatSessionQuery.data.item.substate} />}
      </Box>
      <Box flex="1" maxH='50vh'>
        <AIChatInterface messages={aiChatSessionQuery.data.item.messages} sendMessage={sendMessage} />
      </Box>
    </Flex>
  );
}

export default AIChatSessionWindow;
