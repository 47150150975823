import { type FC, useEffect, useMemo } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { generatePageUrl } from '@/app/pages';
import { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import Management from './Management';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import PlanSummary from '@/account/subscription/PlanManagement/PlanSummary';
import { SubscriptionEditor, useSubscriptionPlanEditor } from '@/account/hook/useSubscriptionPlanEditor';
import { UiStack } from '@/lib/ui';

const PlanManagement: FC = () => {
  const { tenant } = useTenantRoute();
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Subscription' },
        { label: 'Plan', url: generatePageUrl('AccountSubscriptionPlan', { tenantCode: tenant?.code ?? '' }) },
        { label: 'Change plan' }
      ];
    },
    [tenant?.code]
  );

  const subscriptionEditor = useSubscriptionPlanEditor();

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        // disableTopNav={true}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard showDescription={false}/>} currentAppName={'account'} />)}
        MainTransition={null}
      >
        <BreadcrumbBody
          breadcrumbNodes={breadcrumbNodes}
        >
          <Management subscriptionEditor={subscriptionEditor} />
        </BreadcrumbBody>
      </BaseLayout>
      <UiStack height={{ base: '192px', lg: '96px' }} />
      <PlanSummary subscription={subscriptionEditor.subscription} />
    </>
  );
};

export default PlanManagement;
