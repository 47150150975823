import { useMemo, type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiStack,
  UiText,
  uiStyles,
  UiHStack,
  UiButton,
  UiIconShare
} from '@/lib/ui';
import dayjs from 'dayjs';
import { type Order } from '@/api/registration/order';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  XIcon
} from 'react-share';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
export interface RegistrationDetailsProps {
  order: Order
}

const RegistrationDetails: FC<RegistrationDetailsProps> = ({ order }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: event } = useEventQuery(order.eventId);
  const url = useMemo(
    () => window.location.origin + generatePageUrl('RegistrationRegisterEmail', { tenantCode, eventId })
    , [eventId, tenantCode]);
  const title = useMemo(
    () => `I just registered for "${event?.name}"!`
    , [event?.name]);
  return (
    <>
      <UiStack
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        {...uiStyles.hover}
        onClick={onToggle}
        justifyContent={'space-between'}
        px={{ base: 4, lg: 8 }}
        py={8}
        spacing={4}
        enableDivider={true}
      >
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Serial</UiText>
          <UiText variant='body1'>{order.serial}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Name</UiText>
          <UiText variant='body1'>{order.attendee.name}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Email</UiText>
          <UiText variant='body1'>{order.attendee.email}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Event</UiText>
          <UiText variant='body1'>{order.event}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Total Price</UiText>
          <UiText variant='body1'>{order.totalPrice}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Purchased</UiText>
          <UiText variant='body1'>{dayjs(order.purchasedAt).format('DD MMM YYYY, h:mm A')}</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='body1' color={'text.secondary'}>Tickets</UiText>
        </UiHStack>
        <UiHStack justifyContent={'space-between'}>
          <UiText variant='caption'>{'Name'}</UiText>
          <UiText variant='caption'>{'Quantity'}</UiText>
        </UiHStack>
        {order.tickets.map((ticket, index) => (
          <UiHStack key={index} justifyContent={'space-between'}>
            <UiText variant='body2'>{ticket.name}</UiText>
            <UiText variant='body2'>{ticket.quantity}</UiText>
          </UiHStack>
        ))}
      </UiStack>
      <UiHStack justifyContent={'flex-end'}>
        {/* <UiButton
          variant={'ghost'}
          colorScheme={'primary'}
          leftIcon={(<UiIconShare size={'3xl'} color={'primary.500'} />)}
        >
          Share
        </UiButton> */}
        <LinkedinShareButton url={url} title={title}>
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton url={url} title={title}>
          <XIcon size={32} round={true} />
        </TwitterShareButton>
        <FacebookShareButton url={url}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <UiButton
          px={8}
          size={'lg'}
          shadow={'base'}
          colorScheme={'primary'}
          onClick={() => window.open(event?.websiteUrl, '_blank')}
        >
          Go to {order.event} website
        </UiButton>
      </UiHStack>
    </>
  );
};

export default RegistrationDetails;
