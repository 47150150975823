import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { type OrderTableRow, loadOrdersForTable, orderTableQueryKey } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import Datatable from './Datatable';
import { type ApiResponse } from '@/api/tenantClient';
import { CenteredSpinner } from '@/base/QueryContainer/QueryContainer';

export interface SerializedOrderTableRow extends Omit<OrderTableRow, 'purchasedAt'> {
  purchasedAt: Date
}

interface Params {
  filters: any
  sorting: any
  pagination: any
}

const useGetOrders = ({ filters, sorting, pagination, setPagination }: Params & { setPagination: React.Dispatch<React.SetStateAction<any>> }) => {
  const { createTenantAdminApiRequest } = useTenantApi();

  return useQuery<ApiResponse<OrderTableRow>, Error>({
    queryKey: [orderTableQueryKey, pagination],
    queryFn: async (): Promise<ApiResponse<OrderTableRow>> => {
      const response = await loadOrdersForTable(createTenantAdminApiRequest)({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting
      });
      return response;
    },
    keepPreviousData: true,
    staleTime: 30_000
  });
};

const OrderTable = () => {
  const [filters, setFilters] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [pagination, setPagination] = useState<any>({ pageIndex: 0, pageSize: 10 });

  const { data, isLoading, isError, isFetching, refetch } = useGetOrders({ filters, sorting, pagination, setPagination });

  if (!data || isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <Datatable
      data={data.items.map((order) => ({
        ...order,
        purchasedAt: new Date(order.purchasedAt)
      }))}
      isLoading={isLoading}
      isError={isError}
      isFetching={isFetching}
      refetch={refetch}
      filters={filters}
      setFilters={setFilters}
      sorting={sorting}
      setSorting={setSorting}
      pagination={pagination}
      setPagination={setPagination}
      rowCount={data.pagination!.total}
      eventSelectOptions={data.selectOptions!.eventSelectOptions}
      ticketSelectOptions={data.selectOptions!.ticketSelectOptions}
    />
  );
};

export default OrderTable;
