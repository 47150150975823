import { type FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import {
  UiDivider,
  UiHStack,
  UiStack,
  UiText,
  uiStyles,
  UiVStack,
  UiIconDotsNine
} from '@/lib/ui';
import { RouteParams } from '@/app/pages';
// import Filter from './Filter';
import BaseBreadcrumbBar, { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import SmartWindow from '@/base/Layout/SmartWindow';
// import AppList from './AppList';
import SideNav from '@/account/component/Layout/SideNav';
import SwitchApp from '@/account/component/Layout/SwitchApp';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import NewTeam from './NewTeam';
import TeamList from './TeamList';
// import MemberTable from './MemberTable';

const Team: FC = () => {
  const [isSmartWindowVisible, setIsSmartWindowVisible] = useState(false);
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Dashboard' },
        { label: 'Team' }
      ];
    },
    []
  );

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow/>)}
        sideMenu={(<SideNav activeNodes={['userAccess', 'team']}/>)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard showDescription={false}/>} currentAppName={'account'}/>)}
      >
        <BaseLayoutBody
          isStandalone={false}
        >
          <UiStack flexGrow={1} spacing={8} alignItems={'stretch'}>
            <NewTeam/>
            <TeamList/>
          </UiStack>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default Team;
