import { useState, type FC, useMemo } from 'react';
import {
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiStack,
  UiButton,
  UiHStack,
  UiText,
  uiStyles
} from '@/lib/ui';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useCartQuery } from '@/registration/hook/useCartQuery';
import { preparePayment, type PaymentIntent, type PreparePaymentRequest, paymentIntentQueryKey } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import Cart from '@/registration/component/Register/ShoppingCart/Cart';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';

export interface PaymentByInvoiceDrawerProps extends Omit<UiDrawerProps, 'children'> {
}

const PaymentByInvoiceDrawer: FC<PaymentByInvoiceDrawerProps> = ({
  ...props
}) => {
  const queryClient = useQueryClient();
  const { createTenantApiRequest } = useTenantApi();
  const [errors, setErrors] = useState<string[]>([]);

  const navigate = useNavigate();
  const { tenantCode, eventId } = useRegisterRoute();
  const { toNextStep } = useRegisterSteps();

  const cartQuery = useCartQuery();

  const { mutate: preparePaymentMutate, isLoading: isPreparePaymentLoading } = useMutation<ApiResponse<PaymentIntent>, Error, PreparePaymentRequest>({
    mutationFn: async (preparePaymentData: PreparePaymentRequest) => {
      return await preparePayment(createTenantApiRequest)(preparePaymentData);
    },
    onSuccess: (order) => {
      void queryClient.invalidateQueries({ queryKey: [paymentIntentQueryKey] });
      const orderResponse = order.items?.[0].order;
      if (orderResponse) {
        const additionalParams = { orderHash: orderResponse.secureHash };
        toNextStep(additionalParams);
      }
    },
    onError: (error: any) => {
      setErrors([error.message ?? 'Failed to save the host.']);
    }
  });

  const onSubmit = () => {
    if (!cartQuery.data) {
      return;
    }
    const preparePaymentData: PreparePaymentRequest = {
      cartId: cartQuery.data.id,
      locale: 'aud',
      gateway: 'invoice'
    };
    preparePaymentMutate(preparePaymentData);
  };

  return (
    <UiDrawer placement={'right'} size={'xl'} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        <UiDrawerCloseButton size={'lg'} fontWeight={'bold'} color={'primary.500'} />
        <QueryContainer query={cartQuery}>
          {cart => (
            <UiDrawerBody px={0} pb={16} alignItems={'stretch'}>
              {errors.length > 0 && (
                <UiStack spacing={4} flexGrow={1} py={4}>
                  {errors.map((error) => (
                    <BaseMessageBarError key={error}>
                      {error}
                    </BaseMessageBarError>
                  ))}
                </UiStack>
              )}
              <UiStack
                px={{ base: 4, lg: 8 }}
                py={8}
                flexGrow={1}
                spacing={6}
              >
                <UiText variant={'h6'}>Pay by invoice</UiText>
                <BaseMessageBarInfo
                  borderRadius={uiStyles.borderRadius}
                  bgColor={'#fff'}
                >
                  We will send you an email with payment details.
                </BaseMessageBarInfo>
                <BaseDividerHorizontal height={2} />
                <Cart />
              </UiStack>
              <UiHStack px={{ base: 4, lg: 8 }} justifyContent={'flex-end'}>
                <UiButton
                  px={8}
                  size={'lg'}
                  shadow={'base'}
                  colorScheme={'primary'}
                  onClick={onSubmit}
                  isDisabled={cart.cartItems.length < 1}
                  isLoading={isPreparePaymentLoading}>
                  Confirm
                </UiButton>
              </UiHStack>
            </UiDrawerBody>
          )}
        </QueryContainer>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default PaymentByInvoiceDrawer;
