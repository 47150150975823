import { type FC, useMemo } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import {
  UiStack,
  UiText,
  UiIconCurrencyCircleDollar,
  uiStyles,
  UiHStack,
  UiTag
} from '@/lib/ui';
import BaseTextList from '@/base/Text/List';
import BaseTextListItem from '@/base/Text/ListItem';
import { locale } from '@/lib/util';
import { type TicketData } from '@/api/registration';
import TicketDrawer from './TicketDrawer';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import TimeTag from './TimeTag';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);
const { createFormatPrice } = locale;

export interface TicketProps {
  ticket: TicketData
}

const priceLocale = 'en-AU';
const priceCurrency = 'AUD';

const Ticket: FC<TicketProps> = ({ ticket }) => {
  const { eventId } = useRegisterRoute();
  const { data: event } = useEventQuery(eventId);
  const { isOpen, onToggle } = useDisclosure();
  const { registrationQuery } = useRegistrationAuth();
  const registration = registrationQuery?.data;
  const validToDay = useMemo(() =>
    dayjs.tz(ticket.validTo, event?.tzInfoName ?? 'America/Los_Angeles').add(1, 'day')
  , [event?.tzInfoName, ticket.validTo]);
  const isExpired = useMemo(() => validToDay.isBefore(dayjs()), [validToDay]);

  const price = useMemo(
    () => createFormatPrice({ locale: priceLocale, currency: priceCurrency })((ticket?.price ?? 0) * 100),
    [ticket?.price]
  );
  const discountStatus = useMemo(() => {
    if (!registration?.discount || !ticket?.ticketDiscounts) return undefined;
    return (ticket?.ticketDiscounts.find((ticketDiscount) => ticketDiscount.active && Number(ticketDiscount.id) === registration.discount?.id))
      ? 'applied' : 'unavailable';
  }, [ticket?.ticketDiscounts, registration?.discount]);
  return (
    <>
      <UiStack
        bgColor={isExpired || ticket.currentStock <= 0 ? 'gray.300' : '#fff'}
        borderRadius={uiStyles.borderRadius}
        {...isExpired || ticket.currentStock <= 0 ? {} : uiStyles.hover}
        onClick={isExpired || ticket.currentStock <= 0 ? undefined : onToggle}
        enableDivider={true}
        spacing={6}
        p={8}
      >
        <UiStack>
          <BaseTextList>
            <BaseTextListItem variant='h6'>{ticket?.name}</BaseTextListItem>
            <BaseTextListItem variant='body1' color={'text.secondary'}>{ticket?.description}</BaseTextListItem>
          </BaseTextList>
          <UiHStack>
            <TimeTag toDate={validToDay} />
            {ticket.currentStock <= 0 && (<UiTag p={2} colorScheme="gray">Out of stock</UiTag>)}
            {discountStatus === 'applied' && <UiTag p={2} colorScheme="green">Discount applied</UiTag>}
            {discountStatus === 'unavailable' && <UiTag p={2} colorScheme="gray">Discount unavailable</UiTag>}
          </UiHStack>
        </UiStack>
        <UiHStack>
          <UiIconCurrencyCircleDollar size={'2xl'} color={'text.primary'} />
          <UiText flexGrow={1} pl={2}>Price</UiText>
          <UiText>{price}</UiText>
        </UiHStack>
      </UiStack>
      <TicketDrawer
        ticket={ticket}
        price={price}
        isOpen={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default Ticket;
