import { useMemo, type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  UiButton,
  UiHStack,
  type UiHStackProps,
  UiStack,
  uiStyles,
  UiText,
  UiTag
} from '@/lib/ui';
import InvoiceForm from './InvoiceForm';
import AboutInvoice from './AboutInvoice';
import { type PaymentOption } from '@/api/registration';
// import { text } from '@/util';

export interface InvoiceProps extends UiHStackProps {
  paymentOptions: PaymentOption[]
}

const Invoice: FC<InvoiceProps> = ({ paymentOptions }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isAboutOpen, onToggle: onAboutToggle } = useDisclosure();
  const invoicePayment = useMemo(
    () => paymentOptions.find((option) => (option.name === 'invoice'))
    , [paymentOptions]);
  return (
    <>
      <UiHStack
        justifyContent={'space-between'}
        alignItems={'stretch'}
        spacing={4}
        py={6}
        px={8}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
      >
        <UiHStack spacing={4}>
          <UiStack spacing={0} alignItems={'stretch'} justifyContent={'space-between'}>
            <UiText variant={'body1'} fontWeight={600}>Invoice payments</UiText>
            <UiText variant={'body2'} color={'text.secondary'}>Set up your bank account details</UiText>
          </UiStack>
        </UiHStack>
        <UiHStack spacing={8}>
          { invoicePayment && invoicePayment.enabled
            ? <UiTag py={2} px={4} colorScheme={'green'}>Enabled</UiTag>
            : <UiTag py={2} px={4} colorScheme={'red'}>Not enabled</UiTag>}
          {/* <UiStack {...uiStyles.hover} onClick={onAboutToggle}> */}
          {/*  <UiIconInfo size={'3xl'} color={'primary.500'} /> */}
          {/* </UiStack> */}
          <UiHStack
            spacing={0}
            justifyContent={'space-between'}
          >
            <UiButton
              px={0}
              variant={'ghost'}
              {...uiStyles.hover}
              colorScheme={'primary'}
              onClick={onToggle}
            >
              Settings
            </UiButton>
          </UiHStack>
        </UiHStack>
      </UiHStack>
      <InvoiceForm
        onClose={onToggle}
        isVisible={isOpen}
        invoicePayment={invoicePayment}
        onSaveSuccess={() => {}}
      />
      <AboutInvoice
        onClose={onAboutToggle}
        isOpen={isAboutOpen}
      />
    </>
  );
};

export default Invoice;
