import { type EventSchema } from '@/registration/eventGeneration/EventDataVisualizer';
import { type ApiResponseSingle, type ApiRequest, properCallApi } from '../tenantClient';
import { type AIChatMessage } from './aiChatMessage';

export const aiChatSessionQueryKey = 'registration.aiChatSession';

export type Substate = 'attendee_categories' | 'discounts' | 'info_forms' | 'delegate_types' | 'sessions_pricings';

export interface AIChatSession {
  id: number
  type: string
  state: string
  substate: Substate
  messages: AIChatMessage[]
  data: string
}

export function loadAISession(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    aiChatSessionId: string
  ): Promise<ApiResponseSingle<AIChatSession>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/ai_chat_sessions/${aiChatSessionId}`;
    return await properCallApi<ApiResponseSingle<AIChatSession>>(request);
  };
}
