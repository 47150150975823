import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  uiStyles
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import CategoryList from './CategoryList';
import NewCategory from './NewCategory';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import OrderChange from './OrderChange';

const AttendeeTypes: FC = () => {
  const { eventId } = useRegisterRoute();
  const { mutate, isLoading } = useIsSetMutation();
  const { toNextStep } = useBuildEventSteps();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'attendeeCategory', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      // mutate,
      // eventId,
      toNextStep
    ]
  );

  // Mark the step as set if there are fields in the personal info form.
  useEffect(
    () => {
      return () => {
        if (eventSettings &&
          eventSettings?.attendeeCategory.total > 0 &&
          !eventSettings.attendeeCategory.isSet
        ) {
          mutate({ eventId, key: 'attendeeCategory', bool: true });
        }
      };
    },
    [
      eventSettings?.attendeeCategory.total,
      eventSettings?.attendeeCategory.isSet
    ]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'attendeeTypes'}
          />
        )}
        message={'Please design your badge.'}
      >
        <UiStack
          // pt={4}
          spacing={4}
          flexGrow={1}
          p={8}
        >
          <UiHStack justifyContent={'space-between'}>
            <StepInstruction label={'Instructions'}>
              <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                <UiText>
                  Attendee categories will affect the tickets or addons the attendee can see.
                </UiText>
                <UiText>
                  Attendee categories can also affects the steps to include or skip.
                </UiText>
                <UiText color={'red.600'}>
                  You only need to create all the attendee categories here and you will be able to use them in later steps.
                </UiText>
              </UiStack>
            </StepInstruction>
          </UiHStack>
          <BaseDividerHorizontal height={4} />
          <UiHStack justifyContent={'space-between'}>
            <NewCategory />
            <OrderChange />
          </UiHStack>
          <CategoryList />
        </UiStack>
        <UiHStack
          // pt={8}
          p={8}
          justifyContent={'space-between'}
        >
          <UiStack flexGrow={1} />
          <UiButton
            size={'lg'}
            colorScheme={'primary'}
            type={'submit'}
            fontSize={'lg'}
            isLoading={isLoading}
            onClick={onNext}
          >
            Next
          </UiButton>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default AttendeeTypes;
