import { type FC } from 'react';
import {
  UiStack,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiText,
  uiStyles,
  UiButton,
} from '@/lib/ui';

export interface AttendeeDrawerProps {
  isOpen: UiDrawerProps['isOpen'];
  onClose: UiDrawerProps['onClose'];
}

const AttendeeDrawer: FC<AttendeeDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <UiDrawer
      placement={'right'}
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody p={8} py={16} bgColor={'gray.100'}>
          <UiStack
            alignItems={'flex-end'}
            justifyContent={'space-between'}
            p={8}
            bgColor={'#fff'}
            spacing={4}
            borderRadius={uiStyles.borderRadius}
            {...uiStyles.hover}
          >
            <UiStack spacing={4} justifyContent={'space-between'} flexGrow={1} enableDivider={true}>
              <UiText variant={'title'}>Attendee's information update</UiText>
              <UiText variant={'body1'}>Send an email with a link to the attendee that the attendee can click to update both of the personal information and additional information (if available).</UiText>
            </UiStack>
            <UiButton >Send</UiButton>
          </UiStack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default AttendeeDrawer;
