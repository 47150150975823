import { useCallback, type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiStack, UiText } from '@/lib/ui';
import { useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import CloneEventForm from './CloneEventForm';

const PrefillPage: FC = () => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const onStarFromScratch = useCallback(
    () => navigate(generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId }))
    , [eventId, navigate, tenantCode]);

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['manage', 'events']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody
        isStandalone={false}
        // breadcrumbNodes={breadcrumbNodes}
      >
        <UiStack flexGrow={1} spacing={4}>
          <UiText variant={'title'}>Welcome to your event registration setup</UiText>
          <UiText variant="body2">Would you like to clone your settings from a previous event, or would like to start from scratch?</UiText>
          {
            !isOpen ? (
              <UiHStack>
                <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={onOpen}>
                  Clone from previous event
                </UiButton>
                <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={onStarFromScratch}>
                  Start from scratch
                </UiButton>
              </UiHStack>
            ) : (
              <CloneEventForm onClose={onClose} />
            )}
        </UiStack>
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default PrefillPage;
