/* eslint-disable react/no-unescaped-entities */
import { useState, type FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { orderQueryKey, type Order, loadOrder } from '@/api/registration';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { isError } from '@/lib/util';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import CreditCardResult from './CreditCardResult';

const PAID = 'Paid';

const PaymentResult: FC = () => {
  const [searchParams] = useSearchParams();
  const orderHash = searchParams.get('orderHash');
  const { registrationQuery } = useRegistrationAuth();
  const { createTenantApiRequest } = useTenantApi();
  const [error, setError] = useState(false);

  const { data, isLoading, error: fetchError } = useQuery<ApiResponseSingle<Order>, Error>({
    queryKey: [orderQueryKey, { orderHash }],
    queryFn: async () => await loadOrder(createTenantApiRequest)(orderHash!),
    // refetch every 1s, until error or status = PAID or refetch count <= 15
    refetchInterval: (order, { state }) => {
      if (state.dataUpdateCount > 15) { // refetch 15 times, stop and throw error
        setError(true);
        return false;
      }
      return (isError(order?.errors) || order?.item.status === PAID) ? false : 1000;
    },
    enabled: !!orderHash
  });

  useEffect(() => {
    if (!orderHash) {
      if (registrationQuery?.data?.orderHash) {
        searchParams.set('orderHash', registrationQuery?.data?.orderHash);
      }
    }
  }, [orderHash]);

  return (
    <CreditCardResult data={data} isLoading={isLoading} error={error} fetchError={fetchError} />
  );
};

export default PaymentResult;
