import { useMemo, type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import CategoryForm from './CategoryForm';
import { useSearchParams } from 'react-router-dom';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useAttendeeCategoryQuery } from '@/registration/hook/useAttendeeCategoryQuery';

const Category: FC = () => {
  const { eventId } = useRegisterRoute();
  const [searchParams] = useSearchParams();
  const attendeeCategoryQuery = useAttendeeCategoryQuery(eventId);

  const attendeeCategoryId = useMemo(
    () => searchParams.get('attendeeCategory') ? Number(searchParams.get('attendeeCategory')) : undefined
    , [searchParams]);

  return (
    <RegisterLayout header={null}>
      <CategoryForm
        attendeeCategoryData={attendeeCategoryQuery.data}
        attendeeCategoryId={attendeeCategoryId}
      />
    </RegisterLayout>
  );
};

export default Category;
