import { useCallback, type FC } from 'react';
import {
  UiStack,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiHStack,
  UiIconCurrencyCircleDollar,
  UiIconStackSimple,
  uiStyles,
  UiButton
} from '@/lib/ui';
import { type CartItem, type CartItemSaveRequest, cartQueryKey, getProductType, type TicketData, saveCartItem } from '@/api/registration';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCartQuery } from '@/registration/hook/useCartQuery';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useIsAlreadyInCart } from '@/registration/hook/useIsAlreadyInCart';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';

export interface TicketDrawerProps extends Omit<UiDrawerProps, 'children'> {
  price: string
  ticket: TicketData
  isOpen: boolean
  onClose: () => void
}

const TicketDrawer: FC<TicketDrawerProps> = ({
  ticket,
  price,
  isOpen,
  onClose,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { registrationQuery } = useRegistrationAuth();
  const registration = registrationQuery?.data;
  const { data: cart } = useCartQuery();
  const { createTenantApiRequest } = useTenantApi();
  const isAlreadyInCart = useIsAlreadyInCart(ticket);

  const { mutate, isLoading } = useMutation<CartItem, Error, CartItemSaveRequest>(
    async (params: CartItemSaveRequest) => {
      const response = await saveCartItem(createTenantApiRequest)(params);
      return response.items?.[0];
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([cartQueryKey, { registrationId: registration?.id }]);
        onClose();
      }
    }
  );

  const addToCart = useCallback(() => {
    if (!cart) {
      return;
    }
    mutate({
      productId: ticket.id,
      productType: getProductType(ticket.type),
      quantity: 1,
      cartId: cart.id
    });
  }, [cart, mutate, ticket.id, ticket.type]);

  return (
    <UiDrawer placement={'right'} size={'xl'} isOpen={isOpen} onClose={onClose}>
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} fontWeight={'bold'} color={'primary.500'} />
        <UiDrawerBody p={0} alignItems={'stretch'}>
          <UiStack
            justifyContent={'flex-start'}
            alignItems={'stretch'}
            flexGrow={1}
            py={12}
            spacing={8}
          >
            <UiStack
              px={{ base: 0, lg: 8 }}
              flexGrow={1}
              spacing={8}
              alignItems={'stretch'}
            >
              <UiStack spacing={8} px={{ base: 8, lg: 0 }}>
                <UiText variant={'h6'}>{ticket.name}</UiText>
              </UiStack>
              <UiStack
                enableDivider={true}
                bgColor={'blackAlpha.50'}
                shadow={'none'}
                minWidth={'350px'}
                borderRadius={{ base: 0, lg: uiStyles.borderRadius }}
                spacing={6}
                py={8}
              >
                {!!ticket.description && (
                  <UiHStack px={8}>
                    <UiText>{ticket.description}</UiText>
                  </UiHStack>
                )}
                <UiHStack px={8}>
                  <UiIconCurrencyCircleDollar size={'2xl'} color={'text.primary'} />
                  <UiText flexGrow={1} pl={2}>
                    Price
                  </UiText>
                  <UiText>
                    {price}
                  </UiText>
                </UiHStack>
                <UiHStack px={8}>
                  <UiIconStackSimple size={'2xl'} color={'text.primary'} />
                  <UiText flexGrow={1} pl={2}>
                    Seats (available)
                  </UiText>
                  <UiText>
                    {ticket.currentStock}
                  </UiText>
                </UiHStack>
              </UiStack>
            </UiStack>
            <UiHStack
              justifyContent={'flex-end'}
              px={8}
            >
              {
                isAlreadyInCart ? (
                  <UiButton
                    cursor={'pointer'}
                    as={'button'}
                    colorScheme={'primary'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    size={'lg'}
                    isDisabled
                  >
                    Already in cart
                  </UiButton>
                ) : (
                  <UiButton
                    cursor={'pointer'}
                    as={'button'}
                    colorScheme={'primary'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    size={'lg'}
                    isDisabled={ticket.stock < 1}
                    isLoading={isLoading}
                    onClick={addToCart}
                  >
                    Add to cart
                  </UiButton>
                )
              }
            </UiHStack>
          </UiStack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default TicketDrawer;
