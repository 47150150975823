import {
  type RouteObject
} from 'react-router-dom';
import AccountDashboardModulesPage from '@/account/dashboard/Modules';
import AccountSubscriptionPlanPage from '@/account/subscription/Plan';
import AccountSubscriptionPlanManagementPage from '@/account/subscription/PlanManagement';
import AccountAccessAdminUserPage from '@/account/access/AdminUser';
import AccountAccessTeamPage from '@/account/access/Team';
import AccountAccessTeamInfoPage from '@/account/access/TeamInfo';
import AccountSetupEventPage from '@/account/setup/Event';
import AccountModuleBillPage from '@/account/subscription/Payment';
import AccountLoginLoginEmailPage from '@/account/login/LoginEmail';
import AdminAccess from '@/account/component/AdminAccess';

// All the route names should start with Account to avoid conflicts with other modules (e.g. registration).
export interface AccountRouteParams {
  AccountDashboardModules: {
    tenantCode: string
  }
  AccountSubscriptionPlan: {
    tenantCode: string
  }
  AccountSubscriptionPlanManagement: {
    tenantCode: string
  }
  AccountModuleBill: {
    tenantCode: string
  }
  AccountSetupEvent: {
    tenantCode: string
  }
  AccountAccessAdminUser: {
    tenantCode: string
  }
  AccountAccessTeam: {
    tenantCode: string
  }
  AccountAccessTeamInfo: {
    tenantCode: string
    teamId: string
  }
  AccountLoginLoginEmail: {
  }
}

export type AccountRouteName = keyof AccountRouteParams;

export const accountRoutes: Record<AccountRouteName, RouteObject> = {
  AccountDashboardModules: {
    path: '/account/:tenantCode/dashboard/modules',
    element: (<AdminAccess><AccountDashboardModulesPage /></AdminAccess>)
  },
  AccountSubscriptionPlan: {
    path: '/account/:tenantCode/subscription/plan',
    element: (<AdminAccess><AccountSubscriptionPlanPage /></AdminAccess>)
  },
  AccountSubscriptionPlanManagement: {
    path: '/account/:tenantCode/subscription/plan-management',
    element: (<AdminAccess><AccountSubscriptionPlanManagementPage /></AdminAccess>)
  },
  AccountModuleBill: {
    path: '/account/:tenantCode/subscription/payment',
    element: (<AdminAccess><AccountModuleBillPage /></AdminAccess>)
  },
  AccountSetupEvent: {
    path: '/account/:tenantCode/manage/event',
    element: (<AdminAccess><AccountSetupEventPage /></AdminAccess>)
  },
  AccountAccessAdminUser: {
    path: '/account/:tenantCode/access/admin-user',
    element: (<AdminAccess><AccountAccessAdminUserPage /></AdminAccess>)
  },
  AccountAccessTeam: {
    path: '/account/:tenantCode/access/team',
    element: (<AdminAccess><AccountAccessTeamPage /></AdminAccess>)
  },
  AccountAccessTeamInfo: {
    path: '/account/:tenantCode/access/team/:teamId',
    element: (<AdminAccess><AccountAccessTeamInfoPage /></AdminAccess>)
  },
  AccountLoginLoginEmail: {
    path: '/account/login-email',
    element: (<AccountLoginLoginEmailPage />)
  }
};
