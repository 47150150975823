import { type InfoFormResponseSaveRequest, type InfoFormResponse, saveInfoFormResponse, infoFormResponseQueryKey, type RegistrationWithToken, type InfoForm } from '@/api/registration';
import { Formik, Form, type FormikHelpers } from 'formik';
import { useMemo, useCallback } from 'react';
import { type FC } from 'react';
import { createUtils, type FieldsValues } from '@/base/FormGenerator/utils';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import {
  UiButton,
  UiStack,
  UiHStack
} from '@/lib/ui';
import FormGenerator from '@/base/FormGenerator';

type FormData = FieldsValues;
type FieldName = keyof FieldsValues;

type Errors = Record<FieldName, string>;

interface GenericInfoFormProps {
  infoForm: InfoForm
  infoFormResponse: InfoFormResponse
  registration: RegistrationWithToken
  onSuccess: () => void
}

const GenericInfoForm: FC<GenericInfoFormProps> = ({ infoForm, infoFormResponse, registration, onSuccess }) => {
  const { createTenantApiRequest } = useTenantApi();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation<{}, Error, InfoFormResponseSaveRequest>(
    {
      mutationFn: async (data: InfoFormResponseSaveRequest) => await saveInfoFormResponse(createTenantApiRequest)(data),
      onSuccess: () => {
        void queryClient.invalidateQueries([infoFormResponseQueryKey, { registrationId: registration?.id, type: infoForm.formType }]);
        onSuccess();
      }
    }
  );

  const utils = useMemo(
    () => createUtils((infoForm.config?.fieldsMetadata ?? [])),
    [infoForm.config?.fieldsMetadata]);

  const submitForm = useCallback(async (values: FormData) => {
    if (!registration?.id) {
      throw new Error("Can't submit Info Form, Registration ID is not found");
    }
    mutate({
      eventId: registration.eventId,
      formType: infoForm.formType,
      response: values,
      ownerId: registration?.id,
      infoFormId: infoForm.id,
      ownerType: 'Registration'
    });
  }, [registration.eventId, mutate, registration?.id]);

  return (
    <Formik
      initialValues={utils.getFieldsValues(infoFormResponse.response)}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FormData) => {
        const errors: Errors = {};
        // This is an example to check 'required' fields. We might validator others in the future.
        for (const fieldId in values) {
          const fieldMetadata = utils.getFieldMetadata(fieldId);
          if (fieldMetadata) {
            // If a field contains exmpty string, we will treat it as empty. Currently we don't consider number or boolean.
            if ('isRequired' in fieldMetadata && fieldMetadata.isRequired && values[fieldId] === '') {
              errors[fieldId] = 'The field is required';
            }
          }
        }
        return errors;
      }}
      onSubmit={async (
        values,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <Form>
        <BaseTransitionSlideLeft>
          <UiStack flexGrow={1}>
            <FormGenerator
              fieldsLayout={infoForm.config.fieldsLayout}
              fieldsMetadata={infoForm.config.fieldsMetadata}
            />
          </UiStack>
        </BaseTransitionSlideLeft>
        <UiStack height={20} />
        <UiHStack justifyContent={'flex-end'} flexGrow={1}>
          <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading} >
          Save
          </UiButton>
        </UiHStack>
      </Form>
    </Formik>
  );
};

export default GenericInfoForm;
