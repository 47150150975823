import { type FC, useState, useCallback, useMemo } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { eventTypeOptions } from '@/api/constant';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { createEvent, type EventSaveRequest } from '@/api/account';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import ImageSelectField from '@/base/Form/ImageSelectField';
import { type Event } from '@/api/registration';
import dayjs from 'dayjs';
import { type FileOrUrl } from '@/base/ImageSelect/ImageSelectItem';
import { toBase64 } from '@/lib/util';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export interface EventFormProps extends UiHStackProps {
  event?: Event
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

const formSchema = Yup.object().shape({
  eventType: Yup.string().required('Event type is required'),
  name: Yup.string()
    .required('Name is required.')
    .min(3, 'Name is too short (minimum is 3 characters)'),
  location: Yup.string()
    .required('Venue is required.')
    .min(3, 'Venue is too short (minimum is 3 characters)')
    .max(100, 'Venue can not have more than 100 charactors.'),
  // shortName: Yup.string()
  //   .required('Name is required.')
  //   .min(3, 'Name is too short (minimum is 3 characters)'),
  // tagline: Yup.string()
  //   .required('This field is required.')
  //   .min(3, 'Name is too short (minimum is 3 characters)'),
  websiteUrl: Yup.string()
    .url('Invalid URL')
    .required('Website URL is required.'),
  mapUrl: Yup.string()
    .url('Invalid URL'),
  // primaryColour: Yup.string()
  //   .required('Primary Color is required.'),
  // secondaryColour: Yup.string()
  //   .required('Secondary Color is required.'),
  startDate: Yup.date().required('From Date is required'),
  endDate: Yup.date().required('To Date is required')
});

interface FormData {
  eventType: string
  name: string
  location: string
  shortName: string
  tagline: string
  websiteUrl: string
  mapUrl: string
  // primaryColour: string
  // secondaryColour: string
  tmpCoverImage: FileOrUrl[]
  tmpMenuLogo: FileOrUrl[]
  startDate: string
  endDate: string
  startTime: string
  endTime: string
}

const typeOptions: Option[] = eventTypeOptions.map((option) => ({
  value: option.value,
  label: option.label
}));

const EventForm: FC<EventFormProps> = ({
  event,
  onClose,
  isVisible
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation<ApiResponse<Event>, Error, EventSaveRequest>({
    mutationFn: async (data: EventSaveRequest) => {
      return await createEvent(createTenantAdminApiRequest)(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.eventListQueryKey] });
      onClose();
    }
  });

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    const eventData = {
      id: event?.id,
      eventType: values.eventType,
      name: values.name,
      location: values.location,
      // shortName: values.shortName,
      // tagline: values.tagline,
      websiteUrl: values.websiteUrl,
      mapUrl: values.mapUrl,
      // primaryColour: values.primaryColour,
      // secondaryColour: values.secondaryColour,
      tmpCoverImage: values.tmpCoverImage[0] instanceof File ? await toBase64(values.tmpCoverImage[0]) : values.tmpCoverImage[0],
      tmpMenuLogo: values.tmpMenuLogo[0] instanceof File ? await toBase64(values.tmpMenuLogo[0]) : values.tmpMenuLogo[0],
      startDate: dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      endDate: dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      startTime: dayjs(`${values.startDate} ${values.startTime}`, 'YYYY-MM-DD HH:mm').tz(event?.timeZone).utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      endTime: dayjs(`${values.endDate} ${values.endTime}`, 'YYYY-MM-DD HH:mm').tz(event?.timeZone).utc().format('YYYY-MM-DDTHH:mm:ssZ')
    };
    await mutateAsync({ event: eventData });
    setSubmitting(false);
  }, [event?.id, event?.timeZone, mutateAsync]);

  const initialValues: FormData = useMemo(() => ({
    eventType: event?.eventType ?? '',
    name: event?.name ?? '',
    location: event?.location ?? '',
    shortName: event?.shortName ?? '',
    tagline: event?.tagline ?? '',
    websiteUrl: event?.websiteUrl ?? '',
    mapUrl: event?.mapUrl ?? '',
    // primaryColour: event?.primaryColour ?? '#',
    // secondaryColour: event?.secondaryColour ?? '#',
    tmpCoverImage: event?.coverImage ? [event.coverImage] : [],
    tmpMenuLogo: event?.menuLogo ? [event.menuLogo] : [],
    startDate: event?.startDate ? dayjs(event?.startDate).format('YYYY-MM-DD') : '',
    endDate: event?.endDate ? dayjs(event?.endDate).format('YYYY-MM-DD') : '',
    startTime: event?.startTime ? dayjs(event?.startTime).format('HH:mm') : '',
    endTime: event?.endTime ? dayjs(event?.endTime).format('HH:mm') : ''
  }), [event]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Event'}
        size={'xl'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => (
              <BaseMessageBarError key={index}>
                {error}
              </BaseMessageBarError>
            ))}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormSelectField
            name={'eventType'}
            label={'Event type'}
            options={typeOptions}
          />
          <BaseFormInputField
            name={'name'}
            label={'Event name'}
          />
          <BaseFormInputField
            name={'location'}
            label={'Location'}
          />
          <BaseFormInputField
            name={'websiteUrl'}
            label={'Event Website'}
          />
          <BaseFormInputField
            name={'mapUrl'}
            label={'Google Maps URL'}
            isRequired={false}
          />
          {/* <BaseFormInputField
            name={'primaryColour'}
            label={'Primary Color'}
          />
          <BaseFormInputField
            name={'secondaryColour'}
            label={'Secondary Color'}
          /> */}
          <ImageSelectField
            name="tmpCoverImage"
            label="Cover Image"
            isRequired={false}
          />
          <ImageSelectField
            name="tmpMenuLogo"
            label="Menu Logo"
            isRequired={false}
          />
          <BaseFormInputField
            name={'startDate'}
            label={'From date'}
            type={'date'}
            helperText="The provided date should be in the event's time zone."
          />
          <BaseFormInputField
            name={'endDate'}
            label={'To date'}
            type={'date'}
            helperText="The provided date should be in the event's time zone."
          />
          <BaseFormInputField
            name={'startTime'}
            label={'Start time'}
            type={'time'}
            helperText="The provided time should be in the event's time zone."
          />
          <BaseFormInputField
            name={'endTime'}
            label={'End time'}
            type={'time'}
            helperText="The provided time should be in the event's time zone."
          />
          <BaseFormSelectField
            name={'currency'}
            label={'Currency'}
            helperText={'We only support AUD at the moment. Support for more currencies is on the way.'}
            options={[{ value: 'AUD', label: 'AUD' }]}
            defaultValue={{ value: 'AUD', label: 'AUD' }}
            isDisabled={true}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default EventForm;
