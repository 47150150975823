import { useMemo, type FC } from 'react';
import {
  UiText,
  UiStack,
  UiIconClipboardText
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';

export interface PaymentMethodsProps {
  isActive?: boolean
}

const PaymentMethods: FC<PaymentMethodsProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { data: paymentOptions } = useQuery(
    [registration.paymentOptionQueryKey],
    async () => {
      const result = await registration.loadPaymentOptions(createTenantAdminApiRequest)({});
      return result.items;
    },
    { enabled: !isApiPreparing }
  );
  const onlinePayment = useMemo(() => [
    ...paymentOptions?.find((paymentOption) => paymentOption.name === 'stripe') ? ['Stripe'] : [],
    ...paymentOptions?.find((paymentOption) => paymentOption.name === 'eWay') ? ['eWay'] : []
  ], [paymentOptions]);
  const offlinePayment = useMemo(
    () => !!paymentOptions?.find((paymentOption) => paymentOption.name === 'invoice' && paymentOption.enabled)
    , [paymentOptions]);

  return (
    <Step
      Icon={UiIconClipboardText}
      description={'Payment methods'}
      isActive={isActive}
      navLink={generatePageUrl('RegistrationBuildEventPaymentMethods', { tenantCode, eventId })}
      isSet={eventSettings?.paymentMethod?.isSet}
      // stepNumber={3}
    >
      <UiStack spacing={0}>
        {
          onlinePayment.length && (
            <UiStack spacing={0}>
              <UiText variant={'body2'} color={'text.secondary'}>Online ({onlinePayment?.join(', ')})</UiText>
            </UiStack>
          )
        }
        {
          offlinePayment && (
            <UiStack spacing={0}>
              <UiText variant={'body2'} color={'text.secondary'}>Offline</UiText>
            </UiStack>
          )
        }
      </UiStack>
    </Step>
  );
};

export default PaymentMethods;
