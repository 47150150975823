import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { account } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type SubscriptionPackage, SubscriptionPaymentPlan, SubscriptionProduct } from '@/api/constant/subscription';
import { isAddonAvailable } from '@/account/subscription/utils';

export interface SubscriptionEditor {
  subscription: account.Subscription
  isLoading: boolean
  selectPackage: (packageName: SubscriptionPackage) => void
  toggleAddon: (addonProductName: SubscriptionProduct) => void
  updateServicePackHours: (hours: number) => void
  toggleServicePack: () => void
}

export function useSubscriptionPlanEditor(): SubscriptionEditor {
  const [subscription, setSubscription] = useState<account.Subscription>(account.defaultSubscription);
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { data, isLoading } = useQuery<account.Subscription, Error>(
    [account.subscriptionQueryKey, {}],
    async () => {
      return await account.loadSubscription(createTenantAdminApiRequest)({});
    },
    {
      enabled: !isApiPreparing
    }
  );

  useEffect(
    () => {
      if (data) {
        const newSubscription = { ...data };
        setSubscription(newSubscription);
      }
    },
    [data]
  );

  function selectPackage(packageName: SubscriptionPackage) {
    if (subscription.plan.package !== packageName) {
      const newSubscription = { ...subscription };
      newSubscription.plan.package = packageName;
      newSubscription.plan.addons = []; // This is important since Add-ons are different for different packages.
      setSubscription(newSubscription);
    }
  }

  function toggleAddon(addonProductName: SubscriptionProduct, paymentPlan: SubscriptionPaymentPlan = SubscriptionPaymentPlan.Quarterly) {
    // Make sure the addon is NOT included in the package.
    if (isAddonAvailable(subscription, addonProductName)) {
      const newSubscription = { ...subscription };
      // const existingPos = newSubscription.plan.addon.products.indexOf(addonProductName);
      const existingPos = newSubscription.plan.addons.findIndex((addon) => addon.product === addonProductName);
      if (existingPos >= 0) {
        // Remove the addon if exists.
        newSubscription.plan.addons.splice(existingPos, 1);
      } else {
        // Add the addon if not exists.
        newSubscription.plan.addons.push({
          product: addonProductName,
          paymentPlan,
        });
      }
      setSubscription(newSubscription);
    }
  }

  function updateServicePackHours(hours: number) {
    if (subscription.plan.servicePack.hours !== hours) {
      const newSubscription = { ...subscription };
      newSubscription.plan.servicePack.hours = hours;
      setSubscription(newSubscription);
    }
  }

  function toggleServicePack() {
    const newSubscription = { ...subscription };
    if (newSubscription.plan.servicePack.hours === 0) {
      newSubscription.plan.servicePack.hours = 40;
    } else {
      newSubscription.plan.servicePack.hours = 0;
    }
    setSubscription(newSubscription);
  }

  return {
    subscription,
    isLoading,
    selectPackage,
    toggleAddon,
    updateServicePackHours,
    toggleServicePack,
  };
}
