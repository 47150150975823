import { type FC, useMemo, useCallback } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef
} from 'mantine-react-table';
import dayjs, { type Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { type SerializedOrderTableRow } from './OrderTable';
import { createFormatPrice } from '@/lib/util/locale';
import { UiBadge, UiButton, UiFlex } from '@/lib/ui';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type DatatableSelectOptions } from '@/api/tenantClient';

dayjs.extend(advancedFormat);

interface DatatableProps {
  data: SerializedOrderTableRow[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: any
  setFilters: (filters: any) => void
  sorting: any
  setSorting: (sorting: any) => void
  pagination: any
  setPagination: (pagination: any) => void
  rowCount: number
  eventSelectOptions: DatatableSelectOptions
  ticketSelectOptions: DatatableSelectOptions
}

const Datatable: FC<DatatableProps> = ({ data, isLoading, isError, isFetching, refetch, filters, setFilters, sorting, setSorting, pagination, setPagination, rowCount, eventSelectOptions, ticketSelectOptions }) => {
  const navigate = useNavigate();
  const priceLocale = 'en-AU';
  const priceCurrency = 'AUD';
  const { tenantCode } = useRegisterRoute();
  const formatPrice = useMemo(
    () => createFormatPrice({ locale: priceLocale, currency: priceCurrency }),
    []
  );

  const toOrderInfoPage = useCallback(
    (orderId: number) => {
      navigate(generatePageUrl('RegistrationManageOrderInfo', { tenantCode, orderId: `${orderId}` }));
    },
    [tenantCode]
  );

  const columns = useMemo<Array<MRT_ColumnDef<SerializedOrderTableRow>>>(
    () => [
      {
        accessorKey: 'event.id',
        header: 'Event',
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: eventSelectOptions
        },
        Cell: ({ row }) => {
          return row.original.event.name;
        }
      },
      {
        accessorKey: 'tickets',
        header: 'Tickets',
        filterVariant: 'multi-select',
        mantineFilterMultiSelectProps: {
          data: ticketSelectOptions
        },
        enableSorting: false,
        Cell: ({ cell }) => {
          const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
          return (
            <>
              {tickets.map((ticket, index) => (
                <UiBadge key={index} colorScheme="blue" mr={1}>
                  {`${ticket.name}`}
                </UiBadge>
              ))}
            </>
          );
        }
      },
      {
        accessorKey: 'attendee.name',
        header: 'Name'
      },
      {
        accessorKey: 'attendee.email',
        header: 'Email'
      },
      {
        accessorKey: 'totalPrice',
        header: 'Total Price',
        Cell: ({ cell }) => {
          return formatPrice((cell.getValue() as number));
        },
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        mantineFilterRangeSliderProps: {
          max: 500_000,
          min: 0,
          step: 5_000,
          label: (value) => {
            return formatPrice(value);
          }
        }
      },
      {
        accessorKey: 'purchasedAt',
        header: 'Purchased At',
        Cell: ({ cell }) => cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('hh:mm A Do MMMM Z') : '',
        filterVariant: 'date-range'
      }
    ],
    []
  );

  const handleApplyFilter = () => {
    refetch();
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, showGlobalFilter: false },
    enableDensityToggle: false,
    enableClickToCopy: true,
    enableFullScreenToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    rowCount,
    state: {
      columnFilters: filters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      pagination
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 300 } },
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderTopToolbarCustomActions: () => (
      <UiFlex
        width={'100%'}
        direction={'row'}
        justifyContent={'flex-end'}
      >
        <UiButton
          px={6}
          size={'sm'}
          shadow={'base'}
          colorScheme={'primary'}
          onClick={handleApplyFilter}>
            Apply Filter
        </UiButton>
      </UiFlex>
    ),
    renderRowActions: ({ row }) => (
      <UiButton
        px={6}
        size={'sm'}
        shadow={'base'}
        colorScheme={'primary'}
        onClick={() => toOrderInfoPage(row.original.id)}>
          Details
      </UiButton>
    )
  });

  return <MantineReactTable table={table} />;
};

export default Datatable;
