import { type FC, useEffect, useState } from 'react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { UiButton, UiDrawer, UiDrawerBody, UiDrawerCloseButton, UiDrawerContent, UiDrawerFooter, UiDrawerOverlay, type UiDrawerProps, UiStack, UiText } from '@/lib/ui';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import { type RegistrationWithToken, requestPasscode, type RequestPasscodeRequest, verifyPasscode, type VerifyPasscodeRequest } from '@/api/registration';
import { useMutation } from '@tanstack/react-query';
import { type ApiResponseSingle } from '@/api/tenantClient';
import Passcode from '@/base/Passcode/Passcode';

interface PasscodeDrawerProps extends Omit<UiDrawerProps, 'children'> {
  isOpen: boolean
  email: string | undefined
  onClose: () => void
}

const PasscodeDrawer: FC<PasscodeDrawerProps> = ({ isOpen, onClose, email }) => {
  const { eventId } = useRegisterRoute();
  const [errors, setErrors] = useState<string[]>([]);
  const { createTenantApiRequest } = useTenantApi();
  const { setUserSession, registrationQuery } = useRegistrationAuth();
  const { toNextStep } = useRegisterSteps();

  const { mutate: handleRequestPasscode } = useMutation<ApiResponseSingle<void>, Error, RequestPasscodeRequest>({
    mutationFn: async (data: RequestPasscodeRequest) => await requestPasscode(createTenantApiRequest)(data)
  });

  const { mutate: handleVerifyPasscode, isLoading } = useMutation<ApiResponseSingle<RegistrationWithToken>, Error, VerifyPasscodeRequest>({
    mutationFn: async (data: VerifyPasscodeRequest) => await verifyPasscode(createTenantApiRequest)(data),
    onSuccess: (data: ApiResponseSingle<RegistrationWithToken>) => {
      if (data.errors && data.errors.length > 0) {
        return setErrors(data.errors);
      }
      if (data.item?.id) {
        setUserSession({ email: data.item.email, client: data.item.auth.client, authToken: data.item.auth.token });
      }
    }
  });

  useEffect(() => {
    //  wait to load registration completed, then go to next step
    // TODO: need to improve
    if (email && registrationQuery.data) {
      toNextStep();
    }
  }, [email, registrationQuery.data, toNextStep]);

  return (
    <UiDrawer placement={'right'} onClose={onClose} isOpen={isOpen} size={'xl'}>
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerBody p={8}>
          <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
          <UiText variant={'h6'}>Login</UiText>
          {
            email && (
              <Passcode
                errors={errors}
                setErrors={setErrors}
                email={email}
                eventId={eventId}
                isLoading={isLoading || registrationQuery.isFetching}
                onRequestPasscode={handleRequestPasscode}
                onVerifyPasscode={handleVerifyPasscode}
              />
            )
          }
        </UiDrawerBody>
        <UiDrawerFooter p={0}>
          <UiStack flexDirection={'row-reverse'} p={8}>
            <UiButton
              px={8}
              size={'lg'}
              shadow={'base'}
              colorScheme={'primary'}
              variant={'ghost'}
              onClick={onClose}
            >
              Back
            </UiButton>
          </UiStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default PasscodeDrawer;
