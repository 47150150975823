import { type FC, useCallback } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import TicketList from './TicketList';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { TicketType } from '@/api/constant';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';

const MainEventTickets: FC = () => {
  const { eventId } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();

  const onNext = useCallback(
    () => {
      mutate({ eventId, key: 'mainEventTicket', bool: true }, {
        onSuccess: () => {
          toNextStep();
        }
      });
    },
    [mutate, eventId, toNextStep]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'mainEventTickets'}
          />
        )}
        message={'List your event tickets.'}
      >
        <UiStack
          spacing={4}
          flexGrow={1}
          p={8}
        >
          <TicketList ticketType={TicketType.Main} />
        </UiStack>
        <UiHStack justifyContent={'space-between'} p={8}>
          <UiStack flexGrow={1} />
          <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} onClick={onNext} isLoading={isLoading}>
            Next
          </UiButton>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default MainEventTickets;
