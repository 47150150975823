import { type PropsWithChildren, type FC, useCallback, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import RegisterLayout from '@/registration/component/Register/Layout';
import { Center, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { UiButton, UiStack, UiText } from '@/lib/ui';
import { ProviderRegistrationAuth, useRegistrationAuth } from '@/app/ProviderRegistrationAuth';

const RouteGuard: FC<PropsWithChildren> = ({ children }) => {
  const { eventId, tenantCode } = useRegisterRoute();
  const location = useLocation();
  const navigate = useNavigate();
  const { registrationQuery } = useRegistrationAuth();

  const defaultUrl = useMemo(
    () => generatePageUrl('RegistrationRegisterEmail', { tenantCode, eventId })
    , [eventId, tenantCode]);

  const paymentResultUrl = useMemo(
    () => generatePageUrl('RegistrationRegisterPaymentResult', { tenantCode, eventId })
    , [eventId, tenantCode]);

  const onBackEmail = useCallback(() => navigate(defaultUrl), [defaultUrl, navigate]);

  if (registrationQuery?.error && ![defaultUrl, paymentResultUrl].includes(location.pathname)) {
    return (
      <RegisterLayout header={null}>
        <Center><UiText fontWeight={600} variant="h4">You are no longer signed in.</UiText></Center>
        <UiButton colorScheme={'primary'} variant={'ghost'} p={0} mt={8} onClick={onBackEmail}>
          Back to register page
        </UiButton>
      </RegisterLayout>
    );
  }

  if (registrationQuery?.data && registrationQuery?.isLoading) { // loading registration
    return (
      <RegisterLayout header={null}>
        <SkeletonCircle size='16' />
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='8' />
      </RegisterLayout>
    );
  }

  if (registrationQuery?.isLoading && (location.pathname !== defaultUrl)) { // loading registration
    return (
      <RegisterLayout header={null}>
        <SkeletonCircle size='16' />
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='8' />
      </RegisterLayout>
    );
  }
  if (!registrationQuery?.data && (location.pathname !== defaultUrl)) { // registration is null
    return (
      <RegisterLayout header={null}>
        <UiStack alignItems="center">
          <UiText fontWeight={600} variant="h4">This page does not exist</UiText>
          <UiButton colorScheme={'primary'} variant={'ghost'} p={0} mt={8} onClick={onBackEmail}>
            Back to register page
          </UiButton>
        </UiStack>
      </RegisterLayout>
    );
  }
  return children;
};

const RegisterContainer: FC = () => (
  <ProviderRegistrationAuth>
    <RouteGuard>
      <Outlet />
    </RouteGuard>
  </ProviderRegistrationAuth>
);

export default RegisterContainer;
