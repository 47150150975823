import {
  type ApiRequest,
  type ApiResponse,
  callApi
} from '@/api/tenantClient';
import { TicketType } from '../constant';

export interface OrderChangeRequest {
  eventId: string
  orderIds: Array<number | string>
}

export interface AttendeeOrderChangeRequest extends OrderChangeRequest {
}

export function attendeeOrderChange(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: AttendeeOrderChangeRequest): Promise<ApiResponse<undefined>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/attendee_categories/order_position';
    request.payload = {
      eventId: params.eventId,
      orderIds: params.orderIds
    };
    return await callApi<undefined>(request);
  };
}

export interface TicketOrderChangeRequest extends OrderChangeRequest {
  ticketType: TicketType
}

export function ticketOrderChange(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: TicketOrderChangeRequest): Promise<ApiResponse<undefined>> => {
    if (params.ticketType === TicketType.Main) {
      request.method = 'PUT';
      request.endpoint.path = '/api/v1/delegate_types/order_position';
      request.payload = {
        eventId: params.eventId,
        orderIds: params.orderIds
      };
    } else {
      request.method = 'PUT';
      request.endpoint.path = '/api/v1/sessions_pricings/order_position';
      request.payload = {
        eventId: params.eventId,
        orderIds: params.orderIds,
        ticketType: params.ticketType
      };
    }

    return await callApi<undefined>(request);
  };
}
