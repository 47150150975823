import { type FC, type ReactNode } from 'react';
import {
  UiHStack,
  UiStack,
  uiStyles
} from '@/lib/ui';
// import { RouteParams } from '@/app/pages';
// import Filter from './Filter';
import BaseBreadcrumbBar, { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';

export interface BodyProps {
  children: ReactNode
  basePadding?: number
  breadcrumbNodes?: BaseBreadcrumbBarNode[]
  isStandalone?: boolean
}

const Body: FC<BodyProps> = ({
  children,
  basePadding = 8,
  breadcrumbNodes = undefined,
  isStandalone = true
}) => {
  return (
    <UiStack
      spacing={0.5}
      alignItems={'stretch'}
      flexGrow={1}
    >
      {!!breadcrumbNodes && (
        <UiStack
          borderTopRadius={uiStyles.borderRadius}
          spacing={0}
          {...uiStyles.glass}
        >
          <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius}>
            <BaseBreadcrumbBar title={'Sell'} nodes={breadcrumbNodes} />
          </UiStack>
        </UiStack>
      )}
      <BaseTransitionSlideLeft>
        <UiStack
          spacing={0}
          alignItems={'stretch'}
          flexGrow={1}
          p={basePadding}
          {...uiStyles.glass}
        >
          {children}
        </UiStack>
      </BaseTransitionSlideLeft>
    </UiStack>
  );
};

export default Body;
