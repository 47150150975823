import {
  // extendBaseTheme,
  extendTheme
} from '@chakra-ui/react';
// import { mode } from '@chakra-ui/theme-tools';
// import chakraTheme from '@chakra-ui/theme';

// const {
//   Button,
//   Modal,
//   Divider,
//   Tag,
//   Checkbox,
//   Stepper,
//   Drawer,
//   Input,
//   NumberInput,
//   Form,
//   FormError,
//   FormLabel,
//   Textarea,
//   Table,
// } = chakraTheme.components;

const windowWidth = window.innerWidth;

const baseTheme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  },
  // components: {
  //   Button,
  //   Modal,
  //   Divider,
  //   Tag,
  //   Checkbox,
  //   Stepper,
  //   Drawer,
  //   Input,
  //   NumberInput,
  //   Form,
  //   FormError,
  //   FormLabel,
  //   Textarea,
  //   Table,
  // },
  components: {
    Drawer: {
      sizes: {
        '2xl': {
          dialog: {
            maxW: 1200,
            minW: windowWidth <= 1280 ? windowWidth : 1200
          }
        }
      }
    }
  },
  colors: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    // primary: {
    //   50: '#F3E5F5',
    //   100: '#E1BEE7',
    //   200: '#CE93D8',
    //   300: '#BA68C8',
    //   400: '#AB47BC',
    //   500: '#9C27B0',
    //   600: '#8E24AA',
    //   700: '#7B1FA2',
    //   800: '#6A1B9A',
    //   900: '#4A148C'
    // },
    brand: {
      50: '#FBE9E7',
      100: '#FFCCBC',
      200: '#FFAB91',
      300: '#FF8A65',
      400: '#FF5621',
      500: '#FF5621',
      600: '#FF5621',
      700: '#FF5621',
      800: '#F4511E',
      900: '#FF5621'
    },
    primary: {
      50: '#E8EAF6',
      100: '#C5CAE9',
      200: '#9FA8DA',
      300: '#7986CB',
      // 400: '#5C6BC0',
      400: '#3F51B5',
      500: '#3F51B5',
      600: '#3949AB',
      700: '#303F9F',
      800: '#283593',
      900: '#1A237E'
    },
    r1: {
      50: '#FFDDD3',
      100: '#FFDDD3',
      200: '#FFBBA6',
      300: '#FF9A7A',
      400: '#FF784D',
      500: '#FF5621',
      600: '#CC451A',
      700: '#993414',
      800: '#66220D',
      900: '#331107'
    },
    indigo: {
      50: '#E8EAF6',
      100: '#C5CAE9',
      200: '#9FA8DA',
      300: '#7986CB',
      400: '#5C6BC0',
      500: '#3F51B5',
      600: '#3949AB',
      700: '#303F9F',
      800: '#283593',
      900: '#1A237E'
    },
    blue: {
      50: '#E3F2FD',
      100: '#BBDEFB',
      200: '#90CAF9',
      300: '#64B5F6',
      400: '#42A5F5',
      500: '#2196F3',
      600: '#1E88E5',
      700: '#1976D2',
      800: '#1565C0',
      900: '#0D47A1'
    },
    teal: {
      50: '#E0F2F1',
      100: '#B2DFDB',
      200: '#80CBC4',
      300: '#4DB6AC',
      400: '#26A69A',
      500: '#009688',
      600: '#00897B',
      700: '#00796B',
      800: '#00695C',
      900: '#004D40'
    },
    purple: {
      50: '#F3E5F5',
      100: '#E1BEE7',
      200: '#CE93D8',
      300: '#BA68C8',
      400: '#AB47BC',
      500: '#9C27B0',
      600: '#8E24AA',
      700: '#7B1FA2',
      800: '#6A1B9A',
      900: '#4A148C'
    },
    amber: {
      50: '#FFF8E1',
      100: '#FFECB3',
      200: '#FFE082',
      300: '#FFD54F',
      400: '#FFCA28',
      500: '#FFC107',
      600: '#FFB300',
      700: '#FFA000',
      800: '#FF8F00',
      900: '#FF6F00'
    },
    deepOrange: {
      50: '#FBE9E7',
      100: '#FFCCBC',
      200: '#FFAB91',
      300: '#FF8A65',
      400: '#FF7043',
      500: '#FF5722',
      600: '#F4511E',
      700: '#E64A19',
      800: '#D84315',
      900: '#BF360C'
    },
    orange: {
      50: '#FFF3E0',
      100: '#FFE0B2',
      200: '#FFCC80',
      300: '#FFB74D',
      400: '#FFA726',
      500: '#FF9800',
      600: '#FB8C00',
      700: '#F57C00',
      800: '#EF6C00',
      900: '#E65100'
    },
    gray: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121'
    },
    text: {
      primary: 'RGBA(0, 0, 0, 0.95)',
      secondary: 'RGBA(0, 0, 0, 0.64)'
    }
  },
  fonts: {
    // body: "Poppins, system-ui, sans-serif",
    // heading: "Poppins, Georgia, serif",
    // mono: "Poppins, Menlo, monospace",
    // body: 'Lato, system-ui, sans-serif',
    // heading: 'Lato, system-ui, sans-serif',
    // mono: 'Lato, system-ui, sans-serif',

    body: 'ao-app-font, system-ui, sans-serif',
    heading: 'ao-app-font, system-ui, sans-serif',
    mono: 'ao-app-font, system-ui, sans-serif',

    // body: '"Poppins", system-ui, sans-serif',
    // heading: '"Poppins", system-ui, sans-serif',
    // mono: '"Poppins", system-ui, sans-serif',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem'
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    3: '.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem'
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em'
  }
});

export const theme = baseTheme;
