import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

export const ordersQueryKey = 'registration.orderlist';
export const orderQueryKey = 'registration.order';
export const orderTableQueryKey = 'registration.orderTable';

export interface OrderItem {
  id: number
  name: string
  price: number
  quantity: number
}

export interface Order {
  id: number
  secureHash: string
  status: string
  serial: string
  event: string
  eventId: string
  tickets: Array<{ name: string, quantity: number }>
  attendee: { name: string, email: string }
  totalPrice: string
  purchasedAt: string
  orderItems: OrderItem[]
}

export interface OrderTableRow {
  id: number
  attendee: { name: string, email: string }
  event: { id: number, name: string }
  purchasedAt: string
  tickets: Array<{
    id: number
    name: string
    quantity: number
    type: 'DelegateType' | 'SessionsPricing'
  }>
  totalPrice: string
}

export function loadOrder(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    secureHash: string
  ): Promise<ApiResponseSingle<Order>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/orders/${secureHash}`;
    return await properCallApi<ApiResponseSingle<Order>>(request);
  };
}

export interface OrderQueryRequest {
  page: number
  filters?: any
  sorting?: any
  size: number
}

export function loadOrdersForTable(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: OrderQueryRequest
  ): Promise<ApiResponse<OrderTableRow>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/orders';
    request.endpoint.query = {
      page: params?.page + 1 ?? 1,
      filters: JSON.stringify(params.filters ?? []),
      sorting: JSON.stringify(params.sorting ?? []),
      size: params.size ?? 10
    };
    return await properCallApi<ApiResponse<OrderTableRow>>(request);
  };
}
