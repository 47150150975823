import { type FC, useMemo, useCallback } from 'react';
import { Formik, Form, type FormikHelpers } from 'formik';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import {
  UiButton,
  UiHStack,
  UiStack
} from '@/lib/ui';
import FormGenerator from '@/base/FormGenerator';
import { createUtils, type FieldsValues } from '@/base/FormGenerator/utils';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { saveInfoFormResponse, type InfoFormResponseSaveRequest } from '@/api/registration/info_form_response';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type InfoFormByAttendeeCategoryQuery, type InfoForm, InfoFormType, type InfoFormResponse, loadRegistrationInfoFormResponse, infoFormResponseQueryKey, infoFormByAttendeeCategoryQueryKey, loadInfoFormByAttendeeCategory } from '@/api/registration';
import { Skeleton } from '@chakra-ui/react';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';

type FormData = FieldsValues;
type FieldName = keyof FieldsValues;

type Errors = Record<FieldName, string>;

export interface AdditionalInfoFormProps {}

const AdditionalInfoForm: FC<AdditionalInfoFormProps> = () => {
  const { eventId } = useRegisterRoute();
  const queryClient = useQueryClient();
  const { registration } = useRegistrationAuth();
  const { toNextStep, toPreviousStep, isReviewing } = useRegisterSteps();
  const { createTenantApiRequest } = useTenantApi();

  const additionalInfoQuery = useQuery<InfoFormResponse, Error>({
    queryKey: [infoFormResponseQueryKey, { registrationId: registration?.id, type: InfoFormType.ADDITIONAL }],
    queryFn: async () => {
      const response = await loadRegistrationInfoFormResponse(createTenantApiRequest)({
        registrationId: registration!.id,
        type: InfoFormType.ADDITIONAL
      });
      return response?.item ?? {};
    },
    enabled: !!registration?.id
  });

  const infoFormByAttendeeCategoryQuery = useQuery<InfoForm, Error>(
    [infoFormByAttendeeCategoryQueryKey, { registrationId: registration?.id, formType: InfoFormType.ADDITIONAL }],
    async () => await fetchInfoFormByAttendeeCategory(registration?.id),
    {
      enabled: !!registration?.id
    }
  );

  async function fetchInfoFormByAttendeeCategory(registrationId: number | undefined): Promise<InfoForm> {
    if (typeof registrationId === 'undefined') {
      return await Promise.reject(new Error('Invalid registrationId'));
    }

    const params: InfoFormByAttendeeCategoryQuery = { formType: InfoFormType.ADDITIONAL, registrationId };
    const result = await loadInfoFormByAttendeeCategory(createTenantApiRequest)(params);

    return {
      ...result.item,
      config: JSON.parse(result.item.config) as InfoForm['config']
    };
  }

  const utils = useMemo(
    () => createUtils((infoFormByAttendeeCategoryQuery.data?.config?.fieldsMetadata ?? [])),
    [infoFormByAttendeeCategoryQuery.data?.config?.fieldsMetadata]);

  const { mutate, isLoading } = useMutation<{}, Error, InfoFormResponseSaveRequest>(
    {
      mutationFn: async (data: InfoFormResponseSaveRequest) => {
        return await saveInfoFormResponse(createTenantApiRequest)(data);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries([infoFormResponseQueryKey, { registrationId: registration?.id, type: InfoFormType.ADDITIONAL }]);
        toNextStep();
      }
      // onError: (error) => {}
    }
  );

  const submitForm = useCallback(async (values: FormData) => {
    if (!registration?.id) {
      return;
    }

    mutate({
      eventId,
      formType: 'additional',
      response: values,
      ownerId: registration.id,
      ownerType: 'Registration',
      infoFormId: infoFormByAttendeeCategoryQuery?.data?.id
    });
  }, [eventId, infoFormByAttendeeCategoryQuery, mutate, registration]);

  if (infoFormByAttendeeCategoryQuery.isLoading || !infoFormByAttendeeCategoryQuery?.data?.id) {
    return <Skeleton height='80px' />;
  }

  return (
    <QueryContainer query={infoFormByAttendeeCategoryQuery}>
      {(infoForm: InfoForm) => (
        <QueryContainer query={additionalInfoQuery}>
          {(additionInfo) => (
            <Formik
              initialValues={utils.getFieldsValues(additionInfo.response)}
              validateOnChange={false}
              validateOnBlur={false}
              validate={(values: FormData) => {
                const errors: Errors = {};
                // This is an example to check 'required' fields. We might validator others in the future.
                for (const fieldId in values) {
                  const fieldMetadata = utils.getFieldMetadata(fieldId);
                  if (fieldMetadata) {
                    // If a field contains exmpty string, we will treat it as empty. Currently we don't consider number or boolean.
                    if ('isRequired' in fieldMetadata && fieldMetadata.isRequired && values[fieldId] === '') {
                      errors[fieldId] = 'The field is required';
                    }
                  }
                }
                return errors;
              }}
              onSubmit={async (
                values,
                { setSubmitting }: FormikHelpers<FormData>
              ) => {
                setSubmitting(true);
                await submitForm(values);
                setSubmitting(false);
              }}
            >
              <Form>
                <BaseTransitionSlideLeft>
                  <UiStack flexGrow={1}>
                    <FormGenerator
                      fieldsLayout={infoForm.config.fieldsLayout}
                      fieldsMetadata={infoForm.config.fieldsMetadata}
                    />
                  </UiStack>
                </BaseTransitionSlideLeft>
                <UiStack height={96} />
                <LayoutFooter>
                  <UiHStack justifyContent={'flex-end'} flexGrow={1}>
                    <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
                      {isReviewing ? 'Back' : 'Previous'}
                    </UiButton>
                    <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading}>
                      {isReviewing ? 'Done' : 'Next'}
                    </UiButton>
                  </UiHStack>
                </LayoutFooter>
              </Form>
            </Formik>
          )}
        </QueryContainer>

      )}
    </QueryContainer>
  );
};

export default React.memo(AdditionalInfoForm);
