import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiHStackProps,
  uiStyles,
  UiText,
  UiHStack,
  UiStack,
  UiButton,
} from '@/lib/ui';

export interface PageRowProps extends UiHStackProps {
  title: string
  description: string
  onEdit: () => void
}

const PageRow: FC<PageRowProps> = ({
  title,
  description,
  onEdit,
}) => {
  return (
    <>
      <UiHStack
        justifyContent={'space-between'}
        alignItems={'stretch'}
        spacing={4}
        py={6}
        px={8}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
      >
        <UiHStack spacing={4}>
          <UiStack spacing={1} alignItems={'stretch'} justifyContent={'space-between'}>
            <UiText variant={'body1'}>{title}</UiText>
            <UiText variant={'body2'} color={'text.secondary'}>{description}</UiText>
          </UiStack>
        </UiHStack>
        <UiHStack spacing={8}>
          <UiHStack
            spacing={0}
            justifyContent={'space-between'}
          >
            <UiButton
              px={0}
              variant={'ghost'}
              {...uiStyles.hover}
              colorScheme={'primary'}
              onClick={onEdit}
            >
              Edit
            </UiButton>
          </UiHStack>
        </UiHStack>
      </UiHStack>
    </>
  );
};

export default PageRow;
