import { type FC, useState, useEffect } from 'react';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { UiBox, UiStack, UiText } from '@/lib/ui';
import SummarySession from './SummarySession';
import DiscountSession from './DiscountSession';
import PersonalInfoSession from './PersonalInfoSession';
import AdditionalInfoSession from './AdditionalInfoSession';
import { type LoginRequest, type RegistrationWithToken } from '@/api/registration';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useMutation } from '@tanstack/react-query';
import { login as loginAPI } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type UserSession } from '@/app/ProviderRegistrationAuth';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useAttendeeRoute } from '@/registration/hook/useAttendeeRoute';
import { useSearchParams } from 'react-router-dom';
import { CenteredSpinner } from '@/base/QueryContainer/QueryContainer';

const RegistrationDetails: FC = () => {
  const { createTenantApiRequest } = useTenantApi();
  const { eventId } = useAttendeeRoute();
  const [userSession, setUserSession] = useLocalStorage<UserSession | undefined>('userSession');
  const [errors, setErrors] = useState<string[]>([]);
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [registration, setRegistration] = useState<RegistrationWithToken | undefined>(undefined);
  const [searchParams] = useSearchParams();

  const { mutateAsync, isLoading: loginLoading } = useMutation<ApiResponseSingle<RegistrationWithToken>, Error, LoginRequest>({
    mutationFn: async (data: LoginRequest) => {
      return await loginAPI(createTenantApiRequest)(data);
    },
    onSuccess: (data: ApiResponseSingle<RegistrationWithToken>) => {
      if (data.item?.id) {
        setRegistration(data.item);
        setUserSession({ email: data.item.email, client: data.item.auth.client, authToken: data.item.auth.token });
      } else {
        setLoginFailed(true);
        setErrors(['Invalid or expired token. Please try again.']);
      }
    },
    onError: (error: any) => {
      throw error;
    }
  });

  useEffect(() => {
    const uid = searchParams.get('uid');
    const client = searchParams.get('client');
    const token = searchParams.get('token');
    if (client && token && uid) {
      void mutateAsync({ uid, client, token, eventId });
    }
  }, []);

  if (loginLoading && !loginFailed) {
    return (
      <CenteredSpinner />
    );
  }

  if (loginFailed) {
    return (
      <UiBox p={10}>
        <UiStack flexGrow={1} spacing={6} enableDivider={true} pb={16}>
          <UiStack>
            <UiText variant={'h6'}>Registration Details</UiText>
          </UiStack>
          <UiText variant={'body1'} color={'error'}>{errors.join(', ')}</UiText>
        </UiStack>
      </UiBox>
    );
  }

  return (
    <>
      {registration && (
        <UiBox p={10}>
          <BaseTransitionSlideLeft>
            <UiStack flexGrow={1} spacing={6} enableDivider={true} pb={16}>
              <UiStack>
                <UiText variant={'h6'}>Registration Details</UiText>
              </UiStack>
              <SummarySession registration={registration} />
              {registration.discount && (
                <DiscountSession registration={registration} />
              )}
              {registration.personalInfoFormResponseCount > 0 && (
                <PersonalInfoSession registration={registration} />
              )}
              {registration.additionalInfoFormResponseCount > 0 && (
                <AdditionalInfoSession registration={registration} />
              )}
            </UiStack>
          </BaseTransitionSlideLeft>
        </UiBox>
      )}
    </>
  );
};

export default RegistrationDetails;
