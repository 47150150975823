import { type FC } from 'react';
import { Field, type FieldProps } from 'formik';
import {
  UiFormControl,
  UiFormLabel,
  UiInput,
  type UiInputProps,
  UiFormErrorMessage,
  UiFormHelperText,
  UiHStack,
  UiText,
  UiVStack
} from '@/lib/ui';
import FieldContainer, { type FieldContainerProps } from './FieldContainer';

export interface InputProps extends Omit<FieldContainerProps, 'children'> {
  label: string
  name: string
  type?: UiInputProps['type']
  placeholder?: string
  helperText?: string
  isRequired?: boolean
  disabled?: boolean
  hidden?: boolean
}

const Input: FC<InputProps> = ({
  label,
  name,
  type = 'text',
  helperText = undefined,
  isRequired = true,
  placeholder = '',
  layout = 'inline',
  disabled = false,
  hidden = false
}) => {
  return (
    <Field name={name} >
      {({ field, form }: FieldProps) => (
        <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1} style={{ display: hidden ? 'none' : 'block' }}>
          <FieldContainer
            layout={layout}
          >
            {isRequired ? (
              <UiHStack alignItems={'flex-start'} spacing={0}>
                <UiFormLabel>{label}</UiFormLabel>
                <UiText color={'gray.600'} variant='title'>*</UiText>
              </UiHStack>
            ) : (
              <UiFormLabel>{label}</UiFormLabel>
            )}
            <UiVStack alignItems={'stretch'}>
              <UiInput {...field} placeholder={placeholder} type={type} disabled={disabled} />
              {!!helperText && (
                <UiFormHelperText>
                  {helperText}
                </UiFormHelperText>
              )}
              {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
            </UiVStack>
          </FieldContainer>
        </UiFormControl>
      )}
    </Field>
  );
};

export default Input;
