import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  UiSwitch,
  uiStyles
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { type EventSetting, type EventSettingsUpdateRequest } from '@/api/registration';
import { registration } from '@/api';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';

const Badge: FC = () => {
  const { eventId } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();
  const eventQuery = useEventSettingsQuery(eventId);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { mutate: mutateEventSettings } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
    },
    onError: () => {
      // setSaveErrors([error.message ?? 'Failed to save the attendee category.']);
    }
  });

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'badge', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      mutate,
      eventId,
      toNextStep
    ]
  );

  // Assume the step is set once the step is visited.
  useEffect(
    () => {
      mutate({ eventId, key: 'badge', bool: true });
    },
    [mutate, eventId]
  );

  const onToggle = useCallback(() => {
    mutateEventSettings({
      eventId,
      config: {
        badge: {
          isEnabled: !eventQuery.data?.badge?.isEnabled ?? true
        }
      }
    });
  }, [eventId, eventQuery.data?.badge?.isEnabled, mutateEventSettings]);

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'badges'}
          />
        )}
        message={'Please design your badge.'}
      >
        <QueryContainer
          errorMessage="Failed to load badges."
          query={eventQuery}>
          {(eventSettings) => (
            <>
              <UiHStack p={8}>
                {!eventSettings.badge?.isEnabled
                  ? (
                    <UiText variant={'title'} color={'text.secondary'}>
                      No badge required
                    </UiText>
                  ) : (
                    <UiText variant={'title'} color={'primary.500'}>
                      Badge required
                    </UiText>
                  )}
                <UiSwitch colorScheme={'primary'} onChange={onToggle} isChecked={eventSettings.badge?.isEnabled} size={'lg'} />
              </UiHStack>
              {!eventSettings.badge?.isEnabled
                ? (
                  <UiStack pb={8} px={8}>
                    <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>There will be badge for this event.</BaseMessageBarInfo>
                  </UiStack>
                ) : (
                  <UiStack />
                )}
              <UiHStack
                p={8}
                justifyContent={'space-between'}
                // flexDirection={'row-reverse'}
              >
                <UiStack flexGrow={1} />
                <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} onClick={onNext} isLoading={isLoading}>
                  Next
                </UiButton>
              </UiHStack>
            </>
          )}
        </QueryContainer>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default Badge;
