import { type FC, useMemo } from 'react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiHStack, UiIconCurrencyCircleDollar, UiStack, UiText, uiStyles } from '@/lib/ui';
import { createFormatPrice } from '@/lib/util/locale';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import { type Order, loadOrder, orderQueryKey } from '@/api/registration';
import { useQuery } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponseSingle } from '@/api/tenantClient';

const OrderReview: FC = () => {
  const { eventId } = useRegisterRoute();
  const { createTenantApiRequest } = useTenantApi();

  const { registrationQuery } = useRegistrationAuth();
  const registration = registrationQuery?.data;
  const orderHash = registration?.orderHash;

  const { data: orderData } = useQuery<ApiResponseSingle<Order>, Error>({
    queryKey: [orderQueryKey, { orderHash }],
    queryFn: async () => await loadOrder(createTenantApiRequest)(orderHash!),
    enabled: !!orderHash && !!eventId
  });

  const priceCurrency = 'AUD';
  const priceLocale = 'en-AU';
  const formatPrice = useMemo(
    () => createFormatPrice({ locale: priceLocale, currency: priceCurrency }),
    []
  );

  return (
    <UiStack>
      <UiHStack justifyContent={'space-between'}>
        <UiText variant={'title'}>
          Your purchase
        </UiText>
      </UiHStack>
      <UiStack
        p={8}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
      >
        {orderData?.item.orderItems.map((item) => (
          <UiStack key={item.id}>
            <UiText>{item.name}</UiText>
            <UiHStack justifyContent={'flex-start'}>
              <UiIconCurrencyCircleDollar size={'xl'} color={'text.primary'} />
              <UiText variant={'body2'}>Price</UiText>
              <UiText variant={'body2'}>{formatPrice(item.price * 100)}</UiText>
            </UiHStack>
          </UiStack>
        ))}
      </UiStack>
    </UiStack>
  );
};

export default OrderReview;
