import { type FC } from 'react';
import {
  type UiBoxProps, UiHStack, UiIconClosedCaptioning, UiIconFileArrowUp,
  UiStack,
  uiStyles, UiText
} from '@/lib/ui';
import AddonCard, { type AddonCardProps } from './AddonCard';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import { isAddonEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import AbstractCard from '@/base/App/AbstractCard';

export interface AbstractManagementProps extends UiBoxProps {
  subscription?: account.Subscription
  onToggle?: AddonCardProps['onToggle']
}

const AbstractManagement: FC<AbstractManagementProps> = ({
  onToggle = undefined,
  subscription = undefined
}) => {
  return (
    <AddonCard
      onToggle={onToggle}
      // isEnabled={subscription ? getAddonsEnabled(subscription).includes(SubscriptionProduct.AbstractManagement) : false}
      isEnabled={subscription ? isAddonEnabled(subscription, SubscriptionProduct.AbstractManagement) : false}
    >
      <UiStack spacing={0} borderRadius={uiStyles.borderRadius}>
        <UiHStack justifyContent={'space-between'}>
          <AbstractCard showDescription={false}/>
          {subscription && (
            <PriceDisplay price={subscription.products[SubscriptionProduct.AbstractManagement].monthlyPrice * 3} suffix={' / 3 months'} />
          )}
        </UiHStack>
        <BaseDividerHorizontal height={4} />
        <UiText variant='body1' color={'text.secondary'}>Abstract submission & review</UiText>
      </UiStack>
    </AddonCard>
  );
};

export default AbstractManagement;
