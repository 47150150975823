import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  deleteAPI,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

export const addressQueryKey = 'registration.addressList';
export const addressQueryKeyBoth = 'registration.addressListBoth';

export interface Address {
  id: number
  address: string
  address2?: string
  suburb: string
  country: string
  state: string
  postCode: string
  registrationId?: number
  userId?: number
  type: 'billing' | 'shipping'
}

export interface AddressQueryRequest {
  id: string
}

interface BothAddressRequest {
  registrationId: number
}

export interface BothAddressResponse {
  billingAddress: Address
  shippingAddress: Address
}

export function loadAddress(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: AddressQueryRequest
  ): Promise<ApiResponse<Address[]>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/addresses/${params.id}`;
    return await callApi<Address[]>(request);
  };
}

export function loadBothAddresses(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: BothAddressRequest
  ): Promise<ApiResponseSingle<BothAddressResponse>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/addresses/show_both/' + params.registrationId;
    return await properCallApi<ApiResponseSingle<BothAddressResponse>>(request);
  };
}

export interface AddressSaveRequestItem extends Omit<Address, 'id'> {
  id?: number
}

export interface AddressSaveRequest {
  addresses: AddressSaveRequestItem[]
}

export function saveAddresses(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: AddressSaveRequest
  ): Promise<ApiResponse<Address[]>> => {
    if (params.addresses.some(a => a.id)) {
      request.method = 'POST';
      request.endpoint.path = '/api/v1/addresses/update_both';
      request.payload = {
        addresses: params.addresses
      };
    } else {
      request.method = 'POST';
      request.endpoint.path = '/api/v1/addresses/create_both';
      request.payload = {
        addresses: params.addresses
      };
    }
    return await callApi<Address[]>(request);
  };
}

export interface AddressDeleteRequest {
  id: number
}

export function deleteAddress(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: AddressDeleteRequest) => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/addresses/' + params.id;
    await deleteAPI(request);
  };
}
