import { type FC, useMemo } from 'react';
import {
  UiText,
  uiStyles,
  UiStack,
  UiHStack,
  UiFlex,
  UiIconArrowsClockwise,
  UiIconArrowSquareOut,
  UiBox,
  UiTag,
  UiIconPencilSimple,
  UiSpinner
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import PersonalInfoStep from './PersonalInfo';
import BadgesStep from './Badges';
import AttendeeCategoriesStep from './AttendeeCategories';
import DiscountCodesStep from './DiscountCodes';
import MainEventTicketsStep from './MainEventTickets';
import FunctionTicketsStep from './FunctionTickets';
import TourTicketsStep from './TourTickets';
import WorkshopTicketsStep from './WorkshopTickets';
import AdditionaInfoStep from './AdditionalInfo';
// import GroupRegistrationStep from './GroupRegistration';
import PaymentMethods from './PaymentMethods';
import LabelsDescriptions from './LabelsDescriptions';
import OtherSettings from './OtherSettings';
import Privacy from './Privacy';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { RegistrationMode } from '@/api/constant';
import { generateRegisterAbsoluteUrl } from '@/lib/util';
// import Filter from './Filter';

// export interface StepListItem {
//   description: string;
//   value: string;
//   url: string;
// }

// const getStepsList = (tenantCode: string, eventId: string): StepListItem[] => {
//   return [
//     {
//       description: 'Choose the event',
//       value: 'eventInfo',
//       url: generatePageUrl('RegistrationBuildEventEventInfo', {eventId}),
//     },
//     {
//       description: 'Build delegate basic info form',
//       value: 'personalInfo',
//       url: generatePageUrl('RegistrationBuildEventPersonalInfoForm', {eventId}),
//     },
//     {
//       description: 'Add tickets',
//       value: 'tickets',
//       url: generatePageUrl('RegistrationBuildEventMainEventTickets', {eventId}),
//     },
//     {
//       description: 'Design badge',
//       value: 'badge',
//       url: generatePageUrl('RegistrationBuildEventBadge', {eventId}),
//     },
//   ];
// };

type StepName = 'groupRegistration' | 'eventInfo' | 'attendeeTypes' | 'discountCodes' | 'personalInfo' | 'mainEventTickets' | 'functionTickets' | 'workshopTickets' | 'tourTickets' | 'merchandise' | 'additionalInfo' | 'privacy' | 'paymentMethods' | 'badges' | 'labelsDescriptions' | 'otherSettings';

export interface StepsProps {
  activeStep: StepName
  eventId: string
}

const Steps: FC<StepsProps> = ({
  activeStep,
  eventId
}) => {
  // const { clientWidth } = useWindowSize();
  const { tenantCode } = useRegisterRoute();
  const attendeeRegisterLink = useMemo(
    () => generatePageUrl('RegistrationRegisterEmail', { tenantCode, eventId }),
    [eventId, tenantCode]
  );
  const { data: event } = useEventQuery(eventId);
  return (
    <UiStack alignItems={'stretch'} spacing={0}>
      <UiStack spacing={0} p={8}>
        <UiHStack
          spacing={4}
          alignItems={'center'}
          justifyContent={'space-between'}
          // bgColor={'#fff'}
          borderRadius={uiStyles.borderRadius}
          // p={8}
          // py={6}
        >
          <UiHStack spacing={16}>
            <UiStack>
              <UiHStack>
                <UiText color={'text.secondary'}>Event</UiText>
                <BaseRouterLink to={generatePageUrl('RegistrationManageEvent', { tenantCode })}>
                  <UiStack {...uiStyles.hover}>
                    <UiIconArrowsClockwise color={'primary.500'} size={'2xl'} />
                  </UiStack>
                </BaseRouterLink>
              </UiHStack>
              <UiText variant={'body1'}>{event?.name ?? '...'}</UiText>
            </UiStack>
            <UiStack>
              <UiHStack>
                <UiText color={'text.secondary'}>Registration mode</UiText>
                <UiStack {...uiStyles.hover}>
                  <BaseRouterLink to={generatePageUrl('RegistrationBuildEventOtherSettings', { tenantCode, eventId })}>
                    <UiIconPencilSimple color={'primary.500'} size={'2xl'} />
                  </BaseRouterLink>
                </UiStack>
              </UiHStack>
              <UiBox>
                {event?.registrationMode ? (
                  <UiTag colorScheme={event.registrationMode === RegistrationMode.Live ? 'green' : 'yellow'}>{event.registrationMode}</UiTag>
                ) : (
                  <UiSpinner size={'md'} color={'primary.500'} thickness='2px' />
                )}
              </UiBox>
            </UiStack>
            <UiStack>
              <UiHStack>
                <UiHStack>
                  <UiText flexGrow={1} color={'text.secondary'}>Event register link</UiText>
                  <BaseRouterLink to={attendeeRegisterLink ?? undefined} target={'_blank'}>
                    <UiIconArrowSquareOut color={'primary.500'} size={'2xl'} />
                  </BaseRouterLink>
                </UiHStack>
              </UiHStack>
              <UiText variant={'body1'}>{generateRegisterAbsoluteUrl(attendeeRegisterLink)}</UiText>
            </UiStack>
          </UiHStack>
        </UiHStack>
      </UiStack>
      <BaseDividerHorizontal height={0} />
      <UiFlex
        flexGrow={1}
        flexWrap={'wrap'}
        px={4}
        py={4}
      >
        {/* <UiStack p={4}> */}
        {/*  <GroupRegistrationStep isActive={activeStep === 'groupRegistration'} /> */}
        {/* </UiStack> */}
        <UiStack p={4}>
          <AttendeeCategoriesStep isActive={activeStep === 'attendeeTypes'} />
        </UiStack>
        <UiStack p={4}>
          <DiscountCodesStep isActive={activeStep === 'discountCodes'} />
        </UiStack>
        <UiStack p={4}>
          <Privacy isActive={activeStep === 'privacy'} />
        </UiStack>
        <UiStack p={4}>
          <PersonalInfoStep isActive={activeStep === 'personalInfo'} />
        </UiStack>
        <UiStack p={4}>
          <MainEventTicketsStep isActive={activeStep === 'mainEventTickets'} />
        </UiStack>
        <UiStack p={4}>
          <FunctionTicketsStep isActive={activeStep === 'functionTickets'} />
        </UiStack>
        <UiStack p={4}>
          <WorkshopTicketsStep isActive={activeStep === 'workshopTickets'} />
        </UiStack>
        <UiStack p={4}>
          <TourTicketsStep isActive={activeStep === 'tourTickets'} />
        </UiStack>
        <UiStack p={4}>
          <AdditionaInfoStep isActive={activeStep === 'additionalInfo'} />
        </UiStack>
        <UiStack p={4}>
          <PaymentMethods isActive={activeStep === 'paymentMethods'} />
        </UiStack>
        <UiStack p={4}>
          <BadgesStep isActive={activeStep === 'badges'} />
        </UiStack>
        {/* <UiStack p={4}> */}
        {/*  <LabelsDescriptions isActive={activeStep === 'labelsDescriptions'} /> */}
        {/* </UiStack> */}
        <UiStack p={4}>
          <OtherSettings isActive={activeStep === 'otherSettings'} />
        </UiStack>
      </UiFlex>

      {/* <UiHStack spacing={4} px={8}>
        <UiText variant={'title'}>1. Preparation</UiText>
        <UiText variant={'body1'} color={'text.secondary'}>xxx</UiText>
      </UiHStack>
      <UiFlex
        flexGrow={1}
        flexWrap={'wrap'}
        px={4}
      >
        <UiStack p={4}>
          <GroupRegistrationStep eventId={eventId} isActive={activeStep === 'groupRegistration'}/>
        </UiStack>
        <UiStack p={4}>
          <AttendeeCategoriesStep eventId={eventId} isActive={activeStep === 'attendeeTypes'}/>
        </UiStack>
        <UiStack p={4}>
          <DiscountCodesStep eventId={eventId} isActive={activeStep === 'discountCodes'}/>
        </UiStack>
      </UiFlex>
      <UiStack height={4}/>
      <UiHStack spacing={4} px={8}>
        <UiText variant={'title'}>2. Forms & tickets</UiText>
        <UiText variant={'body1'} color={'text.secondary'}>xxx</UiText>
      </UiHStack>
      <UiFlex
        flexGrow={1}
        flexWrap={'wrap'}
        px={4}
      >
        <UiStack p={4}>
          <PersonalInfoStep eventId={eventId} isActive={activeStep === 'personalInfo'}/>
        </UiStack>
        <UiStack p={4}>
          <MainEventTicketsStep eventId={eventId} isActive={activeStep === 'mainEventTickets'}/>
        </UiStack>
        <UiStack p={4}>
          <FunctionTicketsStep eventId={eventId} isActive={activeStep === 'functionTickets'}/>
        </UiStack>
        <UiStack p={4}>
          <WorkshopTicketsStep eventId={eventId} isActive={activeStep === 'workshopTickets'}/>
        </UiStack>
        <UiStack p={4}>
          <TourTicketsStep eventId={eventId} isActive={activeStep === 'tourTickets'}/>
        </UiStack>
        <UiStack p={4}>
          <AdditionaInfoStep eventId={eventId} isActive={activeStep === 'additionalInfo'}/>
        </UiStack>
      </UiFlex>
      <UiStack height={4}/>
      <UiHStack spacing={4} px={8}>
        <UiText variant={'title'}>3. Payment & badges</UiText>
        <UiText variant={'body1'} color={'text.secondary'}>xxx</UiText>
      </UiHStack>
      <UiFlex
        flexGrow={1}
        flexWrap={'wrap'}
        px={4}
      >
        <UiStack p={4}>
          <Privacy eventId={eventId} isActive={activeStep === 'privacy'}/>
        </UiStack>
        <UiStack p={4}>
          <BadgesStep eventId={eventId} isActive={activeStep === 'badges'}/>
        </UiStack>
      </UiFlex> */}
    </UiStack>
  );

  // const listItems = useMemo(
  //   () => {
  //     return getStepsList(tenantCode, eventId);
  //   },
  //   [tenantCode, eventId]
  // );

  // return (
  //   <UiHStack spacing={8} flexGrow={1}>
  //     {listItems.map((listItem) => {
  //       if (activeStep === listItem.value) {
  //         return (
  //           <UiVStack
  //             alignItems={'flex-start'}
  //             spacing={0}
  //             minW={260}
  //             maxW={260}
  //             minH={260}
  //             maxH={260}
  //             bgColor={'primary.50'}
  //             p={4}
  //             // px={8}
  //             borderRadius={uiStyles.borderRadius}
  //           >
  //             <UiStack flexGrow={1}></UiStack>
  //             {/* <UiText variant={'title'}>{listItem.title}</UiText> */}
  //             <UiText variant={'body1'} fontWeight={600}>{listItem.description}</UiText>
  //           </UiVStack>
  //         );
  //       } else {
  //         return (
  //           <BaseRouterLink to={listItem.url}>
  //             <UiVStack
  //               minW={260}
  //               maxW={260}
  //               minH={260}
  //               maxH={260}
  //               alignItems={'flex-start'}
  //               {...uiStyles.hover}
  //               spacing={0}
  //               borderRadius={uiStyles.borderRadius}
  //               p={4}
  //               // px={8}
  //               bgColor={'#fff'}
  //               // borderWidth={'1px'}
  //               // borderStyle={'solid'}
  //               // borderColor={'gray.300'}
  //             >
  //               <UiStack flexGrow={1}></UiStack>
  //               {/* <UiText variant={'title'}>{listItem.title}</UiText> */}
  //               <UiText variant={'body1'} fontWeight={600}>{listItem.description}</UiText>
  //             </UiVStack>
  //           </BaseRouterLink>
  //         );
  //       }
  //     })}
  //   </UiHStack>
  // );
};

export default Steps;
