import { type FC, useCallback, useState } from 'react';
import {
  UiHStack,
  type UiHStackProps,
  UiVStack,
  uiStyles,
  UiText,
  UiSwitch,
  UiStack,
  UiSpinner
} from '@/lib/ui';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import type { EventSetting, EventSettingsUpdateRequest } from '@/api/registration';
import { registration } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { Skeleton } from '@chakra-ui/react';
import { useTenantApi } from '@/account/hook/useTenantApi';

export interface SettingProps extends UiHStackProps {}

const Setting: FC<SettingProps> = () => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const eventQuery = useEventSettingsQuery(eventId);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { mutate, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        // Trigger the host list reload.
        void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the attendee category.']);
    }
  });

  const toggleSwitch = useCallback(
    () => {
      if (eventQuery.data) {
        mutate({
          eventId,
          config: {
            groupRegistration: {
              isEnabled: !eventQuery.data.groupRegistration?.isEnabled
            }
          }
        });
      }
    },
    [eventId, eventQuery.data, mutate]
  );

  return (
    <QueryContainer
      query={eventQuery}
      loadingComponent={<Skeleton height='80px' />}
      errorMessage="Failed to load data."
    >
      {(eventSettings) => (
        <UiStack
          justifyContent={'flex-start'}
          alignItems={'stretch'}
          // {...uiStyles.hover}
          // alignItems={'flex-start'}
          // justifyContent={'flex-start'}
          spacing={4}
          // minWidth={'300px'}
          // py={4}
          // bgColor={'#fff'}
          borderRadius={uiStyles.borderRadius}
        >
          <UiStack alignItems={'flex-start'}>
            <UiHStack p={8} py={6} spacing={4} bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
              {isLoading
                ? <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
                : <UiSwitch colorScheme={'primary'} onChange={toggleSwitch} isChecked={eventSettings.groupRegistration?.isEnabled} size={'lg'} />
              }
              {eventSettings.groupRegistration?.isEnabled ? (
                <UiText variant={'body1'} color={'green.600'} fontWeight={'bold'}>
                  Group registration is turned on
                </UiText>
              ) : (
                <UiText variant={'body1'} color={'text.secondardy'}>
                  Group registration is turned off
                </UiText>
              )}
            </UiHStack>
          </UiStack>
          {saveErrors.length > 0 && (
            <UiStack spacing={2} flexGrow={1}>
              {saveErrors.map((error) => (
                <UiText color={'red.500'} key={error}>
                  {error}
                </UiText>
              ))}
            </UiStack>
          )}
        </UiStack>
      )}
    </QueryContainer>
  );
};

export default Setting;
