import { type FC, useCallback } from 'react';
import { Formik, Form, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  type UiHStackProps,
  UiStack,
  UiButton,
  UiText,
  UiLink,
  uiStyles,
  UiHStack
} from '@/lib/ui';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { IsRequiredType, type DiscountCode, type Registration, type RegistrationUpdateRequest, updateRegistration, type AttendeeCategoryData, registrationQueryKey } from '@/api/registration';
import CheckboxField from '@/base/Form/CheckboxField';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import DiscountCodeForm from './DiscountCodeForm';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import { type ApiResponse, type ApiResponseSingle } from '@/api/tenantClient';
import { type FileOrUrl } from '@/base/ImageSelect/ImageSelectItem';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import { registration as registrationAPI } from '@/api';
import { toBase64 } from '@/lib/util';

export interface CategoryFormProps extends UiHStackProps {
  attendeeCategoryId: number | undefined
  attendeeCategoryData: AttendeeCategoryData[] | undefined
}

export interface FormData {
  attendeeCategory: string | undefined
  discountCodeString?: string
  discountCode?: DiscountCode
  idPhotocopy: FileOrUrl[]
  idInfo?: string
  isPrivacyPolicyAgreed: boolean
  isTermsAndConditionsAgreed: boolean
  // strict value
  privacyPolicyUrl?: string
  termsAndConditionsUrl?: string
}

const initFormData: FormData = {
  attendeeCategory: '',
  idInfo: '',
  idPhotocopy: [],
  isPrivacyPolicyAgreed: false,
  isTermsAndConditionsAgreed: false
};

const formSchema = Yup.object().shape({
  privacyPolicyUrl: Yup.string().nullable(),
  termsAndConditionsUrl: Yup.string().nullable(),
  discountCodeString: Yup.string().nullable(),
  discountCode: Yup.object({
    id: Yup.string(),
    discountCode: Yup.string(),
    idPhotocopy: Yup.string()
  }).nullable(),
  idPhotocopyBase64: Yup.string(),
  attendeeCategory: Yup.string().required('Please choose an attendee category'),
  isPrivacyPolicyAgreed: Yup.boolean().when('privacyPolicyUrl', {
    is: (value: string) => (!!value),
    then: (schema) => schema.isTrue('Please agree with the privacy policy before continue')
  }),
  isTermsAndConditionsAgreed: Yup.boolean().when('termsAndConditionsUrl', {
    is: (value: string) => (!!value),
    then: (schema) => schema.isTrue('Please agree with terms and condition before continue')
  }),
  idPhotocopy: Yup.array()
    .when('discountCode', {
      is: (discountCode: DiscountCode | undefined) => (discountCode?.idPhotocopy === IsRequiredType.REQUIRED),
      then: (schema) => schema.min(1, 'ID Photocopy is required')
    }),
  idInfo: Yup.string()
    .when('discountCode', {
      is: (discountCode: DiscountCode | undefined) => (discountCode?.idInfo === IsRequiredType.REQUIRED),
      then: (schema) => schema.required('ID Info is required')
    })
});

const CategoryForm: FC<CategoryFormProps> = ({
  attendeeCategoryId,
  attendeeCategoryData
}) => {
  const queryClient = useQueryClient();
  const { eventId } = useRegisterRoute();
  const { createTenantApiRequest, createTenantApiRequestWithSession } = useTenantApi();
  const eventSettingQuery = useEventSettingsQuery(eventId);
  const { userSession, registration } = useRegistrationAuth();
  const { toNextStepWithSteps } = useRegisterSteps();

  const options: CheckboxOption[] = attendeeCategoryData
    ?.filter((attendeeCategory) => attendeeCategory.isVisible)
    .map((attendeeCategory) => ({
      value: attendeeCategory.id.toString(),
      label: attendeeCategory.name,
      description: attendeeCategory.description
    })) ?? [];

  const { mutateAsync: updateRegistrationMutate, isLoading: isUpdateRegistrationLoading, error } = useMutation<ApiResponseSingle<Registration>, Error, RegistrationUpdateRequest>({
    mutationFn: async (data: RegistrationUpdateRequest) => {
      return await updateRegistration(createTenantApiRequestWithSession(userSession))(data);
    },
    onSuccess: (data: ApiResponseSingle<Registration>) => {
      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors.join(', '));
      }
      void queryClient.invalidateQueries({ queryKey: [registrationQueryKey] });
    },
    onError: (registrationError: any) => {
      throw registrationError;
    }
  });

  const { mutateAsync: checkDiscountCode, isLoading: isCheckingDiscountCode } = useMutation<ApiResponse<DiscountCode>, Error, string>({
    mutationFn: async (code: string) => {
      return await registrationAPI.checkDiscountCode(createTenantApiRequest)({ eventId, discountCode: code });
    }
  });

  const onSubmit = useCallback(
    async (
      values: FormData,
      { setSubmitting }: FormikHelpers<FormData>
    ) => {
      setSubmitting(true);
      const data = await updateRegistrationMutate({
        registration: {
          id: registration!.id,
          attendeeCategoryId: Number(values.attendeeCategory),
          tmpIdPhotocopy: values.idPhotocopy[0] instanceof File ? await toBase64(values.idPhotocopy[0]) : values.idPhotocopy[0],
          idInfo: values.idInfo,
          termsAndConditionsAccepted: values.isTermsAndConditionsAgreed,
          privacyPolicyAccepted: values.isPrivacyPolicyAgreed,
          ...values.discountCode ? { discountId: values.discountCode.id } : {}
        }
      });
      setSubmitting(false);
      toNextStepWithSteps(data.item.registrationSteps);
    }, [registration, toNextStepWithSteps, updateRegistrationMutate]);

  return (
    <QueryContainer query={eventSettingQuery}>
      {(eventSetting) => (
        <Formik<FormData>
          initialValues={{
            ...initFormData,
            privacyPolicyUrl: eventSetting.privacy?.privacyPolicyUrl,
            termsAndConditionsUrl: eventSetting.privacy?.termsAndConditionsUrl,
            attendeeCategory: attendeeCategoryId ? attendeeCategoryId.toString() : (registration?.attendeeCategoryId ? registration?.attendeeCategoryId.toString() : undefined),
            discountCodeString: registration?.discount?.discountCode ?? undefined,
            discountCode: registration?.discount ?? undefined,
            idPhotocopy: registration?.tmpIdPhotocopy ? [registration?.tmpIdPhotocopy] : [],
            idInfo: registration?.idInfo ?? '',
            isPrivacyPolicyAgreed: registration?.privacyPolicyAccepted ?? !!userSession?.authToken,
            isTermsAndConditionsAgreed: registration?.termsAndConditionsAccepted ?? !!userSession?.authToken
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={formSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <BaseTransitionSlideLeft>
              <BaseFormFieldGroup>
                {error && (
                  <UiStack spacing={4} flexGrow={1} py={4}>
                    <BaseMessageBarError>
                      {error.message ?? 'Failed to save the host.'}
                    </BaseMessageBarError>
                  </UiStack>
                )}
                {!attendeeCategoryId && options.length > 0 ? (
                  <BaseFormCheckboxListField
                    label={'Attendee category'}
                    name={'attendeeCategory'}
                    options={options}
                    isMultiple={false}
                    layout={'stack'} />
                ) : <></>}
                <DiscountCodeForm
                  existingDiscountCode={registration?.discount}
                  onCheckDiscountCode={checkDiscountCode}
                  disabled={(!!userSession?.authToken && registration?.paid) ?? false}
                />
                <BaseDividerHorizontal height={1} />
                <UiStack spacing={4} pb={4}>
                  {!!eventSetting?.privacy.privacyPolicyUrl && (
                    <CheckboxField name="isPrivacyPolicyAgreed">
                      <UiHStack spacing={0}>
                        <UiText>{'I have read and agree to the\u00A0'}</UiText>
                        <UiLink target={'_blank'} href={eventSetting.privacy.privacyPolicyUrl}>
                          <UiText color={'primary.500'} {...uiStyles.hover}>Privacy Policy</UiText>
                        </UiLink>
                      </UiHStack>
                    </CheckboxField>
                  )}
                  {!!eventSetting?.privacy.termsAndConditionsUrl && (
                    <CheckboxField name="isTermsAndConditionsAgreed">
                      <UiHStack spacing={0}>
                        <UiText>{'I have read and agree to the\u00A0'}</UiText>
                        <UiLink target={'_blank'} href={eventSetting.privacy.termsAndConditionsUrl}>
                          <UiText color={'primary.500'} {...uiStyles.hover}>Terms and Conditions</UiText>
                        </UiLink>
                      </UiHStack>
                    </CheckboxField>
                  )}
                </UiStack>
              </BaseFormFieldGroup>
            </BaseTransitionSlideLeft>
            <UiStack height={96} />
            <LayoutFooter>
              <UiHStack justifyContent={'flex-end'} flexGrow={1}>
                <UiButton
                  px={8}
                  size={'lg'}
                  shadow={'base'}
                  colorScheme={'primary'}
                  type={'submit'}
                  isLoading={isCheckingDiscountCode || isUpdateRegistrationLoading}>
                  Next
                </UiButton>
              </UiHStack>
            </LayoutFooter>
          </Form>
        </Formik>
      )}
    </QueryContainer>
  );
};

export default CategoryForm;
