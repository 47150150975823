import { type FC, useEffect, useState } from 'react';
import { type registration } from '@/api';
import ImageSelectField from '@/base/Form/ImageSelectField';
import { useField } from 'formik';
import { useDebounce } from 'use-debounce';
import { IsRequiredType, type DiscountCode } from '@/api/registration';
import BaseFormInputField from '@/base/Form/InputField';
import { UiStack, UiText } from '@/lib/ui';
import { type ApiResponse } from '@/api/tenantClient';

export interface DiscountCodeFormProps {
  existingDiscountCode: DiscountCode | undefined
  onCheckDiscountCode: (code: string) => Promise<ApiResponse<registration.DiscountCode>>
  disabled: boolean
}

const DiscountCodeForm: FC<DiscountCodeFormProps> = ({ existingDiscountCode, onCheckDiscountCode, disabled }) => {
  const [error, setError] = useState<string>();

  const [,{ value: discountCodeString }] = useField<string>('discountCodeString');
  const [debouncedDiscountCode] = useDebounce<string>(discountCodeString, 1000);
  const [,{ value }, { setValue }] = useField<DiscountCode | undefined>('discountCode');

  useEffect(() => {
    void (async () => {
      if (!debouncedDiscountCode || debouncedDiscountCode === '') {
        void setValue(undefined);
        setError(undefined);
      } else {
        const response = await onCheckDiscountCode(debouncedDiscountCode);
        if (response.errors && response.errors.length > 0) {
          void setValue(undefined);
          setError('Invalid discount code');
        } else {
          void setValue(response.items[0]);
          setError(undefined);
        }
      }
    })();
  }, [debouncedDiscountCode, onCheckDiscountCode, setValue]);

  useEffect(() => {
    if (existingDiscountCode) {
      void setValue(existingDiscountCode);
    }
  }, [setValue]);

  return (
    <>
      <UiStack spacing={4}>
        <BaseFormInputField
          name={'discountCodeString'}
          label={'Discount code'}
          isRequired={false}
          layout={'stack'}
          disabled={disabled}
        />
        {error && (<UiText color={'red.500'}>{error}</UiText>)}
      </UiStack>
      {(value?.idPhotocopy === IsRequiredType.REQUIRED || value?.idPhotocopy === IsRequiredType.OPTIONAL) && (
        <ImageSelectField
          name="idPhotocopy"
          label="ID photocopy"
          isRequired={value?.idPhotocopy === IsRequiredType.REQUIRED}
          layout={'stack'}
          disabled={disabled}
        />
      )}
      {(value?.idInfo === IsRequiredType.REQUIRED || value?.idInfo === IsRequiredType.OPTIONAL) && (
        <BaseFormInputField
          name={'idInfo'}
          label={'ID Info'}
          isRequired={value?.idInfo === IsRequiredType.REQUIRED}
          layout={'stack'}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default DiscountCodeForm;
