import { type FC, useState } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
// import Filter from './Filter';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import RegistrationTable from './RegistrationTable';

const Registration: FC = () => {
  const { tenantCode } = useRegisterRoute();

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['manage', 'registrations']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody
        isStandalone={false}
        // breadcrumbNodes={breadcrumbNodes}
      >
        <RegistrationTable />
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default Registration;
