import { useCallback, type FC, useMemo } from 'react';
import copy from 'copy-to-clipboard';
import {
  UiButton,
  UiHStack,
  type UiHStackProps,
  UiStack,
  uiStyles,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerFooter,
  UiIconQuestion,
  UiBox,
  UiTag
} from '@/lib/ui';
import { useDisclosure } from '@chakra-ui/react';
import CategoryForm from './CategoryForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type AttendeeCategoryData, type AttendeeCategoryDeleteRequest } from '@/api/registration';
import { registration } from '@/api';
import { type ApiResponse } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { generatePageUrl } from '@/app/pages';
import { UiTooltip } from '@/lib/ui/Tooltip';
import DeletionConfirmation from './DeletionConfirmation';
import { generateRegisterAbsoluteUrl } from '@/lib/util';
// import { text } from '@/util';

export interface CategoryCardProps extends UiHStackProps {
  eventId: string
  tenantCode: string
  data: registration.AttendeeCategoryData
}

const CategoryCard: FC<CategoryCardProps> = ({
  eventId,
  tenantCode,
  data
}) => {
  const queryClient = useQueryClient();
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isConfirmClone, onToggle: onToggleConfirmClone } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutate, isLoading } = useMutation<undefined, Error, AttendeeCategoryDeleteRequest>({
    mutationFn: async (request: AttendeeCategoryDeleteRequest) => {
      return await registration.deleteAttendeeCategory(createTenantAdminApiRequest)(request);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.attendeeCategoryListQueryKey, { eventId: data.eventId.toString() }] });
    },
    onError: () => {
      // NOTE: handle error
    }
  });

  const { mutate: mutateClone, isLoading: isCloning } = useMutation<ApiResponse<AttendeeCategoryData>, Error, number>({
    mutationFn: async (attendeeCategoryId: number) => {
      return await registration.cloneAttendeeCategory(createTenantAdminApiRequest)(attendeeCategoryId);
    },
    onSuccess: (result) => {
      // Trigger the host list reload.
      void queryClient.invalidateQueries({ queryKey: [registration.attendeeCategoryListQueryKey, { eventId: data.eventId.toString() }] });
      onToggleConfirmClone();
    }
  });

  const onDelete = useCallback(() => {
    mutate({ id: data.id });
  }, [data.id, mutate]);

  const onConfirm = useCallback(() => {
    mutateClone(data.id);
  }, [data, mutateClone]);

  const registrationLink = useMemo(
    () => generateRegisterAbsoluteUrl(generatePageUrl('RegistrationRegisterEmail', { tenantCode, eventId }, { attendeeCategory: data.id })),
    [data.id, eventId, tenantCode]
  );

  const copyRegistrationLink = useCallback(
    () => copy(registrationLink),
    [registrationLink]
  );

  return (
    <>
      <UiHStack
        alignItems={'center'}
        spacing={0}
        p={8}
        py={4}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
      >
        <UiStack alignItems={'flex-start'} spacing={0} justifyContent={'flex-start'} flexGrow={1}>
          <UiText variant={'body1'} fontWeight={600}>{data.name}</UiText>
          <UiText variant={'body2'} color={'text.secondary'}>{data.description ?? ' '}</UiText>
        </UiStack>
        <UiHStack
          spacing={8}
          // px={8}
          justifyContent={'space-between'}
        >
          {
            data.isVisible
              ? <UiTag p={2} bgColor={'green.100'} borderRadius={uiStyles.borderRadius}>Visible</UiTag>
              : <UiTag p={2} bgColor={'blackAlpha.100'} borderRadius={uiStyles.borderRadius}>Hidden</UiTag>
          }
          <UiButton
            px={0}
            variant={'ghost'}
            {...uiStyles.hover}
            colorScheme={'red'}
            onClick={onToggleConfirmDeletion}
            isLoading={isLoading}
          >
            Delete
          </UiButton>
          <UiStack />
          <UiHStack spacing={2} alignItems={'center'}>
            <UiButton
              colorScheme={'primary'}
              variant={'ghost'}
              px={0}
              onClick={copyRegistrationLink}
            >
              Copy registration link
            </UiButton>
            <UiTooltip
              label={'The url will be specific for attendees of this attendee category to register for the event. '}
              shouldWrapChildren={true}
              placement='auto-start'
            >
              <UiBox {...uiStyles.hover}>
                <UiIconQuestion color={'primary.500'} />
              </UiBox>
            </UiTooltip>
          </UiHStack>
          <UiButton
            px={0}
            variant={'ghost'}
            {...uiStyles.hover}
            colorScheme={'primary'}
            onClick={onToggleConfirmClone}
          >
            Clone
          </UiButton>
          <UiButton
            px={0}
            variant={'ghost'}
            {...uiStyles.hover}
            colorScheme={'primary'}
            onClick={onToggle}
          >
            Edit
          </UiButton>
        </UiHStack>
      </UiHStack>
      <CategoryForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
        attendeeCategory={data}
      />
      <UiDrawer placement={'bottom'} size={'lg'} isOpen={isConfirmClone} onClose={onToggleConfirmClone}>
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={8}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
              Do you confirm to clone this attendee category?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={8}>
              <UiButton onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}>
                No
              </UiButton>
              <UiButton colorScheme={'red'} ml={4} onClick={onConfirm} isLoading={isCloning} px={8}>
                Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <DeletionConfirmation isOpen={confirmDeletion} onClose={onToggleConfirmDeletion} onConfirm={onDelete} name={data.name} />
    </>
  );
};

export default CategoryCard;
