/**
 * Notes We should always use these 2 hooks (useEventSettingsQuery, useEventSettingsSegmentMutation) to
 * manage all the event settings related data in all the components during the registration setup.
 */
import { type FC, useCallback } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton, UiText
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import Settings from './Settings';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import AttendeeCategoryPage from '@/registration/buildEvent/OtherSettings/LabelsDescriptions/AttendeeCategoryPage';
import PersonalInformationPage from '@/registration/buildEvent/OtherSettings/LabelsDescriptions/PersonalInformationPage';
import MainTicketPage from '@/registration/buildEvent/OtherSettings/LabelsDescriptions/MainTicketPage';
import FunctionTicketPage from '@/registration/buildEvent/OtherSettings/LabelsDescriptions/FunctionTicketPage';
import WorkshopTicketPage from '@/registration/buildEvent/OtherSettings/LabelsDescriptions/WorkshopTicketPage';
import TourTicketPage from '@/registration/buildEvent/OtherSettings/LabelsDescriptions/TourTicketPage';

const OtherSettings: FC = () => {
  // const [showFilter, setShowFilter] = useState<boolean>(false);
  const { eventId } = useRegisterRoute();
  // const { mutate, isLoading } = useIsSetMutation();
  // const { toNextStep } = useBuildEventSteps();
  //
  // const onNext = useCallback(
  //   () => {
  //     toNextStep();
  //   },
  //   [
  //     toNextStep
  //   ]
  // );

  // // Assume the step is set once the step is visited.
  // useEffect(
  //   () => {
  //     mutate({ eventId, key: 'otherSettings', bool: true });
  //   },
  //   [mutate, eventId]
  // );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={
          <ManageEventSteps
            eventId={eventId}
            activeStep={'otherSettings'}
          />
        }
        message={''}
      >
        {/* <BaseDividerHorizontal height={1}/> */}
        <UiStack
          // pt={4}
          p={8}
          spacing={4}
          flexGrow={1}
        >
          <Settings />
          <BaseDividerHorizontal height={4} />
          <UiStack spacing={4}>
            <UiText fontWeight={600}>Manage labels and descriptions</UiText>
            <AttendeeCategoryPage />
            <PersonalInformationPage />
            <MainTicketPage />
            <FunctionTicketPage />
            <WorkshopTicketPage />
            <TourTicketPage />
          </UiStack>
        </UiStack>
        {/* <UiHStack */}
        {/*  justifyContent={'space-between'} */}
        {/*  // flexDirection={'row-reverse'} */}
        {/*  p={8} */}
        {/* > */}
        {/*  <UiStack flexGrow={1} /> */}
        {/*  <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} isLoading={isLoading} onClick={onNext}> */}
        {/*    Save */}
        {/*  </UiButton> */}
        {/* </UiHStack> */}
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default OtherSettings;
