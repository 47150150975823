import { useMemo, type FC, type ReactNode } from 'react';
import {
  UiStack,
  uiStyles
} from '@/lib/ui';
import BaseBreadcrumbBar, { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

export interface LayoutBodyProps {
  children: ReactNode
  steps: ReactNode
  breadcrumbNodes?: BaseBreadcrumbBarNode[]
  // footer?: ReactNode
  message?: string
}

const LayoutBody: FC<LayoutBodyProps> = ({
  children,
  steps,
  breadcrumbNodes,
  // footer = undefined,
  message = undefined
}) => {
  const { tenantCode } = useRegisterRoute();
  // const [footerHeight, setFooterHeight] = useState(0);
  // const footerRef = useRef(null);
  const defaultBreadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => [
      { label: 'Manage' },
      { label: 'Registration setup', url: generatePageUrl('RegistrationManageEvent', { tenantCode }) },
      { label: 'Build' }
    ],
    [tenantCode]
  );
  // useEffect(
  //   () => {
  //     if (footer && footerRef.current) {
  //       setFooterHeight((footerRef.current as HTMLDivElement).offsetHeight ?? 0);
  //     }
  //   },
  //   [
  //     footer,
  //     footerRef.current
  //   ]
  // );

  return (
    <UiStack
      spacing={0.5}
      alignItems={'stretch'}
      flexGrow={1}
    >
      <UiStack
        alignItems={'stretch'}
        spacing={0}
        enableDivider={true}
        // borderTopRadius={uiStyles.borderRadius}
        {...uiStyles.glass}
      >
        <UiStack
          borderTopRadius={uiStyles.borderRadius}
          spacing={0}
        >
          <UiStack
            p={8}
            py={6}
            // borderRadius={uiStyles.borderRadius}
          >
            <BaseBreadcrumbBar title={'Sell'} nodes={breadcrumbNodes ?? defaultBreadcrumbNodes} />
          </UiStack>
        </UiStack>
        <UiStack
          // borderBottomRadius={uiStyles.borderRadius}
          spacing={0}
        >
          <UiStack
            alignItems={'stretch'}
            justifyContent={'flex-start'}
            spacing={8}
          >
            {steps}
          </UiStack>
        </UiStack>
      </UiStack>
      <BaseTransitionSlideLeft>
        <UiStack
          alignItems={'stretch'}
          flexGrow={1}
          spacing={0}
          {...uiStyles.glass}
          // mb={footer ? `${footerHeight}px` : 0}
        >
          {children}
        </UiStack>
      </BaseTransitionSlideLeft>
      {/* {footer && ( */}
      {/*  <UiBox */}
      {/*    ref={footerRef} */}
      {/*    position={'fixed'} */}
      {/*    bottom={0} */}
      {/*    left={0} */}
      {/*    bgColor={'#fff'} */}
      {/*    zIndex={99} */}
      {/*    alignItems={'stretch'} */}
      {/*    width={'100%'} */}
      {/*    borderTopColor={'gray.300'} */}
      {/*    borderTopWidth={'1px'} */}
      {/*    borderTopStyle={'solid'} */}
      {/*  > */}
      {/*    {footer} */}
      {/*  </UiBox> */}
      {/* )} */}
    </UiStack>
  );
};

export default LayoutBody;
