import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { UiStack, UiText } from '@/lib/ui';
import PayByCard from './PayByCard';
import PayByInvoice from './PayByInvoice';
import { registration } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';

const Payment: FC = () => {
  const { createTenantApiRequest } = useTenantApi();

  const paymentOptionQuery = useQuery(
    [registration.paymentOptionQueryKey],
    async () => (await registration.loadPaymentOptions(createTenantApiRequest)({})).items
  );

  return (
    <RegisterLayout>
      <BaseTransitionSlideLeft>
        <UiStack flexGrow={1} spacing={4}>
          <UiStack>
            <UiText variant={'h6'}>Choose payment method</UiText>
          </UiStack>
          {paymentOptionQuery.data?.find(option => option.name === 'stripe' && option.enabled) && <PayByCard creditCardGateway='Stripe' />}
          {paymentOptionQuery.data?.find(option => option.name === 'invoice' && option.enabled) && <PayByInvoice />}
        </UiStack>
      </BaseTransitionSlideLeft>
      <UiStack height={96} />
    </RegisterLayout>
  );
};

export default Payment;
