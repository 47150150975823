import { type FC, useState, useMemo } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import {
  UiButton,
  UiHStack,
  type UiHStackProps,
  UiStack,
  UiText
} from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';
import { useAttendeeCategoryQuery } from '@/registration/hook/useAttendeeCategoryQuery';
import {
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { registration } from '@/api';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { type TicketData, type TicketSaveRequest } from '@/api/registration';
import { type TicketType } from '@/api/constant';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';

interface FormData {
  name: string
  description: string
  price: number
  stock: number
  validFrom: string
  validTo: string
  code: string
  attendeeCategories: Array<CheckboxOption['value']>
}

export interface TicketFormProps extends UiHStackProps {
  tenantCode: string
  eventId: string
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  ticket?: TicketData
  ticketType: TicketType
}

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.'),
  description: Yup.string()
    .max(100, 'Description can not have more than 100 characters.')
    .nullable(),
  price: Yup.number()
    .required('Price is required.'),
  stock: Yup.number()
    .required('Stock is required.'),
  validFrom: Yup.date(),
  validTo: Yup.date(),
  // code: Yup.string()
  //   .required('Code is required.'),
  attendeeCategories: Yup.array().of(Yup.string())
});

const TicketForm: FC<TicketFormProps> = ({
  tenantCode,
  eventId,
  onClose,
  onSaveSuccess,
  isVisible,
  ticket,
  ticketType
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { data = [], isLoading: isLoadingAttendeeCategory } = useAttendeeCategoryQuery(eventId);
  const attendeeCategoryOptions = useMemo(
    () =>
      data.map((attendeeCategory) => ({
        value: `${attendeeCategory.id}`,
        label: attendeeCategory.name
      })),
    [data]
  );

  const { mutate, isLoading } = useMutation<{}, Error, TicketSaveRequest>({
    mutationFn: async (ticketData: TicketSaveRequest) => {
      return await registration.saveTicket(createTenantAdminApiRequest)(ticketData);
    },
    onSuccess: () => {
      onSaveSuccess();
      void queryClient.invalidateQueries({ queryKey: [registration.ticketListQueryKey, { eventId, ticketType }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      onClose();
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the host.']);
    }
  });

  const submitForm = async (values: FormData) => {
    mutate({
      id: ticket?.id,
      eventIds: [eventId],
      name: values.name,
      description: values.description,
      price: values.price,
      stock: values.stock,
      validFrom: values.validFrom,
      validTo: values.validTo,
      code: values.code ?? '',
      attendeeCategoryIds: values.attendeeCategories,
      ticketType
    });
  };

  return (
    <Formik
      initialValues={
        ticket ? {
          ...ticket,
          validFrom: dayjs(ticket.validFrom).format('YYYY-MM-DD'),
          validTo: dayjs(ticket.validTo).format('YYYY-MM-DD'),
          attendeeCategories: ticket.attendeeCategories.map(category => `${category.id}`)
        } : {
          name: '',
          description: '',
          price: 0,
          stock: 0,
          validFrom: '',
          validTo: '',
          code: '',
          attendeeCategories: ['']
        }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      {({ values }) => {
        const descriptionCharacterCount = values.description ? 100 - values.description.length : 100;

        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={'Add ticket'}
            size={'xl'}
            isLoading={isLoading || isLoadingAttendeeCategory}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField
                name={'name'}
                label={'Name'}
              />
              <BaseFormTextareaField
                name={'description'}
                label={'Description'}
                isRequired={false}
                maxLength={100}
                helperText={`100 character limit (${descriptionCharacterCount} characters remaining)`}
              />
              <BaseFormInputField
                name={'price'}
                type={'number'}
                label={'Price (in dollars)'}
                helperText={'Please round up your price into dollars if needed.'}
              />
              <BaseFormInputField
                name={'stock'}
                type={'number'}
                label={'Stock'}
              />
              <UiText color={'gray.600'} variant="caption">
                Tickets will only be visible on dates including and between the Valid From date and Valid To date.
                If either dates are left blank, the ticket will be visible at all times
              </UiText>
              <BaseFormInputField
                name={'validFrom'}
                label={'Valid from'}
                type={'date'}
                helperText="The provided date should be in the event's time zone."
              />
              <BaseFormInputField
                name={'validTo'}
                label={'Valid to'}
                type={'date'}
                helperText="The provided date should be in the event's time zone."
              />
              <BaseFormInputField
                name={'code'}
                label={'Ticket code'}
                type={'text'}
                helperText={'Ticket code for your own reference.'}
                isRequired={false}
              />
              {attendeeCategoryOptions.length > 0 ? (
                <BaseFormCheckboxListField
                  name={'attendeeCategories'}
                  label={'Visible for attendee categories'}
                  helperText={'Add the attendee categories that can see this ticket.'}
                  options={attendeeCategoryOptions}
                />
              ) : (
                <UiHStack justifyContent={'space-between'} alignItems={'flex-start'}>
                  <UiHStack>
                    <UiText>Attendee categories</UiText>
                    <UiText color={'gray.600'} variant='title'>*</UiText>
                  </UiHStack>
                  <UiStack alignItems={'flex-end'}>
                    <UiText color={'red.500'}>Please create attendee categories first.</UiText>
                    <UiStack>
                      <BaseRouterLink to={generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId })} relative={'route'}>
                        <UiButton variant={'outline'}>Manage attendee categories</UiButton>
                      </BaseRouterLink>
                    </UiStack>
                  </UiStack>
                </UiHStack>
              )}
            </BaseFormFieldGroup>
          </BaseFormDrawer>);
      }}
    </Formik>
  );
};

export default TicketForm;
