import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { UiButton, UiHStack, UiStack, UiText } from '@/lib/ui';
import TicketList from '../MainTickets/TicketList';
import { TicketType } from '@/api/constant';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import QueryContainer, { CenteredSpinner } from '@/base/QueryContainer/QueryContainer';

const WorkshopTickets: FC = () => {
  const { registrationQuery } = useRegistrationAuth();
  const { toNextStep, toPreviousStep } = useRegisterSteps();

  return (
    <RegisterLayout>
      <QueryContainer query={registrationQuery} loadingComponent={<CenteredSpinner />}>
        {(registration) => (
          <>
            <BaseTransitionSlideLeft>
              <UiStack flexGrow={1}>
                <UiStack pb={4}>
                  <UiText variant={'h6'}>Workshop tickets</UiText>
                </UiStack>
                <TicketList ticketType={TicketType.Workshop} onEmpty={toNextStep} registration={registration} />
              </UiStack>
            </BaseTransitionSlideLeft>
            <UiStack height={96} />
            <LayoutFooter>
              <UiHStack justifyContent={'flex-end'} flexGrow={1}>
                <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
                  Previous
                </UiButton>
                <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={toNextStep}>
                  Next
                </UiButton>
              </UiHStack>
            </LayoutFooter>
          </>
        )}
      </QueryContainer>
    </RegisterLayout>
  );
};

export default WorkshopTickets;
