import { useCallback, useState, type FC } from 'react';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import { UiButton, UiHStack, UiIconSignOut, UiIconUser, UiStack, uiStyles, UiText } from '@/lib/ui';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { type RegistrationWithToken, verifyUser, type VerifyUserRequest, type VerifyUserResponse } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useNavigate } from 'react-router-dom';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { generatePageUrl } from '@/app/pages';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormInputField from '@/base/Form/InputField';

const formSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Email is required')
});

interface EmailLayoutProps {
  onShowPasscode: (email: string) => void
}

const EmailLayout: FC<EmailLayoutProps> = ({ onShowPasscode }) => {
  const { userSession, setUserSession, logOut } = useRegistrationAuth();
  const { createTenantApiRequest } = useTenantApi();
  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();
  const { tenantCode, eventId } = useRegisterRoute();

  const { toNextStep } = useRegisterSteps();

  const { mutateAsync: verifyUserMutate, isLoading: isVerifying } = useMutation<ApiResponseSingle<VerifyUserResponse>, Error, VerifyUserRequest>({
    mutationFn: async (data: VerifyUserRequest) => await verifyUser(createTenantApiRequest)(data),
    onSuccess: (data: ApiResponseSingle<VerifyUserResponse>) => {
      if (data.errors && data.errors.length > 0) {
        return setErrors(data.errors);
      }
    }
  });

  const onVerifyUser = useCallback(async (email: string) => {
    setUserSession(undefined);
    const data = await verifyUserMutate({ email, eventId });
    if (data.item.existingUser) {
      onShowPasscode(email);
    } else {
      const registrationResponse = data.item.registration as RegistrationWithToken;
      setUserSession({ email: registrationResponse.email, client: registrationResponse.auth.client, authToken: registrationResponse.auth.token });
      navigate(generatePageUrl('RegistrationRegisterCategory', { tenantCode, eventId }));
    }
  }, [eventId, navigate, onShowPasscode, setUserSession, tenantCode, verifyUserMutate]);

  const onSubmit = useCallback(async (values: { email: string }) => {
    if (userSession?.email) {
      toNextStep();
    } else {
      await onVerifyUser(values.email);
    }
  }, [onVerifyUser, toNextStep, userSession?.email]);

  return (
    <Formik<{ email: string }>
      initialValues={{ email: userSession?.email ?? '' }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <UiStack>
          <BaseTransitionSlideLeft key="email">
            {userSession?.email ? (
              <UiStack flexGrow={1}>
                <UiHStack
                  bgColor={'#fff'}
                  borderRadius={uiStyles.borderRadius}
                  spacing={0}
                  p={0}
                >
                  <UiStack
                    p={6}
                    borderLeftRadius={uiStyles.borderRadius}
                    borderRightWidth={'1px'}
                    borderRightColor={'gray.200'}
                    borderRightStyle={'solid'}
                  >
                    <UiIconUser size={'2xl'} color={'primary.500'} />
                  </UiStack>
                  <UiHStack p={6} spacing={4} flexGrow={1}>
                    <UiText color={'text.secondary'} flexGrow={1}>
                      {userSession?.email}
                    </UiText>
                    <UiStack {...uiStyles.hover} onClick={logOut}>
                      <UiIconSignOut color={'primary.500'} size={'2xl'} />
                    </UiStack>
                  </UiHStack>
                </UiHStack>
              </UiStack>
            ) : (
              <UiStack spacing={4} flexGrow={1} pt={8}>
                {errors.length > 0 && (
                  <UiStack spacing={4} flexGrow={1} py={4}>
                    {errors.map((error) => (
                      <BaseMessageBarError key={error}>
                        {error}
                      </BaseMessageBarError>
                    ))}
                  </UiStack>
                )}
                <BaseFormInputField
                  name={'email'}
                  label={'Email'}
                  isRequired={true}
                  layout={'stack'}
                />
              </UiStack>
            )}
          </BaseTransitionSlideLeft>
          <UiStack height={48} />
          <LayoutFooter>
            <UiHStack justifyContent={'flex-end'} flexGrow={1}>
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type="submit" isLoading={isVerifying}>
                Next
              </UiButton>
            </UiHStack>
          </LayoutFooter>
        </UiStack>
      </Form>
    </Formik>
  );
};

export default EmailLayout;
