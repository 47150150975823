import { type Substate } from '@/api/registration';
import { Badge, Box, Collapse, Flex, Grid, Heading, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface AttendeeCategories {
  name?: string
  description?: string
  is_visible?: boolean | string
  limit?: number | string
}

interface Discounts {
  name?: string
  description?: string
  discount_code?: string
  start_date?: string
  end_date?: string
  active?: boolean | string
  id_photocopy?: 'required' | 'optional' | 'not_required' | string
}

interface InfoForms {
  form_type?: 'personal' | 'additional' | string
}

interface DelegateTypes {
  name?: string
  description?: string
  price?: number | string
  stock?: number | string
  valid_from?: string
  valid_to?: string
  code?: string
}

interface SessionsPricings {
  name?: string
  price?: number | string
  ticket_type?: string
  start_date?: string
  end_date?: string
}

export interface EventSchema {
  attendee_categories?: AttendeeCategories[]
  discounts?: Discounts[]
  info_forms?: InfoForms[]
  delegate_types?: DelegateTypes[]
  sessions_pricings?: SessionsPricings[]
}

interface EventDataVisualizerProps {
  eventData: EventSchema
  substate: Substate
}

const defaultAttendeeCategories: AttendeeCategories = {
  name: 'Missing',
  description: 'Missing',
  is_visible: 'Missing',
  limit: 'Missing'
};

const defaultDiscounts: Discounts = {
  name: 'Missing',
  description: 'Missing',
  discount_code: 'Missing',
  start_date: 'Missing',
  end_date: 'Missing',
  active: 'Missing',
  id_photocopy: 'Missing'
};

const defaultInfoForms: InfoForms = {
  form_type: 'Missing'
};

const defaultDelegateTypes: DelegateTypes = {
  name: 'Missing',
  description: 'Missing',
  price: 'Missing',
  stock: 'Missing',
  valid_from: 'Missing',
  valid_to: 'Missing',
  code: 'Missing'
};

const defaultSessionsPricings: SessionsPricings = {
  name: 'Missing',
  price: 'Missing',
  ticket_type: 'Missing',
  start_date: 'Missing',
  end_date: 'Missing'
};

type EventDataTypes = AttendeeCategories | Discounts | InfoForms | DelegateTypes | SessionsPricings;

interface EventCardProps {
  keyName: string
  value: EventDataTypes[]
  renderData: (itemData: EventDataTypes[], key: string) => JSX.Element[]
  isExpanded: boolean
}

const EventCard: React.FC<EventCardProps> = ({ keyName, value, renderData, isExpanded }) => {
  const { onOpen, onClose, isOpen, onToggle } = useDisclosure();

  React.useEffect(() => {
    if (isExpanded) {
      onOpen();
    } else {
      onClose();
    }
  }, [isExpanded, onClose, onOpen]);

  return (
    <Box key={keyName} my={4}>
      <Flex justify="space-between" align="center">
        <Heading
          size="lg"
          textTransform="capitalize"
          color={isExpanded ? 'teal.500' : 'gray.500'}
        >
          {keyName.replace('_', ' ')}
        </Heading>
        <Badge colorScheme="teal" fontSize="0.8em">{value.length}</Badge>
      </Flex>
      <Collapse in={isOpen}>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6}>
          {Array.isArray(value)
            ? renderData(value, keyName)
            : renderData([value], keyName)}
        </Grid>
      </Collapse>
    </Box>
  );
};

const EventDataVisualizer: React.FC<EventDataVisualizerProps> = ({ eventData, substate }) => {
  const { isOpen, onToggle } = useDisclosure();

  const renderData = (itemData: EventDataTypes[], key: string) => {
    return itemData.map((data, index) => {
      let defaultData;
      switch (key) {
        case 'attendee_categories':
          defaultData = defaultAttendeeCategories;
          break;
        case 'discounts':
          defaultData = defaultDiscounts;
          break;
        case 'info_forms':
          defaultData = defaultInfoForms;
          break;
        case 'delegate_types':
          defaultData = defaultDelegateTypes;
          break;
        case 'sessions_pricings':
          defaultData = defaultSessionsPricings;
          break;
        default:
          defaultData = {};
      }

      const dataToRender: Record<string, any> = { ...defaultData };
      Object.keys(defaultData).forEach((defaultKey) => {
        if ((data as any)[defaultKey] !== undefined) {
          dataToRender[defaultKey] = (data as any)[defaultKey];
        }
      });

      return (
        <motion.div
          key={`${key}[${index}]`}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
        >
          <Box p={4}
            borderWidth={1}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="lg"
          >
            {dataToRender ? (
              typeof dataToRender === 'object' && dataToRender !== null
                ? Object.entries(dataToRender).map(([innerKey, value]) => (
                  <Text key={innerKey} textTransform="capitalize">
                    {innerKey.replace('_', ' ')}: {value !== null && value !== undefined ? String(value) : <Badge colorScheme="red">Missing</Badge>}
                  </Text>
                ))
                : <Text>{String(dataToRender)}</Text>
            ) : (
              <Text>Missing</Text>
            )}
          </Box>
        </motion.div>
      );
    });
  };

  const allEventDataTypesKeys = ['attendee_categories', 'discounts', 'info_forms', 'delegate_types', 'sessions_pricings'];

  return (
    <Box minH="50vh" maxH="50vh" overflowY="auto" overflowX="hidden" p={4} bg="gray.50">
      <AnimatePresence>
        {allEventDataTypesKeys.map((key) => {
        // Use the key from eventData if it exists, otherwise use an empty array
          const value = Array.isArray(eventData[key as keyof EventSchema]) ? eventData[key as keyof EventSchema] : [];
          return (
            <EventCard key={key} keyName={key} value={value ?? []} renderData={renderData} isExpanded={key === substate} />
          );
        })}
      </AnimatePresence>
    </Box>
  );
};

export default EventDataVisualizer;
