import { useCallback, type FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiIconPencilSimple, UiStack, UiText } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import { loadRegistrationInfoFormResponse, type InfoFormResponse, InfoFormType, type InfoForm, type InfoFormByAttendeeCategoryQuery, infoFormByAttendeeCategoryQueryKey, loadInfoFormByAttendeeCategory, infoFormResponseQueryKey } from '@/api/registration';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import InformationList from './InformationList';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';

const PersonalInfoReview: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { tenantCode, eventId } = useRegisterRoute();
  const { registrationQuery } = useRegistrationAuth();
  const registration = registrationQuery?.data;
  const { createTenantApiRequest } = useTenantApi();

  const infoFormByAttendeeCategoryQuery = useQuery<InfoForm, Error>(
    [infoFormByAttendeeCategoryQueryKey, { registrationId: registration?.id, formType: InfoFormType.PERSONAL }],
    async () => await fetchInfoFormByAttendeeCategory(registration?.id),
    {
      enabled: !!registration
    }
  );

  async function fetchInfoFormByAttendeeCategory(registrationId: number | undefined): Promise<InfoForm> {
    if (typeof registrationId === 'undefined') {
      return await Promise.reject(new Error('Invalid registrationId'));
    }

    const params: InfoFormByAttendeeCategoryQuery = { formType: InfoFormType.PERSONAL, registrationId };
    const result = await loadInfoFormByAttendeeCategory(createTenantApiRequest)(params);

    return {
      ...result.item,
      config: JSON.parse(result.item.config) as InfoForm['config']
    };
  }

  const personalInfoQuery = useQuery<InfoFormResponse, Error>(
    [infoFormResponseQueryKey, { registrationId: registration?.id, type: InfoFormType.PERSONAL }],
    async () => await fetchRegistrationInfoFormResponse(registration?.id),
    {
      enabled: !!registration
    }
  );

  async function fetchRegistrationInfoFormResponse(registrationId: number | undefined): Promise<InfoFormResponse> {
    if (typeof registrationId === 'undefined') {
      return await Promise.reject(new Error('Invalid registrationId'));
    }

    const response = await loadRegistrationInfoFormResponse(createTenantApiRequest)({
      registrationId,
      type: InfoFormType.PERSONAL
    });

    return response.item;
  }

  const toModify = useCallback(
    () => {
      navigate(generatePageUrl(
        'RegistrationRegisterPersonalInfo',
        { tenantCode, eventId },
        { ...Object.fromEntries(searchParams), review: true }
      ));
    },
    [eventId, navigate, searchParams, tenantCode]
  );

  return (
    <UiStack>
      <UiHStack justifyContent={'space-between'}>
        <UiText variant={'title'}>
          Personal information
        </UiText>
        <UiButton
          rightIcon={(<UiIconPencilSimple color={'primary.500'} size={'xl'} weight={'bold'} />)}
          variant={'ghost'}
          colorScheme={'primary'}
          fontSize={'md'}
          fontWeight={'bold'}
          onClick={toModify}
        >
          Modify
        </UiButton>
      </UiHStack>
      <QueryContainer query={infoFormByAttendeeCategoryQuery}>
        {(infoForm) => (
          <QueryContainer query={personalInfoQuery}>
            {(personalInfoForm) => (
              <InformationList
                fieldsMetadata={infoForm.config.fieldsMetadata}
                response={personalInfoForm.response}
              />
            )}
          </QueryContainer>
        )}
      </QueryContainer>
    </UiStack>
  );
};

export default PersonalInfoReview;
