import { type FC, type PropsWithChildren, createContext, useContext, useMemo, useState, useCallback, Children } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useMutation, useQuery, type UseQueryResult } from '@tanstack/react-query';
import { loadRegistration, login as loginAPI, type LoginRequest, registrationQueryKey, type RegistrationWithToken, type Registration } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { CenteredSpinner } from '@/base/QueryContainer/QueryContainer';
import { useSearchParams } from 'react-router-dom';

export interface UserSession {
  email: string
  client: string
  authToken: string
}

interface AuthContextValue {
  userSession?: UserSession
  setUserSession: (userSession: UserSession | undefined) => void
  registrationQuery: UseQueryResult<Registration, Error> | undefined
  registration: Registration | undefined
  login: () => Promise<ApiResponseSingle<RegistrationWithToken> | undefined>
  logOut: () => void
}

const AuthContext = createContext<AuthContextValue>({
  userSession: undefined,
  setUserSession: () => {},
  registrationQuery: undefined,
  registration: undefined,
  login: async () => await Promise.resolve(undefined),
  logOut: () => {}
});

export const ProviderRegistrationAuth: FC<PropsWithChildren> = ({
  children
}) => {
  const [userSession, setUserSession] = useLocalStorage<UserSession | undefined>('userSession');
  const { createTenantApiRequestWithSession, isLoading: isApiPreparing } = useTenantApi();
  const { eventId } = useRegisterRoute();
  const { createTenantApiRequest } = useTenantApi();
  const [searchParams] = useSearchParams();

  const { mutateAsync, isLoading: loginLoading } = useMutation<ApiResponseSingle<RegistrationWithToken>, Error, LoginRequest>({
    mutationFn: async (data: LoginRequest) => {
      return await loginAPI(createTenantApiRequest)(data);
    },
    onSuccess: (data: ApiResponseSingle<RegistrationWithToken>) => {
      if (data.item?.id) {
        setUserSession({ email: data.item.email, client: data.item.auth.client, authToken: data.item.auth.token });
      } else if (data.errors) {
        logOut();
      }
    },
    onError: (error: any) => {
      logOut();
      throw error;
    }
  });

  const registrationQuery = useQuery<Registration, Error>({
    queryKey: [registrationQueryKey, { email: userSession?.email }, { token: userSession?.authToken }],
    queryFn: async () => {
      if (userSession?.email === undefined) {
        throw new Error('email is undefined');
      }
      const response = await loadRegistration(createTenantApiRequestWithSession(userSession))({ email: userSession.email, eventId });
      if (response.errors && response.errors.length > 0) {
        throw new Error(response.errors.join(', '));
      }
      if (!response.item) {
        throw new Error('No item in response');
      }
      return response.item;
    },
    enabled: !!userSession?.email && !isApiPreparing && !!eventId && !loginLoading
  });

  const login = useCallback(async (): Promise<ApiResponseSingle<RegistrationWithToken> | undefined> => {
    const authToken = searchParams.get('token');
    const email = searchParams.get('uid');
    const client = searchParams.get('client');
    if (authToken && email && client) {
      setUserSession({ email, client, authToken });
    }
    if (userSession) {
      return await mutateAsync({ uid: userSession.email, client: userSession.client, token: userSession.authToken, eventId });
    }
    return undefined;
  }, [eventId, mutateAsync, userSession, searchParams, setUserSession]);

  const logOut = useCallback(() => {
    setUserSession(undefined);
  }, []);

  const value: AuthContextValue = useMemo(() => ({
    userSession,
    setUserSession,
    registrationQuery,
    registration: registrationQuery?.data,
    login,
    logOut
  }), [userSession, setUserSession, registrationQuery, login, logOut]);

  if (!registrationQuery) return <CenteredSpinner />;
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export function useRegistrationAuth() {
  const { userSession, setUserSession, registrationQuery, registration, login, logOut } = useContext(AuthContext);
  return {
    userSession,
    setUserSession,
    registrationQuery: registrationQuery!,
    registration,
    login,
    logOut
  };
}
