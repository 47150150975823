import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import PersonalInfoReview from './PersonalInfoReview';
import AdditionalInfoReview from './AdditionalInfoReview';
import ShoppingCartReview from './ShoppingCartReview';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { UiButton, UiHStack, UiStack, UiText } from '@/lib/ui';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import { type RegistrationCompleteRequest, RegistrationStrategyType, type Registration, completeRegistration } from '@/api/registration';
import { useMutation } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponseSingle } from '@/api/tenantClient';
import OrderReview from './OrderReview';

const Review: FC = () => {
  const { registration } = useRegistrationAuth();
  const { toNextStep, toPreviousStep } = useRegisterSteps();
  const { createTenantApiRequest } = useTenantApi();

  const { mutate } = useMutation<ApiResponseSingle<Registration>, Error, RegistrationCompleteRequest>(
    {
      mutationFn: async (data: RegistrationCompleteRequest) => {
        return await completeRegistration(createTenantApiRequest)(data);
      },
      onSuccess: (data: ApiResponseSingle<Registration>) => {
        if (data.item) {
          toNextStep();
        } else {
          throw new Error('Failed to complete registration');
        }
      },
      onError: (error: any) => {
        throw error;
      }
    }
  );

  const handleCompleteRegistration = () => {
    if (registration) {
      mutate({ registrationId: registration.id });
    }
  };

  return (
    <RegisterLayout>
      <BaseTransitionSlideLeft>
        <UiStack flexGrow={1} spacing={6} enableDivider={true} pb={16}>
          <UiStack>
            <UiText variant={'h6'}>Registration review</UiText>
          </UiStack>
          {/* <BaseDividerHorizontal height={{base: 4, lg: 6}} color={'gray.300'}/> */}
          <PersonalInfoReview />
          {registration?.registrationSteps?.find(value => value.routeName === 'RegistrationRegisterAdditionalInfo') && <AdditionalInfoReview />}
          {registration?.registrationStrategy === RegistrationStrategyType.FORMS_FIRST && <ShoppingCartReview />}
          {registration?.registrationStrategy === RegistrationStrategyType.PAYMENT_FIRST && <OrderReview />}
        </UiStack>
      </BaseTransitionSlideLeft>
      <UiStack height={96} />
      <LayoutFooter>
        <UiHStack justifyContent={'flex-end'} flexGrow={1}>
          <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
            Previous
          </UiButton>
          {registration?.registrationStrategy === RegistrationStrategyType.FORMS_FIRST && (
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={toNextStep}>
            Billing Details
            </UiButton>
          )}
          {registration?.registrationStrategy === RegistrationStrategyType.PAYMENT_FIRST && (
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={handleCompleteRegistration}>
            Complete registration
            </UiButton>
          )}
        </UiHStack>
      </LayoutFooter>
    </RegisterLayout>
  );
};

export default Review;
