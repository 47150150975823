import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  uiStyles,
  UiIconInfo
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import NewCode from './NewCode';
import CodeList from './CodeList';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';

const DiscountCodes: FC = () => {
  const { eventId } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'discountCode', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      // mutate,
      // eventId,
      toNextStep
    ]
  );

  // Mark the step as set regardless.
  useEffect(
    () => {
      return () => {
        if (eventSettings && !eventSettings.discountCode.isSet) {
          mutate({ eventId, key: 'discountCode', bool: true });
        }
      };
    },
    [eventSettings?.discountCode.isSet]
  );

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        // disableTopNav={true}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
        MainTransition={null}
      >
        <ManageEventLayoutBody
          steps={(
            <ManageEventSteps
              eventId={eventId}
              activeStep={'discountCodes'}
            />
          )}
          message={'Please design your badge.'}
        >
          <UiStack
            // pt={4}
            spacing={4}
            flexGrow={1}
            // pt={4}
            p={8}
          >
            <UiHStack>
              <StepInstruction label={'Instructions'}>
                <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                  <UiText>
                    Discount codes are utilised to set up reduced rates for selected ticket categories. To qualify for the discount, specific and well-defined conditions or criteria must be met. Examples of discount codes include Early Bird discount, Member discount, Student discount, Exhibitor discount, Developing Country discount and more.
                  </UiText>
                  <UiText color={'red.600'}>
                    You only need to create all the discount codes here and you will be able to set the discount rules later when creating tickets.
                  </UiText>
                </UiStack>
              </StepInstruction>
            </UiHStack>
            <BaseDividerHorizontal height={4} />
            <NewCode />
            <CodeList />
          </UiStack>
          <UiHStack
            // pt={8}
            p={8}
            justifyContent={'space-between'}
            // flexDirection={'row-reverse'}
          >
            <UiStack flexGrow={1} />
            <UiButton
              size={'lg'}
              colorScheme={'primary'}
              type={'submit'}
              fontSize={'lg'}
              onClick={onNext}
              isLoading={isLoading}>
              Next
            </UiButton>
          </UiHStack>
        </ManageEventLayoutBody>
      </BaseLayout>
    </>
  );
};

export default DiscountCodes;
