import { type FC, useMemo } from 'react';
import BaseLayout from '@/base/Layout';
import { useParams } from 'react-router-dom';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiStack } from '@/lib/ui';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import type { BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import { generatePageUrl } from '@/app/pages';
import { type ManageRouteParams } from '@/registration/pages';
import { useQuery } from '@tanstack/react-query';
import { loadRegistrationById, registrationIdQueryKey, type RegistrationInfo } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import RegistrationSummary from './RegistrationSummary';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import RegistrationDiscount from './RegistrationDiscount';
import RegistrationFormResponse from './RegistrationFormResponse';

const RegistrationManageInfoPage: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const { createTenantAdminApiRequest } = useTenantApi();
  const params = useParams<ManageRouteParams['RegistrationManageInfo']>();
  const registrationId = useMemo<number | null>(
    () => params.registrationId ? parseInt(params.registrationId, 10) : null,
    [params.registrationId]
  );
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Manager' },
        { label: 'Registration', url: generatePageUrl('RegistrationManageOrder', { tenantCode }) },
        /**
         * @todo orderId here should be the order serial number, not the database table row id.
         */
        { label: `Registration information (${registrationId ?? ''})` }
      ];
    },
    [registrationId, tenantCode]
  );

  const registrationQuery = useQuery<RegistrationInfo, Error>({
    queryKey: [registrationIdQueryKey, { id: registrationId }],
    queryFn: async (): Promise<RegistrationInfo> => {
      const response = await loadRegistrationById(createTenantAdminApiRequest)({ id: registrationId! });
      return response.item;
    },
    enabled: !!registrationId
  });

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={null}
      MainTransition={null}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BreadcrumbBody
        breadcrumbNodes={breadcrumbNodes}
      >
        <QueryContainer query={registrationQuery}>
          {(registration) => (
            <UiStack p={8} spacing={8}>
              <RegistrationSummary registration={registration} />
              {registration.discount && <RegistrationDiscount registration={registration} />}
              {registration.personalFormResponses &&
                 <RegistrationFormResponse
                   title="Personal Info"
                   infoFormResponse={registration.personalFormResponses} />}
              {registration.additionalFormReponses &&
                  <RegistrationFormResponse
                    title="Additional Info"
                    infoFormResponse={registration.additionalFormReponses} />}
              <UiHStack>
                <UiButton px={8} size={'lg'} colorScheme={'primary'}>
                  Request Details Change
                </UiButton>
              </UiHStack>
            </UiStack>
          )}
        </QueryContainer>
      </BreadcrumbBody>
    </BaseLayout>
  );
};

export default RegistrationManageInfoPage;
