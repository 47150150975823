/* eslint-disable @typescript-eslint/no-shadow */
import { type FC, useState } from 'react';
import {
  UiHStack,
  UiStack,
  uiStyles
} from '@/lib/ui';
import LoginEmailForm from './LoginEmailForm';
import LoginSupportSmartButton from '@/account/component/Layout/LoginSupportSmartButton';
import { type account } from '@/api';
import LoginPasswordForm from './LoginPasswordForm';
import LoginSmartWindow from '@/account/component/Layout/LoginSmartWindow';
import BaseLayout from '@/base/Layout';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseLayoutBody from '@/base/Layout/Body';
import LoginBody from '@/account/component/Layout/LoginBody';

const LoginEmail: FC = () => {
  const [adminUser, setAminUser] = useState<account.AdminUserData | null>(null);

  const onAdminUserLoaded = (adminUser: account.AdminUserData) => {
    setAminUser(adminUser);
  };

  return (
    <BaseLayout
      smartWindow={(<LoginSmartWindow />)}
      sideMenu={null}
      smartButton={(<LoginSupportSmartButton />)}
      appSwitch={(
        <UiStack>
          {/*<BaseAppAccountCard />*/}
        </UiStack>
      )}
    >
      <LoginBody>
        <UiStack
          // breadcrumbNodes={breadcrumbNodes}
          spacing={4}
          alignItems={'stretch'}
          flexGrow={1}
        >
          <UiHStack
            flexGrow={1}
            alignItems={'stretch'}
            borderRadius={uiStyles.borderRadius}
            spacing={0}
          >
            {/* <UiStack */}
            {/*  borderLeftRadius={uiStyles.borderRadius} */}
            {/*  spacing={0} */}
            {/*  bgColor={'primary.400'} */}
            {/*  bgImg={'/image/bg/login-bg.jpg'} */}
            {/*  bgSize={'cover'} */}
            {/*  bgPos={'center'} */}
            {/*  width={'40%'} */}
            {/* > */}
            {/* </UiStack> */}
            <UiStack
              alignItems={'center'}
              justifyContent={'center'}
              flexGrow={1}
              borderRightRadius={uiStyles.borderRadius}
              spacing={4}
            >
              {/* <UiText
              variant={'h5'}
            >
              Admin login
            </UiText> */}
              {!adminUser?.id ? (
                <LoginEmailForm
                  onAdminUserLoaded={onAdminUserLoaded}
                />
              ) : (
                <LoginPasswordForm
                  onLoginSuccess={() => {}}
                  adminUser={adminUser}
                  changeEmail={() => setAminUser(null)}
                />
              )}
            </UiStack>
          </UiHStack>
        </UiStack>
      </LoginBody>
    </BaseLayout>
  );

  // return (
  //   <LoginLayout
  //     smartWindow={<LoginSmartWindow/>}
  //     smartButton={<LoginSupportSmartButton/>}
  //   >
  //     <UiStack
  //       // breadcrumbNodes={breadcrumbNodes}
  //       spacing={4}
  //       alignItems={'stretch'}
  //       flexGrow={1}
  //     >
  //       <UiHStack
  //         flexGrow={1}
  //         alignItems={'stretch'}
  //         borderRadius={uiStyles.borderRadius}
  //         spacing={0}
  //       >
  //         <UiStack
  //           borderLeftRadius={uiStyles.borderRadius}
  //           spacing={0}
  //           // alignItems={'center'}
  //           // p={8}
  //           // justifyContent={'center'}
  //           bgColor={'primary.400'}
  //           bgImg={'/image/bg/login-bg.jpg'}
  //           bgSize={'cover'}
  //           bgPos={'center'}
  //           // minW={'600px'}
  //           // maxW={'800px'}
  //           width={'40%'}
  //         >
  //           {/* <UiImage
  //             src={'/image/bg/login-bg.jpg'}
  //             borderLeftRadius={uiStyles.borderRadius}
  //             minH={innerHeight - 160}
  //             maxH={innerHeight - 160}
  //           /> */}
  //         </UiStack>
  //         <UiStack
  //           alignItems={'center'}
  //           justifyContent={'center'}
  //           flexGrow={1}
  //           bgColor={'gray.100'}
  //           borderRightRadius={uiStyles.borderRadius}
  //           spacing={4}
  //         >
  //           {/* <UiText
  //             variant={'h5'}
  //           >
  //             Admin login
  //           </UiText> */}
  //           {!adminUser?.id ? (
  //             <LoginEmailForm
  //               onAdminUserLoaded={onAdminUserLoaded}
  //             />
  //           ) : (
  //             <LoginPasswordForm
  //               onLoginSuccess={() => {}}
  //               adminUser={adminUser}
  //               changeEmail={() => setAminUser(null)}
  //             />
  //           )}
  //         </UiStack>
  //       </UiHStack>
  //     </UiStack>
  //   </LoginLayout>
  // );
};

export default LoginEmail;
