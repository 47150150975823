import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

interface RouteParams extends Record<string, string | undefined> {
  tenantCode: string
  eventId: string | undefined
}

export function useAttendeeRoute() {
  const params = useParams<RouteParams>();
  const eventId = useMemo<string>(
    () => params.eventId ?? '0',
    [params.eventId]
  );
  return {
    ...params,
    tenantCode: params.tenantCode ?? 'dev',
    eventId
  };
}
