import { type FC } from 'react';
import { Formik, Form, type FormikHelpers } from 'formik';
import {
  UiStack,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiText,
  uiStyles,
  UiButton, UiHStack
} from '@/lib/ui';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';

export interface OrderStatusDrawerProps {
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
}

export interface FormData {
  status: string
}

const options: CheckboxOption[] = [
  { label: 'Waiting for payment', value: '1' },
  { label: 'Paid', value: '2' },
  { label: 'Payment failed', value: '3' },
  { label: 'Order cancelled', value: '4' }
];

const OrderStatusDrawer: FC<OrderStatusDrawerProps> = ({
  isOpen,
  onClose
}) => {
  return (
    <UiDrawer
      placement={'right'}
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerBody p={8} py={16} bgColor={'gray.100'}>
          <Formik<FormData>
            initialValues={{
              status: '2'
            }}
            validateOnChange={false}
            validateOnBlur={false}
            // validationSchema={formSchema}
            onSubmit={(values: FormData) => {
            }}
          >
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              <UiStack flexGrow={1} spacing={8}>
                <BaseFormCheckboxListField
                  label={'Order status'}
                  name={'status'}
                  options={options}
                  isMultiple={false}
                  layout={'stack'}
                  // helperText={'@todo This field might be hidden since admin users can choose to send attendee category specific urls to delegates.'}
                />
                <UiHStack justifyContent={'flex-end'}>
                  <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={false}>
                    Save
                  </UiButton>
                </UiHStack>
              </UiStack>
            </Form>
          </Formik>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default OrderStatusDrawer;
