import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps } from '@/lib/ui';
import PageRow from '@/registration/buildEvent/OtherSettings/LabelsDescriptions/PageRow';
import PersonalInformationPageForm from './PersonalInformationPageForm';

export interface ManageLabelsProps extends UiHStackProps {
}

const PersonalInformationPage: FC<ManageLabelsProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <PageRow
        title={'Personal information page'}
        description={'Manage labels and descriptions on the page where we ask the attendees to fill the personal information form.'}
        onEdit={onToggle}
      />
      <PersonalInformationPageForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default PersonalInformationPage;
