import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import AddressForm from './AddressForm';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import { useQuery } from '@tanstack/react-query';
import { addressQueryKeyBoth, loadBothAddresses } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import QueryContainer from '@/base/QueryContainer/QueryContainer';

const Checkout: FC = () => {
  const { createTenantApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { registrationQuery } = useRegistrationAuth();

  const bothAddressQuery = useQuery(
    [addressQueryKeyBoth],
    async () => await fetchBothAddresses(registrationQuery?.data?.id),
    {
      enabled: !isApiPreparing && !!registrationQuery?.data
    }
  );

  async function fetchBothAddresses(registrationId: number | undefined): Promise<any> {
    if (typeof registrationId === 'undefined') {
      return await Promise.reject(new Error('Invalid registrationId'));
    }

    const response = await loadBothAddresses(createTenantApiRequest)({ registrationId });
    return response.item;
  }

  return (
    <RegisterLayout>
      <QueryContainer query={bothAddressQuery}>
        {(addresses) => <AddressForm existingAddresses={addresses} />}
      </QueryContainer>
    </RegisterLayout>
  );
};

export default Checkout;
