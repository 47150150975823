import { type FC, useEffect, useRef, useState } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import EmailLayout from './EmailLayout';
import LoginDrawer from './PasscodeDrawer';

const EmailPage: FC = () => {
  const { userSession, login } = useRegistrationAuth();
  const loginAttemptedRef = useRef(false);
  const [email, setEmail] = useState<string>();

  useEffect(() => {
    if (!loginAttemptedRef.current && userSession) {
      loginAttemptedRef.current = true;
      void login();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RegisterLayout header={null}>
      <EmailLayout
        onShowPasscode={setEmail}
      />
      <LoginDrawer
        isOpen={!!email}
        email={email}
        onClose={() => setEmail(undefined)}
      />
    </RegisterLayout>
  );
};

export default EmailPage;
