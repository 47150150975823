/* eslint-disable react/no-unescaped-entities */
import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { UiSpinner, UiStack, UiText, UiVStack, uiStyles } from '@/lib/ui';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import RegistrationDetails from './RegistrationDetails';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { AbsoluteCenter } from '@chakra-ui/react';
import { isError } from '@/lib/util';
import { type Order } from '@/api/registration';

const PAID = 'Paid';

interface CreateCreditCardResultProps {
  data: ApiResponseSingle<Order> | undefined
  isLoading: boolean
  error: any
  fetchError: any
}

const CreditCardResult: FC<CreateCreditCardResultProps> = ({ data, isLoading, error, fetchError }) => {
  if ((isLoading || !data || data?.item?.status !== PAID) && !isError(data?.errors) && !error && !fetchError) {
    return (
      <RegisterLayout>
        <AbsoluteCenter>
          <UiVStack spacing={4}>
            <UiSpinner />
            <UiText variant={'h6'}>We're completing your order, please wait</UiText>
          </UiVStack>
        </AbsoluteCenter>
      </RegisterLayout>
    );
  }

  return (
    <RegisterLayout>
      <BaseTransitionSlideLeft>
        <UiStack flexGrow={1} spacing={4}>
          <UiStack>
            {(isError(data?.errors) || error || fetchError) && <UiText variant={'h6'}>Registration failed</UiText>}
            {data?.item?.status === PAID && (
              <UiText variant={'h6'}>Registration successful</UiText>
            )}
          </UiStack>
          {(isError(data?.errors) || error || fetchError) && (
            <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'red.100'}>
                Something went wrong, please try again later
            </BaseMessageBarInfo>)}
          {data?.item?.status === PAID && (
            <>
              <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'green.100'}>
                  We've sent the receipt with your registration details to your email. It might take several minutes to arrive. Please also check your spam.
              </BaseMessageBarInfo>
              <RegistrationDetails order={data.item} />
            </>
          )}
        </UiStack>
      </BaseTransitionSlideLeft>
      <UiStack height={96} />
    </RegisterLayout>
  );
};

export default CreditCardResult;
