import { useCallback, type FC, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  UiStack,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiText,
  type UiDrawerProps,
  UiImage,
  UiIconCalendarBlank,
  UiHStack,
  UiIconMapPin,
  UiButton,
  UiDrawerFooter, UiIconCaretRight, uiStyles, UiIconHouse
  // UiGrid,
} from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type Event } from '@/api/registration';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useNavigate } from 'react-router-dom';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface EventDetailProps extends Omit<UiDrawerProps, 'children'> {
  event: Event
  isOpen: boolean
}

const EventDetail: FC<EventDetailProps> = ({
  event,
  isOpen,
  ...props
}) => {
  const navigate = useNavigate();
  const { tenantCode } = useRegisterRoute();
  const onNext = useCallback(
    () => event.eventSettings
      ? navigate(generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId: event.id.toString() }))
      : navigate(generatePageUrl('RegistrationPrefillEvent', { tenantCode, eventId: event.id.toString() }))
    , [event.eventSettings, event.id, navigate, tenantCode]);
  const attendeeRegisterLink = useMemo(
    () => generatePageUrl('RegistrationRegisterEmail', { tenantCode, eventId: event.id }),
    [event.id, tenantCode]
  );

  return (
    <UiDrawer placement={'right'} size={'md'} isOpen={isOpen} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        {/* <UiDrawerHeader borderBottomWidth='1px'>Filter events</UiDrawerHeader> */}
        <UiDrawerBody p={0}>
          <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
          <UiStack alignItems={'stretch'} p={8} py={16} spacing={4}>
            <UiHStack>
              <UiImage
                boxSize={16}
                src={event.menuLogo}
              />
              <UiText variant="h6">{event.name}</UiText>
            </UiHStack>
            <UiStack
              bgColor={'#fff'}
              borderRadius={uiStyles.borderRadius}
              enableDivider={true}
              spacing={0}
            >
              {event.startTime && event.endTime && (
                <UiHStack p={6}>
                  <UiIconCalendarBlank size={'3xl'} />
                  <UiText>
                    {dayjs.tz(event.startTime, event.tzInfoName).format('Do MMM YYYY')} - {dayjs.tz(event.endTime, event.tzInfoName).format('Do MMM YYYY')}
                  </UiText>
                </UiHStack>
              )}
              {!!event.location && (
                <UiHStack p={6}>
                  <UiIconMapPin size={'3xl'} />
                  <UiText>{event.location}</UiText>
                </UiHStack>
              )}
              {
                event.venues.length > 0 && (
                  <UiHStack p={6}>
                    <UiIconHouse size={'3xl'} />
                    <UiStack>
                      {event.venues.map((venue) => (venue.name)).filter((venueName) => !!venueName).map((venueName) => (
                        <UiHStack key={venueName}>
                          <UiText>{venueName}</UiText>
                        </UiHStack>
                      ))}
                    </UiStack>
                  </UiHStack>
                )
              }
            </UiStack>
            <BaseDividerHorizontal height={8} />
            <UiStack spacing={4}>
              <BaseRouterLink to={attendeeRegisterLink ?? undefined} target={'_blank'}>
                <UiHStack
                  p={8}
                  py={6}
                  bgColor={'#fff'}
                  borderRadius={uiStyles.borderRadius}
                  {...uiStyles.hover}
                >
                  <UiText flexGrow={1}>Event register link</UiText>
                  <UiIconCaretRight color={'primary.500'} />
                </UiHStack>
              </BaseRouterLink>
            </UiStack>
          </UiStack>
        </UiDrawerBody>
        <UiDrawerFooter p={0}>
          <UiStack flexDirection={'row-reverse'} p={8}>
            <UiButton
              px={8}
              size={'lg'}
              shadow={'base'}
              colorScheme={'primary'}
              onClick={onNext}
            >
              Start
            </UiButton>
          </UiStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default EventDetail;
