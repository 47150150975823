import { type RouteObject } from 'react-router-dom';

import AdminAccess from '@/account/component/AdminAccess';
import RegistrationDashboardOverviewPage from '@/registration/dashboard/Overview';

import RegisterLayout from '@/registration/component/Layout/RegisterLayout';
import BuildEventLayout from '@/registration/component/Layout/BuildEventLayout';
import AttendeeLayout from '@/registration/component/Layout/AttendeeLayout';

import RegistrationManageEventPage from '@/registration/manage/Event';
import RegistrationManageOrderPage from '@/registration/manage/Order';
import RegistrationManageOrderInfoPage from '@/registration/manage/OrderInfo';
import RegistrationPrefillPage from '@/registration/manage/PrefillEvent';
import RegistrationManageTablePage from '@/registration/manage/Registration';
import RegistrationManageInfoPage from '@/registration/manage/RegistrationInfo';

import RegistrationBuildEventEventInfoPage from '@/registration/buildEvent/EventInfo';
import RegistrationBuildEventAttendeeCategoriesPage from '@/registration/buildEvent/AttendeeCategories';
import RegistrationBuildEventPersonalInfoFormPage from '@/registration/buildEvent/PersonalInfoForm';
import RegistrationBuildEventMainEventTicketsPage from '@/registration/buildEvent/MainEventTickets';
import RegistrationBuildEventBadgePage from '@/registration/buildEvent/Badge';
import RegistrationBuildEventAddDiscountCodesPage from '@/registration/buildEvent/DiscountCodes';
import RegistrationBuildEventBuildPersonalInfoFormPage from '@/registration/buildEvent/BuildPersonalInfoForm';
import RegistrationBuildEventFunctionTicketsPage from '@/registration/buildEvent/FunctionTickets';
import RegistrationBuildEventWorkshopTicketsPage from '@/registration/buildEvent/WorkshopTickets';
import RegistrationBuildEventTourTicketsPage from '@/registration/buildEvent/TourTickets';
import RegistrationBuildEventAdditionalInfoFormPage from '@/registration/buildEvent/AdditionalInfoForm';
import RegistrationBuildEventBuildAdditionalInfoFormPage from '@/registration/buildEvent/BuildAdditionalInfoForm';
import RegistrationBuildEventMerchandisePage from '@/registration/buildEvent/Merchandise';
import RegistrationBuildEventCheckoutPage from '@/registration/buildEvent/Checkout';
import RegistrationBuildEventPrivacyPage from '@/registration/buildEvent/Privacy';
import RegistrationBuildEventPaymentMethodsPage from '@/registration/buildEvent/PaymentMethods';
import RegistrationBuildEventGroupRegistrationPage from '@/registration/buildEvent/GroupRegistration';
// import RegistrationBuildEventLabelsDescriptionsPage from '@/registration/buildEvent/LabelsDescriptions';
import RegistrationBuildEventOtherSettingsPage from '@/registration/buildEvent/OtherSettings';

import RegistrationEmailPage from '@/registration/register/Email';
import RegistrationRegisterPersonalInfoPage from '@/registration/register/PersonalInfo';
import RegistrationRegisterCategoryPage from '@/registration/register/Category';
import RegistrationRegisterMainTicketsPage from '@/registration/register/MainTickets';
import RegistrationRegisterFunctionTicketsPage from '@/registration/register/FunctionTickets';
import RegistrationRegisterWorkshopTicketsPage from '@/registration/register/WorkshopTickets';
import RegistrationRegisterTourTicketsPage from '@/registration/register/TourTickets';
import RegistrationRegisterShoppingCartPage from '@/registration/register/ShoppingCart';
import RegistrationRegisterAdditionalInfoPage from '@/registration/register/AdditionalInfo';
import RegistrationRegisterReviewPage from '@/registration/register/Review';
import RegistrationRegisterCheckoutAddressPage from '@/registration/register/CheckoutAddress';
import RegistrationRegisterPaymentPage from '@/registration/register/Payment';
import RegistrationRegisterPaymentResultPage from '@/registration/register/PaymentResult';
import RegistrationRegisterInvoiceResultPage from '@/registration/register/InvoiceResult';
import AIChatSessionWindow from './eventGeneration/AIChatSessionWindow';

import RegistrationAttendeeRegistrationDetails from '@/registration/attendee/RegistrationDetails';

export interface DashboardRouteParams {
  RegistrationDashboardOverview: { tenantCode: string }
}
export type DashboardRouteName = keyof DashboardRouteParams;

export interface ManageRouteParams {
  RegistrationManageEvent: { tenantCode: string }
  RegistrationManageOrder: { tenantCode: string }
  RegistrationManageTable: { tenantCode: string }
  RegistrationManageInfo: { tenantCode: string, registrationId: string }
  RegistrationManageOrderInfo: { tenantCode: string, orderId: string }
  RegistrationPrefillEvent: { tenantCode: string, eventId: string }
}

export type ManageRouteName = keyof ManageRouteParams;

export interface BuildEventRouteParams {
  RegistrationBuildEventEventInfo: { tenantCode: string, eventId: string }
  RegistrationBuildEventGroupRegistration: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventAttendeeCategories: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventPersonalInfoForm: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventBuildPersonalInfoForm: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventDiscountCodes: { tenantCode: string, eventId: string }
  RegistrationBuildEventMainEventTickets: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventFunctionTickets: { tenantCode: string, eventId: string }
  RegistrationBuildEventWorkshopTickets: { tenantCode: string, eventId: string }
  RegistrationBuildEventTourTickets: { tenantCode: string, eventId: string }
  RegistrationBuildEventAdditionalInfoForm: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventBuildAdditionalInfoForm: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventPrivacy: { tenantCode: string, eventId: string }
  RegistrationBuildEventPaymentMethods: { tenantCode: string, eventId: string }
  RegistrationBuildEventBadge: { tenantCode: string, eventId: string }
  RegistrationBuildEventCheckout: { tenantCode: string, eventId: string }
  RegistrationBuildEventMerchandise: { tenantCode: string, eventId: string }
  // RegistrationBuildEventLabelsDescriptions: { tenantCode: string, eventId: string }
  RegistrationBuildEventOtherSettings: { tenantCode: string, eventId: string }
  EventGeneration: { tenantCode: string }
}
export type BuildEventRouteName = keyof BuildEventRouteParams;

export interface RegisterRouteParams {
  RegistrationRegisterEmail: { tenantCode: string, eventId: string }
  RegistrationRegisterPersonalInfo: { tenantCode: string, eventId: string }
  RegistrationRegisterCategory: { tenantCode: string, eventId: string }
  RegistrationRegisterMainTickets: { tenantCode: string, eventId: string }
  RegistrationRegisterFunctionTickets: { tenantCode: string, eventId: string }
  RegistrationRegisterWorkshopTickets: { tenantCode: string, eventId: string }
  RegistrationRegisterTourTickets: { tenantCode: string, eventId: string }
  RegistrationRegisterShoppingCart: { tenantCode: string, eventId: string }
  RegistrationRegisterAdditionalInfo: { tenantCode: string, eventId: string }
  RegistrationRegisterReview: { tenantCode: string, eventId: string }
  RegistrationRegisterCheckoutAddress: { tenantCode: string, eventId: string }
  RegistrationRegisterPayment: { tenantCode: string, eventId: string }
  RegistrationRegisterPaymentResult: { tenantCode: string, eventId: string }
  RegistrationRegisterInvoiceResult: { tenantCode: string, eventId: string }
}

export type RegisterRouteName = keyof RegisterRouteParams;

export interface AttendeeRouteParams {
  RegistrationAttendeeRegistrationDetails: { tenantCode: string, eventId: string }
}

export type AttendeeRouteName = keyof AttendeeRouteParams;

export interface RegistrationRouteParams
  extends DashboardRouteParams,
  ManageRouteParams,
  BuildEventRouteParams,
  AttendeeRouteParams,
  RegisterRouteParams {}

export type RegistrationRouteName = keyof RegistrationRouteParams;

export const buildEventRoutes: Record<
keyof BuildEventRouteParams,
RouteObject
> = {
  RegistrationBuildEventEventInfo: {
    id: 'RegistrationBuildEventEventInfo',
    path: ':eventId/event-info',
    element: <RegistrationBuildEventEventInfoPage />
  },
  RegistrationBuildEventGroupRegistration: {
    id: 'RegistrationBuildEventGroupRegistration',
    path: ':eventId/group-registration',
    element: <RegistrationBuildEventGroupRegistrationPage />
  },
  RegistrationBuildEventAttendeeCategories: {
    id: 'RegistrationBuildEventAttendeeCategories',
    path: ':eventId/attendee-categories',
    element: <RegistrationBuildEventAttendeeCategoriesPage />
  },
  RegistrationBuildEventDiscountCodes: {
    id: 'RegistrationBuildEventDiscountCodes',
    path: ':eventId/discount-codes',
    element: <RegistrationBuildEventAddDiscountCodesPage />
  },
  RegistrationBuildEventPersonalInfoForm: {
    id: 'RegistrationBuildEventPersonalInfoForm',
    path: ':eventId/personal-info-form',
    element: <RegistrationBuildEventPersonalInfoFormPage />
  },
  RegistrationBuildEventBuildPersonalInfoForm: {
    id: 'RegistrationBuildEventBuildPersonalInfoForm',
    path: ':eventId/build-personal-info-form',
    element: <RegistrationBuildEventBuildPersonalInfoFormPage />
  },
  RegistrationBuildEventMainEventTickets: {
    id: 'RegistrationBuildEventMainEventTickets',
    path: ':eventId/main-event-tickets',
    element: <RegistrationBuildEventMainEventTicketsPage />
  },
  RegistrationBuildEventFunctionTickets: {
    id: 'RegistrationBuildEventFunctionTickets',
    path: ':eventId/function-tickets',
    element: <RegistrationBuildEventFunctionTicketsPage />
  },
  RegistrationBuildEventWorkshopTickets: {
    id: 'RegistrationBuildEventWorkshopTickets',
    path: ':eventId/workshop-tickets',
    element: <RegistrationBuildEventWorkshopTicketsPage />
  },
  RegistrationBuildEventTourTickets: {
    id: 'RegistrationBuildEventTourTickets',
    path: ':eventId/tour-tickets',
    element: <RegistrationBuildEventTourTicketsPage />
  },
  RegistrationBuildEventAdditionalInfoForm: {
    id: 'RegistrationBuildEventAdditionalInfoForm',
    path: ':eventId/additional-info-form',
    element: <RegistrationBuildEventAdditionalInfoFormPage />
  },
  RegistrationBuildEventBuildAdditionalInfoForm: {
    id: 'RegistrationBuildEventBuildAdditionalInfoForm',
    path: ':eventId/build-additional-info-form',
    element: <RegistrationBuildEventBuildAdditionalInfoFormPage />
  },
  RegistrationBuildEventPrivacy: {
    id: 'RegistrationBuildEventPrivacy',
    path: ':eventId/privacy',
    element: <RegistrationBuildEventPrivacyPage />
  },
  RegistrationBuildEventPaymentMethods: {
    id: 'RegistrationBuildEventPaymentMethods',
    path: ':eventId/payment-methods',
    element: <RegistrationBuildEventPaymentMethodsPage />
  },
  RegistrationBuildEventBadge: {
    id: 'RegistrationBuildEventBadge',
    path: ':eventId/badge',
    element: <RegistrationBuildEventBadgePage />
  },
  RegistrationBuildEventCheckout: {
    id: 'RegistrationBuildEventCheckout',
    path: ':eventId/checkout',
    element: <RegistrationBuildEventCheckoutPage />
  },
  RegistrationBuildEventMerchandise: {
    id: 'RegistrationBuildEventMerchandise',
    path: ':eventId/merchandise',
    element: <RegistrationBuildEventMerchandisePage />
  },
  // RegistrationBuildEventLabelsDescriptions: {
  //   id: 'RegistrationBuildEventLabelsDescriptions',
  //   path: ':eventId/labels-descriptions',
  //   element: (<RegistrationBuildEventLabelsDescriptionsPage />)
  // },
  RegistrationBuildEventOtherSettings: {
    id: 'RegistrationBuildEventOtherSettings',
    path: ':eventId/other-settings',
    element: <RegistrationBuildEventOtherSettingsPage />
  },
  EventGeneration: {
    id: 'EventGeneration',
    path: 'event-generation',
    element: <AIChatSessionWindow />
  }
};

export const registerRoutes: Record<keyof RegisterRouteParams, RouteObject> = {
  RegistrationRegisterEmail: {
    id: 'RegistrationRegisterEmail',
    path: ':eventId/email',
    element: <RegistrationEmailPage />
  },
  RegistrationRegisterCategory: {
    id: 'RegistrationRegisterCategory',
    path: ':eventId/category',
    element: <RegistrationRegisterCategoryPage />
  },
  RegistrationRegisterPersonalInfo: {
    id: 'RegistrationRegisterPersonalInfo',
    path: ':eventId/personal-info',
    element: <RegistrationRegisterPersonalInfoPage />
  },
  RegistrationRegisterMainTickets: {
    id: 'RegistrationRegisterMainTickets',
    path: ':eventId/main-tickets',
    element: <RegistrationRegisterMainTicketsPage />
  },
  RegistrationRegisterFunctionTickets: {
    id: 'RegistrationRegisterFunctionTickets',
    path: ':eventId/function-tickets',
    element: <RegistrationRegisterFunctionTicketsPage />
  },
  RegistrationRegisterWorkshopTickets: {
    id: 'RegistrationRegisterWorkshopTickets',
    path: ':eventId/workshop-tickets',
    element: <RegistrationRegisterWorkshopTicketsPage />
  },
  RegistrationRegisterTourTickets: {
    id: 'RegistrationRegisterTourTickets',
    path: ':eventId/tour-tickets',
    element: <RegistrationRegisterTourTicketsPage />
  },
  RegistrationRegisterShoppingCart: {
    id: 'RegistrationRegisterShoppingCart',
    path: ':eventId/shopping-cart',
    element: <RegistrationRegisterShoppingCartPage />
  },
  RegistrationRegisterAdditionalInfo: {
    id: 'RegistrationRegisterAdditionalInfo',
    path: ':eventId/additional-info',
    element: <RegistrationRegisterAdditionalInfoPage />
  },
  RegistrationRegisterReview: {
    id: 'RegistrationRegisterReview',
    path: ':eventId/review',
    element: <RegistrationRegisterReviewPage />
  },
  RegistrationRegisterCheckoutAddress: {
    id: 'RegistrationRegisterCheckoutAddress',
    path: ':eventId/checkout-address',
    element: <RegistrationRegisterCheckoutAddressPage />
  },
  RegistrationRegisterPayment: {
    id: 'RegistrationRegisterPayment',
    path: ':eventId/payment',
    element: <RegistrationRegisterPaymentPage />
  },
  RegistrationRegisterPaymentResult: {
    id: 'RegistrationRegisterPaymentResult',
    path: ':eventId/payment-result',
    element: <RegistrationRegisterPaymentResultPage />
  },
  RegistrationRegisterInvoiceResult: {
    id: 'RegistrationRegisterInvoiceResult',
    path: ':eventId/invoice-result',
    element: <RegistrationRegisterInvoiceResultPage />
  }
};

export const attendeeRoutes: Record<keyof AttendeeRouteParams, RouteObject> = {
  RegistrationAttendeeRegistrationDetails: {
    id: 'RegistrationAttendeeRegistrationDetails',
    path: ':eventId/details',
    element: <RegistrationAttendeeRegistrationDetails />
  }
};

export const registrationRoutes: Record<string, RouteObject> = {
  // Dashboard routes
  RegistrationDashboardOverview: {
    id: 'RegistrationDashboardOverview',
    path: 'dashboard/overview',
    element: (
      <AdminAccess>
        <RegistrationDashboardOverviewPage />
      </AdminAccess>
    )
  },
  // Manage routes
  RegistrationManageEvent: {
    id: 'RegistrationManageEvent',
    path: 'manage/event',
    element: (
      <AdminAccess>
        <RegistrationManageEventPage />
      </AdminAccess>
    )
  },
  RegistrationManageOrder: {
    id: 'RegistrationManageOrder',
    path: 'manage/order',
    element: (
      <AdminAccess>
        <RegistrationManageOrderPage />
      </AdminAccess>
    )
  },
  RegistrationPrefillEvent: {
    id: 'RegistrationPrefillEvent',
    path: 'prefill-event/:eventId',
    element: (
      <AdminAccess>
        <RegistrationPrefillPage />
      </AdminAccess>
    )
  },
  RegistrationManageOrderInfo: {
    id: 'RegistrationManageOrderInfo',
    path: 'manage/order-info/:orderId',
    element: (
      <AdminAccess>
        <RegistrationManageOrderInfoPage />
      </AdminAccess>
    )
  },
  RegistrationManageTable: {
    id: 'RegistrationManageTable',
    path: 'manage/registration',
    element: (
      <AdminAccess>
        <RegistrationManageTablePage />
      </AdminAccess>
    )
  },
  RegistrationManageInfo: {
    id: 'RegistrationManageInfo',
    path: 'manage/registration-info/:registrationId',
    element: (
      <AdminAccess>
        <RegistrationManageInfoPage />
      </AdminAccess>
    )
  },
  // Build Event routes
  BuildEvent: {
    path: 'build-event',
    element: <BuildEventLayout />,
    children: Object.values(buildEventRoutes)
  },
  // Register routes
  RegisterRoute: {
    path: 'register',
    element: <RegisterLayout />,
    children: Object.values(registerRoutes)
  },
  // Attendee routes
  AttendeeRoute: {
    path: 'attendee',
    element: <AttendeeLayout />,
    children: Object.values(attendeeRoutes)
  }
};
