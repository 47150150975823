import { type FC, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { type UiHStackProps, UiStack, UiHStack, UiButton, UiFormHelperText, UiText, UiVStack } from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { registration } from '@/api';
import {
  IsRequiredType,
  type DiscountCode,
  type DiscountCodeSaveRequest
} from '@/api/registration';
import dayjs from 'dayjs';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseFormSelectField from '@/base/Form/SelectField';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useEventQuery } from '@/registration/hook/useEventQuery';

export interface CodeFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  discountCode?: DiscountCode
}

interface FormData {
  name: string
  description?: string
  discountCode: string
  startDate: string
  endDate: string
  idPhotocopy: IsRequiredType
  idInfo: IsRequiredType
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  description: Yup.string()
    .max(100, 'Description can not have more than 100 charactors.'),
  discountCode: Yup.string().required('Discount Code is required.'),
  startDate: Yup.date(),
  endDate: Yup.date(),
  idPhotocopy: Yup.string().required('This field is required.'),
  idInfo: Yup.string().required('This field is required.')
});

const CodeForm: FC<CodeFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  discountCode
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { data: event } = useEventQuery(eventId);

  const { mutate, isLoading } = useMutation<{}, Error, DiscountCodeSaveRequest>(
    {
      mutationFn: async (data: DiscountCodeSaveRequest) => {
        return await registration.saveDiscountCode(createTenantAdminApiRequest)(data);
      },
      onSuccess: () => {
        onSaveSuccess();
        void queryClient.invalidateQueries({ queryKey: [registration.discountCodeQueryKey, { eventId }] });
        void queryClient.invalidateQueries({ queryKey: [registration.ticketDiscountOptionQueryKey] });
        void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
        onClose();
      },
      onError: (error) => {
        setSaveErrors([error.message ?? 'Failed to save the host.']);
      }
    }
  );

  const submitForm = async (values: FormData) => {
    mutate({
      id: discountCode?.id,
      eventId,
      name: values.name,
      ...values.description ? { description: values.description } : {},
      discountCode: values.discountCode,
      startDate: values.startDate,
      endDate: values.endDate,
      idPhotocopy: values.idPhotocopy,
      idInfo: values.idInfo,
      active: true
    });
  };

  const generateCode = () => {
    return [...Array(8)]
      .map(() => Math.random().toString(36)[2])
      .join('');
  };

  return (
    <Formik<FormData>
      initialValues={
        discountCode
          ? {
            ...discountCode,
            startDate: dayjs(discountCode.startDate).tz(event?.timeZone).format('YYYY-MM-DD'),
            endDate: dayjs(discountCode.endDate).tz(event?.timeZone).format('YYYY-MM-DD')
          } : {
            name: '',
            description: '',
            discountCode: '',
            startDate: '',
            endDate: '',
            idPhotocopy: IsRequiredType.NOT_REQUIRED,
            idInfo: IsRequiredType.NOT_REQUIRED
          }
      }
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (values, { setSubmitting }: FormikHelpers<FormData>) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >{({ setFieldValue, values }) => (
        <BaseFormDrawer
          isOpen={isVisible}
          onClose={onClose}
          title={discountCode ? 'Edit Discount' : 'Add Discount'}
          size={'xl'}
          isLoading={isLoading}
        >
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {saveErrors.map((error, index) => (
                <BaseMessageBarError key={index}>{error}</BaseMessageBarError>
              ))}
            </UiStack>
          )}
          <BaseFormFieldGroup>
            <BaseFormInputField name="name" label="Name" layout="stack" />
            <BaseFormTextareaField
              name="description"
              label="Description"
              isRequired={false}
              layout="stack"
              maxLength={100}
              helperText={`100 character limit (${100 - (values?.description?.length ?? 0)} characters remaining)`} />
            <BaseFormInputField
              name="discountCode"
              label="Discount Code"
              layout="stack" />
            <UiHStack alignItems="flex-start">
              <UiButton px={6}
                py={4}
                size={'md'}
                colorScheme={'primary'}
                onClick={() => {
                  const code = generateCode();
                  void setFieldValue('discountCode', code);
                } }>
                Generate
              </UiButton>
            </UiHStack>
            <UiStack>
              <UiText color={'gray.600'} variant="caption">
                Discounts will only be available on dates including and between the Start Date and End Date.
                If either dates are left blank, the discount will be available at all times
              </UiText>
              <UiHStack flex={1} alignItems="flex-start" >
                <BaseFormInputField
                  name="startDate"
                  label="Start date"
                  layout="stack"
                  type="date"
                  helperText="The provided date should be in the event's time zone." />
                <BaseFormInputField
                  name="endDate"
                  label="End date"
                  layout="stack"
                  type="date"
                  helperText="The provided time should be in the event's time zone." />
              </UiHStack>
            </UiStack>
            <BaseFormSelectField
              name={'idPhotocopy'}
              label={'ID Photocopy'}
              layout="stack"
              options={[
                { value: IsRequiredType.NOT_REQUIRED, label: 'Not required' },
                { value: IsRequiredType.REQUIRED, label: 'Required' },
                { value: IsRequiredType.OPTIONAL, label: 'Optional' }
              ]} />
            <BaseFormSelectField
              name={'idInfo'}
              label={'ID info'}
              layout="stack"
              options={[
                { value: IsRequiredType.NOT_REQUIRED, label: 'Not required' },
                { value: IsRequiredType.REQUIRED, label: 'Required' },
                { value: IsRequiredType.OPTIONAL, label: 'Optional' }
              ]}
              helperText={'If the attendee needs to provide the information about the ID. E.g. member id'} />
          </BaseFormFieldGroup>
        </BaseFormDrawer>)}
    </Formik>
  );
};

export default CodeForm;
