import { useCallback, useMemo } from 'react';
import { useMatches, useNavigate, useSearchParams } from 'react-router-dom';
import { type RegistrationRouteName } from '@/registration/pages';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from './useRegisterRoute';
import { type RegistrationSteps, RegistrationStrategyType } from '@/api/registration';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';

export interface RegisterStep {
  routeName: RegistrationRouteName
  isEnable?: boolean
}

export function useRegisterSteps() {
  const { tenantCode, eventId } = useRegisterRoute();
  const { registration } = useRegistrationAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const steps = useMemo(
    () => registration?.registrationSteps ?? [],
    [registration?.registrationSteps]
  );

  const matches = useMatches();
  const currentStepRouteName = useMemo(
    () => matches[matches.length - 1].id as RegistrationRouteName
    , [matches]);

  const stepIndex = useMemo(
    () => steps.findIndex((currentStep) => currentStep.routeName === currentStepRouteName)
    , [currentStepRouteName, steps]);

  const currentStep = useMemo(() => steps[stepIndex], [stepIndex, steps]);

  const isReviewing = useMemo(() => !!searchParams.get('review'), [searchParams]);

  const findNextStep = useCallback(() => {
    const nextStep = steps[stepIndex + 1];
    return nextStep;
  }, [stepIndex, steps]);

  const findPreviousStep = useCallback(() => {
    if (stepIndex === -1) return null;

    const prevStep = steps[stepIndex - 1];
    return prevStep;
  }, [stepIndex, steps]);

  const toNextStep = useCallback((additionalParams?: any) => {
    if (isReviewing) {
      searchParams.delete('review');
      return navigate(generatePageUrl('RegistrationRegisterReview', { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
    const nextStep = findNextStep();
    if (nextStep) {
      let params = Object.fromEntries(searchParams);
      if (additionalParams) {
        params = { ...params, ...additionalParams };
      }
      navigate(generatePageUrl(nextStep.routeName, { tenantCode, eventId }, params));
    }
  }, [eventId, findNextStep, isReviewing, navigate, searchParams, tenantCode]);

  // TODO: toNextStep not use latest data, need to fix and remove toNextStepWithSteps
  const toNextStepWithSteps = useCallback((registrationSteps: RegistrationSteps) => {
    if (isReviewing) {
      searchParams.delete('review');
      return navigate(generatePageUrl('RegistrationRegisterReview', { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }

    const nextStep = registrationSteps[stepIndex + 1];
    if (nextStep) {
      navigate(generatePageUrl(nextStep.routeName, { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
  }, [eventId, isReviewing, navigate, searchParams, stepIndex, tenantCode]);

  const generateNextStepUrl = useCallback((additionalParams: object) => {
    const nextStep = findNextStep();
    const params = { ...Object.fromEntries(searchParams), ...additionalParams };
    return generatePageUrl(nextStep.routeName, { tenantCode, eventId }, params);
  }, [eventId, findNextStep, searchParams, tenantCode]);

  const toPreviousStep = useCallback(() => {
    if (isReviewing) {
      searchParams.delete('review');
      return navigate(generatePageUrl('RegistrationRegisterReview', { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
    const previousStep = findPreviousStep();
    if (previousStep) {
      navigate(generatePageUrl(previousStep.routeName, { tenantCode, eventId }, Object.fromEntries(searchParams)));
    }
  }, [eventId, findPreviousStep, isReviewing, navigate, searchParams, tenantCode]);

  const displayShoppingCart = useMemo(() => {
    if (currentStep) {
      if (currentStep.routeName === 'RegistrationRegisterPersonalInfo' ||
        currentStep.routeName === 'RegistrationRegisterAdditionalInfo' ||
        currentStep.routeName === 'RegistrationRegisterReview') {
        if (registration?.registrationStrategy === RegistrationStrategyType.FORMS_FIRST) {
          return true;
        } else if (registration?.registrationStrategy === RegistrationStrategyType.PAYMENT_FIRST) {
          return false;
        }
      }
      if (currentStep.routeName === 'RegistrationRegisterPaymentResult') {
        return false;
      }
      return true;
    }
  }, [registration?.registrationStrategy, currentStep]);

  return {
    steps,
    currentStep,
    isReviewing,
    findNextStep,
    findPreviousStep,
    toNextStep,
    toNextStepWithSteps,
    generateNextStepUrl,
    toPreviousStep,
    displayShoppingCart
  };
}
