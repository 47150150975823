import { useCallback, type FC } from 'react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import useFields from '@/base/FormBuilder/useFields';
import { initFieldsLayout, initFieldsMetadata, InfoFormType, type InfoForm, type InfoFormSaveRequest } from '@/api/registration';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import { type Layout } from 'react-grid-layout';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import { UiHStack, UiIconArrowSquareOut, UiIconCaretLeft, UiLink, UiStack, UiText, uiStyles } from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import BaseFormBuilder from '@/base/FormBuilder';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useSearchParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import FullScreen from '@/base/Modal/FullScreen';
import FormPreview from '@/base/FormGenerator/FormPreview';

const BuildAdditionalInfoForm: FC = () => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const [searchParams] = useSearchParams();
  const id = Number(searchParams.get('id'));

  const infoFormQuery = useQuery<InfoForm, Error>(
    [registration.infoFormQueryKey, { id }],
    async () => {
      const result = await registration.loadInfoForm(createTenantAdminApiRequest)(id);
      return {
        ...result.item,
        config: JSON.parse(result.item.config) as InfoForm['config']
      };
    },
    {
      enabled: !isApiPreparing && !!id
    }
  );

  const {
    fieldsLayout,
    fieldsMetadata,
    addField,
    setFieldsLayout,
    deleteField,
    updateFieldMetadata
  } = useFields(
    (infoFormQuery?.data?.config.fieldsLayout ?? initFieldsLayout),
    (infoFormQuery?.data?.config?.fieldsMetadata ?? initFieldsMetadata)
  );

  const { mutate } = useMutation<InfoForm, Error, InfoFormSaveRequest>({
    mutationFn: async (data: InfoFormSaveRequest) => {
      const response = await registration.saveInfoForm(createTenantAdminApiRequest)(data);
      return response.item;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId, formType: InfoFormType.ADDITIONAL }] });
      void (!!id && queryClient.invalidateQueries({ queryKey: [registration.infoFormQueryKey, { id }] }));
    },
    onError: () => {
      // setSaveErrors([error.message ?? 'Failed to save the attendee category.']);
    }
  });

  const onFieldAdd = (newFieldsMetadata: FieldMetadata) => {
    let layout: Layout = { x: 0, y: Infinity, w: 1, h: 1, maxH: 1, i: newFieldsMetadata.id };
    if (newFieldsMetadata.type === 'sectionBreak') {
      layout = { x: 0, y: Infinity, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: newFieldsMetadata.id };
    }
    addField(layout, newFieldsMetadata);
  };

  const onSaveAndGoBack = useCallback(() => {
    mutate({
      infoForm: {
        ...(id ? { id } : {}),
        formType: InfoFormType.ADDITIONAL,
        eventId,
        config: JSON.stringify({
          totalFields: fieldsLayout.length,
          fieldsLayout,
          fieldsMetadata
        })
      }
    });
  }, [eventId, fieldsLayout, fieldsMetadata, id, mutate]);

  return (
    <>
      <UiStack
        // width={1200}
        bgColor={'gray.100'}
        alignItems={'stretch'}
        borderRadius={uiStyles.borderRadius}
        minHeight={'100vh'}
        flexGrow={1}
        // p={8}
        pb={12}
      >
        <UiStack
          position={'fixed'}
          alignItems={'stretch'}
          width={'100%'}
          zIndex={999}
          bgColor={'gray.100'}
          p={8}
        >
          <UiStack
            px={8}
            py={6}
            bgColor={'#fff'}
            borderRadius={uiStyles.borderRadius}
          >
            <UiHStack alignItems={'stretch'} justifyContent={'space-between'}>
              <BaseRouterLink to={generatePageUrl('RegistrationBuildEventAdditionalInfoForm', { tenantCode, eventId })}>
                <UiHStack {...uiStyles.hover} onClick={onSaveAndGoBack}>
                  <UiIconCaretLeft color={'primary.500'} />
                  <UiText variant={'title'} color={'primary.400'} fontWeight={400}>Save & go back</UiText>
                </UiHStack>
              </BaseRouterLink>
              <UiLink
                color={'primary.500'}
                fontSize={'xl'}
                fontWeight={'bold'}
                onClick={onOpen}
                target={'_blank'}
              >
                <UiHStack {...uiStyles.hover}>
                  <UiText variant={'title'} color={'primary.400'} fontWeight={400}>Preview</UiText>
                  <UiIconArrowSquareOut color={'primary.500'} />
                </UiHStack>
              </UiLink>
            </UiHStack>
          </UiStack>
        </UiStack>
        <UiStack
          spacing={0}
          flexGrow={1}
          alignItems={'center'}
          pt={36}
        >
          <UiStack
            width={1080}
            alignItems={'stretch'}
            spacing={8}
          >
            <BaseFormBuilder
              onLayoutChange={setFieldsLayout}
              onFieldUpdate={updateFieldMetadata}
              fieldsLayout={fieldsLayout}
              fieldsMetadata={fieldsMetadata}
              onFieldAdd={onFieldAdd}
              onFieldDelete={deleteField}
            />
          </UiStack>
        </UiStack>
        <FullScreen isOpen={isOpen} onClose={onClose}>
          <FormPreview fieldsLayout={fieldsLayout} fieldsMetadata={fieldsMetadata} />
        </FullScreen>
      </UiStack>
    </>
  );
};

export default BuildAdditionalInfoForm;
