/**
 * Notes We should always use these 2 hooks (useEventSettingsQuery, useEventSettingsSegmentMutation) to
 * manage all the event settings related data in all the components during the registration setup.
 */
import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  uiStyles, UiIconCurrencyCircleDollar
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import TicketList from '../MainEventTickets/TicketList';
import { TicketType } from '@/api/constant';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';

const FunctionTickets: FC = () => {
  const { eventId } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();

  const { mutate, isLoading: isSetLoading } = useIsSetMutation();
  const eventQuery = useEventSettingsQuery(eventId);

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'functionTicket', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      // mutate,
      // eventId,
      toNextStep
    ]
  );

  // Assume the step is set once the step is visited.
  useEffect(
    () => {
      mutate({ eventId, key: 'functionTicket', bool: true });
    },
    [mutate, eventId]
  );

  return (
    <BaseLayout
      smartWindow={<SmartWindow />}
      // disableTopNav={true}
      appSwitch={<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={
          <ManageEventSteps
            eventId={eventId}
            activeStep={'functionTickets'}
          />
        }
        message={'Please design your badge.'}
      >
        <QueryContainer
          query={eventQuery}
          errorMessage="Failed to load tickets">
          {(eventSettings) => (
            <>
              {/* <UiHStack p={8}> */}
              {/*  {!eventSettings.functionTicket?.isEnabled */}
              {/*    ? ( */}
              {/*      <UiText variant={'title'} color={'text.secondary'}> */}
              {/*        Step skipped */}
              {/*      </UiText> */}
              {/*    ) */}
              {/*    : ( */}
              {/*      <UiText variant={'title'} color={'primary.500'}> */}
              {/*        Step included */}
              {/*      </UiText> */}
              {/*    )} */}
              {/*  <UiSwitch */}
              {/*    colorScheme={'primary'} */}
              {/*    onChange={onToggle} */}
              {/*    isChecked={eventSettings.functionTicket?.isEnabled} */}
              {/*    size={'lg'} /> */}
              {/* </UiHStack> */}

              {/* {!eventSettings.functionTicket?.isEnabled */}
              {/*  ? ( */}
              {/*    <UiStack pb={8} px={8}> */}
              {/*      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>There are no function tickets for this event.</BaseMessageBarInfo> */}
              {/*    </UiStack> */}
              {/*  ) : ( */}
              <>
                <UiStack spacing={4} flexGrow={1} p={8}>
                  <UiHStack alignItems={'flex-start'} justifyContent={'flex-start'} spacing={4}>
                    {/* <UiHStack bgColor={'#fff'} p={6} py={4} borderRadius={uiStyles.borderRadius}>
                      <UiIconCurrencyCircleDollar />
                      <UiText>AUD</UiText>
                      <UiText color={'text.secondary'}>(you can change the currency by editing the event)</UiText>
                    </UiHStack> */}
                    <UiHStack>
                      <StepInstruction label={'Instructions'}>
                        <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                          <UiText>
                            You can manage which attendee categories can see each of the tickets.
                          </UiText>
                        </UiStack>
                      </StepInstruction>
                    </UiHStack>
                  </UiHStack>
                  <BaseDividerHorizontal height={4} />
                  <TicketList ticketType={TicketType.Function} />
                </UiStack>
              </>
              {/*  ) */}
              {/* } */}
            </>
          )}
        </QueryContainer>
        <UiHStack justifyContent={'space-between'} p={8} >
          <UiStack flexGrow={1} />
          <UiButton
            size={'lg'}
            colorScheme={'primary'}
            type={'submit'}
            fontSize={'lg'}
            onClick={onNext}
            isLoading={isSetLoading}>
              Next
          </UiButton>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default FunctionTickets;
