import { type FC } from 'react';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import BaseShoppingCart from '@/registration/component/Register/ShoppingCart';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import { UiButton, UiHStack, UiStack, UiText } from '@/lib/ui';
import { useRegisterSteps } from '@/registration/hook/useRegisterSteps';

const ShoppingCart: FC = () => {
  const { toNextStep, toPreviousStep } = useRegisterSteps();

  return (
    <RegisterLayout>
      <BaseTransitionSlideLeft>
        <UiStack flexGrow={1}>
          <UiStack pb={4}>
            <UiText variant={'h6'}>Shopping cart</UiText>
          </UiStack>
          <BaseShoppingCart />
        </UiStack>
      </BaseTransitionSlideLeft>
      <UiStack height={96} />
      <LayoutFooter>
        <UiHStack justifyContent={'flex-end'} flexGrow={1}>
          <UiButton px={8} size={'lg'} colorScheme={'primary'} onClick={toPreviousStep} variant={'ghost'}>
            Previous
          </UiButton>
          <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={toNextStep}>
            Checkout
          </UiButton>
        </UiHStack>
      </LayoutFooter>
    </RegisterLayout>
  );
};

export default ShoppingCart;
