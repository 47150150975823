import { type FC, type ReactNode } from 'react';
import {
  useToken,
  useDisclosure
} from '@chakra-ui/react';
import BaseRouterLink from '@/base/Router/Link';
import {
  type UiBoxProps,
  UiHStack, UiIconArrowCounterClockwise, UiIconArrowsClockwise,
  UiIconCaretRight,
  UiIconDotsNine, UiSpinner, UiStack,
  uiStyles, UiText
} from '@/lib/ui';
import { type AppName } from './List';
import { generatePageUrl } from '@/app/pages';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface ListDrawerButtonProps extends UiBoxProps {
  currentApp: ReactNode
  currentAppName?: AppName
}

const ListDrawerButton: FC<ListDrawerButtonProps> = ({
  currentApp,
  currentAppName = undefined
}) => {
  // const { isOpen, onToggle } = useDisclosure();
  const { tenant } = useTenantRoute();
  const colorToken = useToken('colors', 'brand.100');

  if (!tenant?.code) {
    return (
      <UiStack>
        <UiSpinner size={'lg'} color={'brand.500'} thickness='2px' />
      </UiStack>
    );
  }

  const tenantCode = tenant.code;
  return (
    <>
      <UiHStack
        spacing={0}
        // bgColor={'#fff'}
        // borderRadius={uiStyles.borderRadius}
        // borderWidth={'2px'}
        // borderColor={'brand.500'}
        // borderStyle={'solid'}
        // onClick={onToggle}
        // py={6}
        // flexGrow={1}
        alignItems={'center'}
        // justifyContent={'space-around'}
        // bgColor={'red'}
        // height={'80px'}
      >
        <BaseRouterLink to={generatePageUrl('AccountDashboardModules', { tenantCode })} style={{ cursor: 'inherit' }}>
          <UiHStack
            borderWidth={'3px'}
            borderColor={'brand.500'}
            borderStyle={'solid'}
            bgColor={'brand.500'}
            borderRadius={'50%'}
            // onClick={onToggle}
            p={2}
            {...uiStyles.hover}
          >
            <UiStack
              // width={'48px'}
              // height={'48px'}
              spacing={0}
              flexGrow={1}
              alignItems={'center'}
              justifyContent={'space-around'}
            >
              <UiIconDotsNine
                size={'2xl'}
                color={'#fff'}
                weight={'bold'}
              />
              {/* <UiIconArrowsClockwise */}
              {/*  size={'2xl'} */}
              {/*  color={'#fff'} */}
              {/*  weight={'bold'} */}
              {/* /> */}
            </UiStack>
            {/* <UiText color={'#fff'} fontWeight={'500'}>Modules</UiText> */}
          </UiHStack>
        </BaseRouterLink>
        {/* <UiStack> */}
        {/*  <UiIconCaretRight size={'3xl'} color={'text.brand'}/> */}
        {/* </UiStack> */}
        <UiStack px={4}>
          {currentApp}
        </UiStack>
      </UiHStack>
      {/* <ListDrawer */}
      {/*  currentAppName={currentAppName} */}
      {/*  isOpen={isOpen} */}
      {/*  onClose={onToggle} */}
      {/* /> */}
    </>
  );

  // return (
  //   <>
  //     <UiHStack
  //       spacing={0}
  //     >
  //       <UiHStack spacing={0}>
  //         <UiStack pl={8} pr={4} py={4} bgColor={colorToken} height={'80px'} alignItems={'center'} justifyContent={'space-around'}>
  //           <UiBox
  //             p={2}
  //             borderRadius={'100%'}
  //             borderWidth={'2px'}
  //             borderColor={'brand.500'}
  //             onClick={onToggle}
  //             {...uiStyles.hoverFlex({bgColor: 'brand.100'})}
  //           >
  //             <UiIconDotsNine size={'xl'} color={'brand.800'} weight={'bold'}/>
  //           </UiBox>
  //         </UiStack>
  //         <UiBox width={'80px'}>
  //           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="none" fill={colorToken}>
  //             <polygon points="0 0 24 0 0 24"/>
  //           </svg>
  //         </UiBox>
  //       </UiHStack>
  //       {currentApp}
  //       {/*<UiIconArrowsClockwise size={'2xl'} color={'brand.500'} weight={'bold'}/>*/}
  //     </UiHStack>
  //     <ListDrawer
  //       currentAppName={currentAppName}
  //       isOpen={isOpen}
  //       onClose={onToggle}
  //     />
  //   </>
  // );
};

export default ListDrawerButton;
