/* eslint-disable react/no-unescaped-entities */
import { type FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import RegisterLayout from '@/registration/component/Register/Layout';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { UiStack, UiText, uiStyles } from '@/lib/ui';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { registration } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { type Order } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import RegistrationDetails from '../PaymentResult/RegistrationDetails';
import QueryContainer from '@/base/QueryContainer/QueryContainer';

const InvoiceResult: FC = () => {
  const [searchParams] = useSearchParams();
  const orderHash = searchParams.get('orderHash');
  // const [error, setError] = useState(false);
  const { createTenantApiRequest } = useTenantApi();

  const orderQuery = useQuery<Order, Error>({
    queryKey: [registration.orderQueryKey, { orderHash }],
    queryFn: async () => (await registration.loadOrder(createTenantApiRequest)(orderHash!)).item,
    enabled: !!orderHash
  });

  return (
    <RegisterLayout>
      <QueryContainer query={orderQuery}>
        {(order) => (
          <BaseTransitionSlideLeft>
            <UiStack flexGrow={1} spacing={4}>
              <UiStack>
                {/* {error && <UiText variant={'h6'}>Invoice creation failed</UiText>} */}
                {order && <UiText variant={'h6'}>Invoice creation successful</UiText>}
              </UiStack>
              {/* {error && <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'red.100'}>
                  Something went wrong, please try again later
                </BaseMessageBarInfo>} */}
              {order && (
                <>
                  <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'green.100'}>
                    We've sent the receipt with your payment details to your email. It might take several minutes to arrive. Please also check your spam.
                  </BaseMessageBarInfo>
                  <RegistrationDetails order={order} />
                </>
              )}
            </UiStack>
          </BaseTransitionSlideLeft>
        )}
      </QueryContainer>
      <UiStack height={96} />
    </RegisterLayout>
  );
};

export default InvoiceResult;
