import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import {
  UiStack,
  UiText
} from '@/lib/ui';
// import Filter from './Filter';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import EventList from './EventList';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

const Event: FC = () => {
  const { tenantCode } = useRegisterRoute();
  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<AdminSideNav activeNodes={['manage', 'events']} tenantCode={tenantCode} />)}
        // appSwitch={(
        //   <UiHStack spacing={4}>
        //     {/* <UiIconDotsNine size={'40px'} color={'primary.500'} weight={'bold'}/> */}
        //     <BaseAppRegistrationCard navLink={generatePageUrl('AccountSubscriptionPlan')}/>
        //   </UiHStack>
        // )}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
          // breadcrumbNodes={breadcrumbNodes}
        >
          <UiStack flexGrow={1} spacing={4} alignItems={'stretch'}>
            {/* <NewEventRow/> */}
            <UiText variant={'title'}>Events</UiText>
            <EventList queryParams={{}} />
            {/* <RecentEvents queryParams={{tenantCode,}}/> */}
          </UiStack>
          {/* <UiHStack
            justifyContent={'space-between'}
          >
            <UiStack flexGrow={1}/>
            <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} variant={'ghost'} onClick={onToggle}>
              Next
            </UiButton>
          </UiHStack> */}
        </BaseLayoutBody>
      </BaseLayout>
      {/* <Filter
        isOpen={showFilter}
        onClose={() => {setShowFilter(false)}}
      /> */}
    </>
  );
};

export default Event;
