import { useMemo, type FC } from 'react';
import {
  UiHStack,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText
} from '@/lib/ui';
import { type FormResponse } from '@/api/registration';
import { type FieldMetadata } from '@/base/FormBuilder/Field';

export interface RegistrationInfoResponseProps {
  title: string
  infoFormResponse: FormResponse
}

const RegistrationInfoResponse: FC<RegistrationInfoResponseProps> = ({ title, infoFormResponse }) => {
  const fieldsMetadata = useMemo(() => {
    return infoFormResponse.infoForm?.config
      ? JSON.parse(infoFormResponse.infoForm.config).fieldsMetadata as FieldMetadata[]
      : null;
  }, [infoFormResponse.infoForm?.config]);
  return (
    <UiStack spacing={4}>
      <UiText variant={'title'}>{title}</UiText>
      <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1}>
        <UiSimpleGrid columns={3} gap={8}>
          {
            fieldsMetadata
              ? fieldsMetadata
                .filter((fieldMetadata) => (fieldMetadata.type !== 'sectionBreak'))
                .map((fieldMetadata) => (
                  <UiStack key={fieldMetadata.id}>
                    <UiText color={'text.secondary'}>{fieldMetadata.label}</UiText>
                    <UiHStack spacing={4}>
                      {
                        infoFormResponse.response[fieldMetadata.label.toLowerCase()]
                          ? <UiText>{infoFormResponse.response[fieldMetadata.label.toLowerCase()]}</UiText>
                          : <UiText color="orange">Missing</UiText>
                      }
                    </UiHStack>
                  </UiStack>
                ))
              : (
                // NOTE: personal info form not have info_form_id
                Object.entries(infoFormResponse.response).map(([key, value]) => (
                  <UiStack key={key}>
                    <UiText color={'text.secondary'}>{key}</UiText>
                    <UiText>{value}</UiText>
                  </UiStack>
                ))
              )
          }
        </UiSimpleGrid>
      </UiStack>
    </UiStack>
  );
};

export default RegistrationInfoResponse;
