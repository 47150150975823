import { useState, type FC } from 'react';
import {
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiStack,
  UiText,
  UiVStack
} from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';
import PayByStripeWrapper from './PayByStripeWrapper';
import Cart from '@/registration/component/Register/ShoppingCart/Cart';

export type CreditCardGateway = 'Stripe' | 'eWay';

export interface PayByCardDrawerProps extends Omit<UiDrawerProps, 'children'> {
  creditCardGateway: CreditCardGateway
}

const PayByCardDrawer: FC<PayByCardDrawerProps> = ({
  ...props
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  return (
    <UiDrawer placement={'right'} size={'xl'} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        <UiDrawerCloseButton size={'lg'} fontWeight={'bold'} color={'primary.500'} />
        <UiDrawerBody px={0} pb={16} alignItems={'stretch'}>
          <UiStack
            px={{ base: 4, lg: 8 }}
            py={8}
            flexGrow={1}
            spacing={6}
          >
            <UiText variant={'h6'}>Pay by card</UiText>
          </UiStack>
          <UiVStack px={{ base: 4, lg: 8 }} justifyContent={'center'}>
            <Cart />
            <PayByStripeWrapper setErrors={setErrors} />
          </UiVStack>
          {errors.length >= 1 && <BaseMessageBarError mt={3}>{errors[0]}</BaseMessageBarError>}
          {// TODO: handle multiple errors
          }
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default PayByCardDrawer;
