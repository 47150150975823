import { type FC } from 'react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiHStack, UiIconCheck, UiSimpleGrid, UiStack, uiStyles, UiText } from '@/lib/ui';
import Ticket from './Ticket';

export interface TicketsProps {
  orderId: number;
}

const Tickets: FC<TicketsProps> = ({
  orderId,
}) => {
  const { tenantCode } = useRegisterRoute();

  return (
    <UiStack spacing={4}>
      <UiText variant={'title'}>Tickets</UiText>
      <UiSimpleGrid columns={3} gap={8}>
        <Ticket
          name={'General admission'}
          amount={'$3960.00'}
          isDiscounted={true}
        />
        <Ticket
          name={'Welcome Reception'}
          amount={'$75.00'}
          isDiscounted={true}
        />
        <Ticket
          name={'Pre-conference Workshop AM'}
          amount={'$400.00'}
          isDiscounted={false}
        />
        <Ticket
          name={'Blue Mountains Tour'}
          amount={'$250.00'}
          isDiscounted={false}
        />
      </UiSimpleGrid>
    </UiStack>
  );
};

export default Tickets;
