import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import {
  UiHStack, UiIconArrowsClockwise,
  UiIconCheck,
  UiIconDotsThreeCircle,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText
} from '@/lib/ui';
import AttendeeDrawer from '@/registration/manage/OrderInfo/AttendeeDrawer';
import OrderStatusDrawer from '@/registration/manage/OrderInfo/OrderStatusDrawer';

export interface OrderSummaryProps {
  orderId: number
}

const OrderSummary: FC<OrderSummaryProps> = ({
  orderId
}) => {
  const { tenantCode } = useRegisterRoute();
  const { isOpen: isAttendeeDrawerOpen, onToggle: onAttendeeDrawerToggle } = useDisclosure();
  const { isOpen: isOrderStatusOpen, onToggle: onOrderStatusToggle } = useDisclosure();

  return (
    <>
      <UiStack spacing={4}>
        <UiText variant={'title'}>Summary</UiText>
        <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1}>
          <UiSimpleGrid columns={4} gap={8}>
            <UiStack>
              <UiText color={'text.secondary'}>Status</UiText>
              <UiHStack spacing={4}>
                <UiHStack>
                  <UiIconCheck color={'green.500'} size={'2xl'} />
                  <UiText color={'green.500'} fontWeight={'bold'}>Paid</UiText>
                </UiHStack>
                <UiStack {...uiStyles.hover} onClick={onOrderStatusToggle}>
                  <UiIconArrowsClockwise size={'2xl'} color={'primary.500'} />
                </UiStack>
              </UiHStack>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Status</UiText>
              <UiHStack spacing={4}>
                <UiText>michael.key2035@gmail.com</UiText>
                <UiStack {...uiStyles.hover} onClick={onAttendeeDrawerToggle}>
                  <UiIconDotsThreeCircle size={'2xl'} color={'primary.500'} />
                </UiStack>
              </UiHStack>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Order number</UiText>
              <UiText>A1000983</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Purchased at</UiText>
              <UiText>11th March 2024 13:50:05 (Australia/Melbourne)</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Currency</UiText>
              <UiText>AUD</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Amount</UiText>
              <UiText>$3690.00</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Discount code</UiText>
              <UiText>SPEAKER001</UiText>
            </UiStack>
          </UiSimpleGrid>
        </UiStack>
      </UiStack>
      <AttendeeDrawer isOpen={isAttendeeDrawerOpen} onClose={onAttendeeDrawerToggle} />
      <OrderStatusDrawer isOpen={isOrderStatusOpen} onClose={onOrderStatusToggle} />
    </>
  );
};

export default OrderSummary;
