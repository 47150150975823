import { type FC, useMemo } from 'react';
import { UiHStack, UiStack, UiTag, UiText } from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import Card from './Card';
import ModuleList from '../../../component/SubscriptionModule/ModuleList';
import { getPackageProducts, isPackageEnabled } from '@/account/subscription/utils';
import { SubscriptionPackage, subscriptionPackageLabels } from '@/api/constant/subscription';

export interface Pack1Props {
  subscription: account.Subscription
  onSelect: () => void
}

const Pack1: FC<Pack1Props> = ({
  subscription,
  onSelect
}) => {
  const products = useMemo(
    () => {
      return getPackageProducts(subscription, SubscriptionPackage.Pack1);
    },
    [subscription]
  );

  return (
    <>
      <Card
        isSelected={isPackageEnabled(subscription, SubscriptionPackage.Pack1)}
        onSelect={onSelect}
      >
        <UiStack>
          <UiHStack spacing={0} justifyContent={'space-between'}>
            <UiHStack>
              <UiText variant={'h6'} flexGrow={1}>{subscriptionPackageLabels[SubscriptionPackage.Pack1]}</UiText>
            </UiHStack>
            <UiTag px={4} py={2} bgColor={'blackAlpha.50'}>Yearly plan</UiTag>
          </UiHStack>
          <BaseDividerHorizontal height={4} />
          <UiStack>
            <UiText>20 hours free onboarding service</UiText>
            <UiText>Unlimited events</UiText>
            <UiText>Unlimited admin users</UiText>
          </UiStack>
        </UiStack>
        <BaseDividerHorizontal height={4} />
        <UiStack spacing={4}>
          <UiText>Modules included ({products.length})</UiText>
          <ModuleList
            modules={products}
          />
        </UiStack>
      </Card>
    </>
  );
};

export default Pack1;
