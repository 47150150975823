/* eslint-disable react/no-unescaped-entities */
import { type FC, useRef } from 'react';
import {
  UiButton,
  type UiAlertDialogProps,
  type UiButtonProps,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerCloseButton,
  UiDrawerFooter,
  UiDrawerBody,
  UiHStack,
  UiText
} from '@/lib/ui';

export interface DeletionConfirmationProps {
  isOpen: UiAlertDialogProps['isOpen']
  onClose: UiAlertDialogProps['onClose']
  onConfirm: UiButtonProps['onClick']
  name: string
}

const DeletionConfirmation: FC<DeletionConfirmationProps> = ({
  isOpen,
  onClose,
  onConfirm,
  name
}) => {
  const cancelRef = useRef(null);
  return (
    <UiDrawer placement={'bottom'} size={'lg'} isOpen={isOpen} onClose={onClose}>
      <UiDrawerOverlay />
      <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
      <UiDrawerContent p={8}>
        <UiDrawerBody>
          <UiText variant={'title'} fontWeight={400}>
            Do you confirm to delete "{name}"?
          </UiText>
        </UiDrawerBody>
        <UiDrawerFooter>
          <UiHStack spacing={8}>
            <UiButton ref={cancelRef} onClick={onClose} colorScheme={'gray'} px={8}>
              No
            </UiButton>
            <UiButton colorScheme={'red'} ml={4} onClick={onConfirm} px={8}>
              Yes
            </UiButton>
          </UiHStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default DeletionConfirmation;
