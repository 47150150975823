import { type FC, useMemo } from 'react';
import BaseLayout from '@/base/Layout';
import { useParams } from 'react-router-dom';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiStack } from '@/lib/ui';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import type { BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import { generatePageUrl } from '@/app/pages';
import { type ManageRouteParams } from '@/registration/pages';
import OrderSummary from '@/registration/manage/OrderInfo/OrderSummary';
import Tickets from './Tickets';

const OrderInfo: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const params = useParams<ManageRouteParams['RegistrationManageOrderInfo']>();
  const orderId = useMemo<number | null>(
    () => params.orderId ? parseInt(params.orderId, 10) : null,
    [params.orderId]
  );
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Manager' },
        { label: 'Orders', url: generatePageUrl('RegistrationManageOrder', { tenantCode }) },
        /**
         * @todo orderId here should be the order serial number, not the database table row id.
         */
        { label: `Order information (${orderId ?? ''})` }
      ];
    },
    [orderId]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={null}
      MainTransition={null}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BreadcrumbBody
        breadcrumbNodes={breadcrumbNodes}
      >
        <UiStack p={8} spacing={8}>
          {!!orderId && (
            <OrderSummary orderId={orderId} />
          )}
          {!!orderId && (
            <Tickets orderId={orderId} />
          )}
        </UiStack>
      </BreadcrumbBody>
    </BaseLayout>
  );
};

export default OrderInfo;
