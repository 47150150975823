import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps } from '@/lib/ui';
import TourTicketPageForm from './TourTicketPageForm';
import PageRow from './PageRow';

export interface ManageLabelsProps extends UiHStackProps {
}

const TourTicketPage: FC<ManageLabelsProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <PageRow
        title={'Tour tickets page'}
        description={'Manage labels and descriptions on the page where we list the tour tickets.'}
        onEdit={onToggle}
      />
      <TourTicketPageForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default TourTicketPage;
