import { useCallback, type FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiIconPencilSimple, UiModal, UiModalBody, UiModalCloseButton, UiStack, UiText } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import { loadRegistrationInfoFormResponse, type InfoFormResponse, InfoFormType, type InfoForm, type InfoFormByAttendeeCategoryQuery, infoFormByAttendeeCategoryQueryKey, loadInfoFormByAttendeeCategory, infoFormResponseQueryKey, type RegistrationWithToken } from '@/api/registration';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useRegistrationAuth } from '@/app/ProviderRegistrationAuth';
import InformationList from './InformationList';
import { useDisclosure } from '@chakra-ui/react';
import FullScreen from '@/base/Modal/FullScreen';
import GenericInfoForm from './GenericInfoForm';

interface AdditionalInfoSessionProps {
  registration: RegistrationWithToken
}

const AdditionalInfoSession: FC<AdditionalInfoSessionProps> = ({ registration }) => {
  const { createTenantApiRequest } = useTenantApi();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const infoFormByAttendeeCategoryQuery = useQuery<InfoForm, Error>(
    [infoFormByAttendeeCategoryQueryKey, { registrationId: registration?.id, formType: InfoFormType.ADDITIONAL }],
    async () => await fetchInfoFormByAttendeeCategory(registration?.id),
    {
      enabled: !!registration
    }
  );

  async function fetchInfoFormByAttendeeCategory(registrationId: number | undefined): Promise<InfoForm> {
    if (typeof registrationId === 'undefined') {
      return await Promise.reject(new Error('Invalid registrationId'));
    }

    const params: InfoFormByAttendeeCategoryQuery = { formType: InfoFormType.ADDITIONAL, registrationId };
    const result = await loadInfoFormByAttendeeCategory(createTenantApiRequest)(params);

    return {
      ...result.item,
      config: JSON.parse(result.item.config) as InfoForm['config']
    };
  }

  const additionalInfoQuery = useQuery<InfoFormResponse, Error>(
    [infoFormResponseQueryKey, { registrationId: registration?.id, type: InfoFormType.ADDITIONAL }],
    async () => await fetchRegistrationInfoFormResponse(registration?.id),
    {
      enabled: !!registration
    }
  );

  async function fetchRegistrationInfoFormResponse(registrationId: number | undefined): Promise<InfoFormResponse> {
    if (typeof registrationId === 'undefined') {
      return await Promise.reject(new Error('Invalid registrationId'));
    }

    const response = await loadRegistrationInfoFormResponse(createTenantApiRequest)({
      registrationId,
      type: InfoFormType.ADDITIONAL
    });

    return response.item;
  }

  const toModify = () => {
    onOpen();
  };

  return (
    <UiStack>
      <QueryContainer query={infoFormByAttendeeCategoryQuery}>
        {(infoForm) => (
          <QueryContainer query={additionalInfoQuery}>
            {(additionalInfoResponse) => (
              <>
                <UiHStack justifyContent={'space-between'}>
                  <UiText variant={'title'}>
                      Additional information
                  </UiText>
                  <UiButton
                    rightIcon={(<UiIconPencilSimple color={'primary.500'} size={'xl'} weight={'bold'} />)}
                    variant={'ghost'}
                    colorScheme={'primary'}
                    fontSize={'md'}
                    fontWeight={'bold'}
                    onClick={toModify}
                  >
                      Modify
                  </UiButton>
                </UiHStack>
                <InformationList
                  fieldsMetadata={infoForm.config.fieldsMetadata}
                  response={additionalInfoResponse.response}
                />
                <FullScreen isOpen={isOpen} onClose={onClose}>
                  <GenericInfoForm
                    infoForm={infoForm}
                    infoFormResponse={additionalInfoResponse}
                    registration={registration}
                    onSuccess={onClose}
                  />
                </FullScreen>
              </>
            )}
          </QueryContainer>
        )}
      </QueryContainer>
    </UiStack>
  );
};

export default AdditionalInfoSession;
